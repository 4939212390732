import * as React from 'react';
import { observer } from "mobx-react";
import { ICommandBarItemProps, CommandBar, Dropdown, IDropdownOption, Slider, PrimaryButton, Stack, TextField, IStackItemStyles, Checkbox, DatePicker, Text, IconButton, IStackItemTokens } from '@fluentui/react';
import { FormHeader } from './FormHeader';
import { toJS } from 'mobx';
import { UIAssetsFormStore } from '../stores/UIAssetsFormStore';

export interface AssetsFormProps {
    store: UIAssetsFormStore
}

@observer
export class AssetsForm extends React.Component<AssetsFormProps, any> {

    static create(store: UIAssetsFormStore) {
        return <AssetsForm store={store} />;
    }

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    private _renderCommands() {
        let items: ICommandBarItemProps[] = [];
        this.props.store.addCloseMenu(items);
        this.props.store.parentStore.setCommandBarItemsKeys(items);
        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    render() {
        let header: string = "Download Assets: " + this.props.store.assetData.product.name + " "  + this.props.store.assetData.product.version;

        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content"> 
                    <FormHeader header={header} uiStore={this.props.store.parentStore} />
                    <Stack tokens={{childrenGap: 10, padding: 10 }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign={"center"}>
                                <Stack.Item>
                                    <Text>
                                        {"License Key: " + this.props.store.assetData.assetResponse.licenseKey}
                                    </Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <IconButton
                                        iconProps={{
                                            iconName: "Copy"
                                        }}
                                        onClick={this.props.store.copyLicenseToClipboard}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                           <PrimaryButton 
                                text="License"
                                onClick={this.props.store.downloadLicense}
                                disabled={this.props.store.disableLicense}
                            />
                        </Stack.Item>
                        <Stack.Item>
                           <PrimaryButton 
                                text="Installation Files"
                                onClick={this.props.store.downloadInstall}
                                disabled={this.props.store.disableInstall}
                           />
                        </Stack.Item>
                    </Stack>
                </div>
            </div>
        )
    }
}
