import { IInsightData } from "./ServerInsight";

// maps to AccountDTO on server
export interface IdDTO {
    id: number;
}

export interface AppInsight {
    id: number;
    updatedBy: string;
    lastUpdated : Date;
    // emailId: string derived
    insightType: string;
    // insightData: string derived from jData
    jData: IInsightData;
}

export interface AccountDTO
{
    publicId: string;
    // updatedBy: string;
    // lastUpdated: Date;
    emailId: string;
    firstName: string;
    lastName: string;
    enabled: boolean;
    approved: boolean;
    companyPublicId: number;
    expires: Date;
    sendNotification: boolean;
    companyAdmin: boolean;
    company: Company;
}

export interface Company {
    publicId: string;
    updatedBy: string;
    lastUpdated: Date;
    name: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    enabled: boolean;
}

export interface Administrator
{
    id: number;
    updatedBy: Date;
    lastUpdated: Date;
    emailId: string;
    name: string;
    password: string;
    enabled: boolean;
    forcePasswordReset: boolean;
    passwordResetId: string;
    passwordResetExpiry?: Date;
}

export interface ProductDTO {
    id: number;
    name: string;
    version: string;
    active: boolean;
    trialPeriod: number;
    subscriptionPeriod: number;
    renewalPeriod: number; 
    perpetual: boolean;
    eulaId: number; 
    buildDate: Date;
    pricingConfiguration: ProductConfiguration
    remoteInstallEnabled: boolean;
    remoteInstallAgreementId: number;
    supportTicketsEnabled: boolean;
    info: ProductInfo;
    videos: ProductVideo[];
}

export interface ProductInfo {
    name: string;
    tagLine: string;
    productUrl: string;
    image: string;
    fsProductPath: string;
    fsInstallPath: string;
}

export interface ProductConfiguration {
    productId: number;
    id: string;
    name: string;
    version: string;
    licenseFamily: string;
    items: ProductItem[];
}

export interface ProductItem {
    id: string;
    allowUserInput: boolean;
    hideForTrial: boolean;
    description: string;
    tip: string;
    type: ProductItemType;
    uiType: ProductItemUIType,
    config: ProductItemChoiceConfiguration | ProductItemSlabConfiguration
}

export interface ProductSupportItem {
    id: string;
    allowUserInput: boolean;
    hideForTrial: boolean;
    description: string;
    tip: string;
    type: ProductItemType;
    uiType: ProductItemUIType,
    config: ProductItemSlabConfiguration
}

export enum ProductItemType {
    slab = 1, // => textbox, slider
    choice = 2, // dropdown, checkbox, radio button
}

export enum ProductItemUIType {
    TextBox = 1,
    Slider = 2,
    DropDown = 3,
    CheckBox = 4,
    RadioButton = 5
}

export interface ProductItemConfiguration {
    id: string
}

export interface ProductItemChoiceConfiguration extends ProductItemConfiguration {
    defaultChoices: number[];
    allowMultiple: boolean;
    choices: ProductItemChoice[];
}

export interface ProductItemChoice {
    id: number;
    description: string;
    // value: number;
    // cost: number;
}

export interface ProductItemSlabConfiguration extends ProductItemConfiguration {
    defaultValue: number,
    minValue: number,
    maxValue: number,
    // perUnit: boolean,
    // slabs: ProductItemSlab[]
}

// export interface ProductItemSlab {
//     id: number;
//     start: number;
//     end: number;
//     cost: number;
// }

export interface ProdcutItemValue {
    item: ProductItem;
    value: number | number[];
}

export interface ProductVideo {
    id: number,
    name: string;
    description: string;
    active: boolean;
    image: string;
}

export interface SubscriptionDTO {
    publicId: string;
    isTrial: boolean;
    companyPublicId: string;
    productId: number;
    accountPublicId: string;
    organization: string;
    startDate: Date;
    endDate: Date;
    productData: any;
    licenseKey: string;
    licenseFile: string;
    product: ProductDTO;
    renewableDate: Date;
    cost: number;
    installCost: number
}

export interface OrderDTO {
    publicId: string;
    companyPublicId: string;
    orderDate: Date;
    orderTitle: string;
    orderDetailObject: any;
    productId: number;
    subscriptionPublicId: string;
    paymentId: string;
    paymentReference: string;
    paymentEmail: string;
    paymentAccount: string;
    paymentSubTotal: number;
    paymentTax: number;
    paymentTotal: number;
    paymentZipCode: string;
    paymentCountry: string;
    paymentInvoiceUrl: string
}

export interface LoginRequest {
    emailId: string;
    password: string
    deviceName: string;
    deviceOS : string;
    deviceVersion: string;
    hostInfo: string;
    asAdmin: boolean;
    captcha: string;
    brc: string;
}

export interface ForgotPasswordRequest {
    emailId: string;
    captcha: string
    brc: string;
    isAdmin : boolean;
}

export interface SubscriptionRequest {
    isTrial: boolean;
    renewaPubliclId: string;
    productId: number;
    accountId: number; // admin requests only
    productData: any;
    eulaAcceptanceId: number;
    acceptCompany: string; 
    acceptDate: Date;
    acceptName: string; 
    acceptTitle: string; 
    acceptEmail: string; 
    legalSignatory: boolean;
    paymentOrderId: string;
    paymentReferenceId : string;
    cost: number;
    installCost: number
}

export interface BaseResponse {
    statusCode: number;
    requestDate: Date;
    responseDate: Date;
    internalMessage: string;
    message: string;
}

export interface IdResponse extends BaseResponse {
    id: number;
}

export interface GenericResponse extends BaseResponse {

}

export interface GetEntitiesResponse<T> extends BaseResponse {
    entities: T[];
}

export interface GetEnityResponse<T> extends BaseResponse {
    entity: T;
}

export interface LoginResponse extends BaseResponse
{
    usha: string;
    token: string;
    resetPassword: boolean;
    account: AccountDTO;
    admin: Administrator;
    config: any;
}

export interface LogoffRequest
{
    usha: string;
    token: string;
}

export interface AppInitResponse extends BaseResponse {
    appToken: string;
    vak: string;
    features: any;
    buildVersion: string;
    countries: Country[]
}

export interface ChangePasswordRequest
{
    emailId: string;
    oldPassword: string
    newPassword: string;
    // isAdmin is implicit. see controller
}

export enum AssetType {
    Trial = 1,
    Subscription = 2,
    Agreement = 3,
    Invoice = 4,
    Video = 5
}

export interface CreateAssetsRequest {
    assetType: AssetType
    id: number;
    publicId: string;
}

export interface CreateAssetsResponse extends BaseResponse {
    licenseKey: string;
    assets: Asset[];
}

export interface DeleteAssetsRequest {
    ids: string[];
}

export interface DownloadAssetsRequest
{
    isTrial: boolean;
    id: number;
}

export interface Asset {
    id: string;
    displayName: string;
    contentType: string;
    tag: string;
}

export interface ResetPasswordRequest {
    resetId: string;
    emailId: string;
    password: string;
    isAdmin: boolean;
    captcha: string;
    brc: string;
}

export interface SignupRequest {
    captcha: string;
    emailId: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    brc: string;
    acceptedTerms: boolean;
}

export interface ConfirmEmailRequest {
    data: string;
    cData: string;
}

export interface Signup {
    id: number;
    emailId: string;
    firstName: string;
    lastName: string;
    ipAddress: string;
    signupDate: Date;
    companyName: string;
    approved: boolean;
    approvalDate: Date;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    acceptedTerms: boolean;
    emailConfirmed: boolean;
    emailConfirmDate: Date;
    emailConfirmIp: string;
}

export interface EulaDTO {
    id: number;
    version: string;
    fileName: string;
    effectiveDate: Date;
    asset: Asset;
}

export interface AgreementAcceptance {
    id: number;
    // updatedBy: string;
    // lastUpdated: Date;
    // ipAddress: string;
    accountId: number;
    companyId: number;
    productId: number;
    agreementId: number;
    productName: string;
    productVersion: string; 
    subscriptionId: number;
    trial: boolean;
    acceptCompany: string; 
    acceptDate: Date;
    acceptName: string; 
    acceptTitle: string; 
    acceptEmail: string; 
    legalSignatory: boolean;
    custom: boolean;
    fileName: string; 
    expiryDate: Date;
}

export interface SendMessageRequest {
    subject: string;
    message: string;
}

export interface FSPayloadResponse extends BaseResponse {
    securePayload: string;
    secureKey: string
}

export interface CostReqest {
    productId: number;
    trial: boolean;
    remoteInstall: boolean;
    productData: any;
}

export interface CostResponse extends BaseResponse {
    cost: number;
    remoteInstallCost: number;
}

export interface VideoReponse extends BaseResponse {
    data: ArrayBuffer;
}

export interface Country {
    id: number;
    isoCode: string;
    name: string;
    enabled: boolean;
}

export interface FSConfirmation {
    id: string;
    reference: string;
}