import { String } from 'typescript-string-operations';
import { isUndefined, isPrimitive, isString } from 'util';
import zz from 'zxcvbn';

export interface IValidator {
    validatedValue: string;
    validationMessage: string;
    valid: boolean;
    validate(value: string): boolean;
}

export enum DataType {
    String = 1,
    WholeNumber = 2,
    Decimal = 3,
    Date = 4
}

export abstract class BaseValidator implements IValidator {
    protected _validatedValue: string;
    protected _validationMessage: string;
    protected _valid: boolean;
    public evalData: any;

    constructor() {
        this._valid = false;
        this._validationMessage = "";
        this._validatedValue = "";
    }

    public get validatedValue() {
        return this._validatedValue;
    }

    public get validationMessage() {
        return this._validationMessage;
    }

    public get valid() : boolean {
        return this._valid;
    }

    validate(value: string) : boolean {
        return false;
    }
}

export class RequiredValidator extends BaseValidator {
    _trim: boolean;
    
    constructor(trim?: boolean) {
        super();
        this._trim = false;
        if (!isUndefined(trim)) this._trim = trim;
    }

    validate(value: string) : boolean {
        this._validationMessage = "";
        this._validatedValue = "";
        this._valid = false;
        if (String.IsNullOrWhiteSpace(value)) {
            this._validationMessage = "Required";
            return false;
        }
        this._validatedValue =  this._trim ? value.trim() : value;
        this._valid = true;
        return true;
    }
}

export class EmailValidator extends BaseValidator {

    validate(value: string) : boolean {
        this._validationMessage = "";
        this._validatedValue = "";
        this._valid = false;
        if (String.IsNullOrWhiteSpace(value)) {
            this._validationMessage = "Invalid Email";
            return false;
        }
        let emailValid: RegExpMatchArray = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        this._valid = (emailValid && emailValid.length > 0);
        if (this._valid) {
            this._validatedValue = value.trim();
        } else {
            this._validationMessage = "Invalid Email";
        }
        return this._valid;
    }
}

export class UrlValidator extends BaseValidator {

    validate(value: string) {
        this._validationMessage = "";
        this._validatedValue = "";
        this._valid = false;
        if (String.IsNullOrWhiteSpace(value)) {
            this._validationMessage = "Invalid URL";
            return false;
        }
        // let regexQuery = "^(https?://)?(((www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z‌​0-9]{0,61}[a-z0-9]\\‌​.[a-z]{2,6})|((\\d{1‌​,3}\\.){3}\\d{1,3}))‌​(:\\d{2,4})?(/[-\\w@‌​\\+\\.~#\\?&/=%]*)?$‌​";
        // let url: RegExp = new RegExp(regexQuery,"i");
        this._valid = /^(http|https):\/\/[^ "]+$/.test(value.trim());
        this._validatedValue = value.trim();
        if (!this._valid) this._validationMessage = "Invalid URL";
        return this._valid;
    }
}

export class DataTypeValidator extends BaseValidator {
    private _dataType: DataType;
    private _maxLength: number;
    private _integral: number;
    private _decimals: number;
    private _allowNeg: boolean;

    constructor(dataType: DataType, maxLength: number, integral: number, decimals: number, allowNeg: boolean) {
        super();
        this._dataType = dataType;
        this._maxLength = maxLength;
        this._integral = integral;
        this._decimals = decimals;
        this._allowNeg = allowNeg;
    }

    validate(value: any) : boolean {
        this._validationMessage = "";
        this._validatedValue = "";
        if (!isPrimitive(value)) return false;

        try {
            this._valid = false;

            switch (this._dataType) {
                case DataType.Decimal:
                    if (!Number.isNaN(Number(value))) {
                        this._validationMessage = "Value is not a number."
                        return false;
                    }
                    var d = Number(value);
                    if (!this._allowNeg && d < 0) {
                        this._validationMessage = "Negative vlaue is invalid."
                        return false;
                    }
                    if (this._maxLength > 0) {
                        if ( Number(value).toString().length > this._maxLength) {
                            this._validationMessage = String.Format("Invalid value. Max length {0} exceeded.", this._maxLength);
                            return false;
                        }
                    }
                    if (this._decimals > 0) {
                        if (d.toString().split('.').length > 0) {
                            var decimals = Number(d.toString().split('.')[1].length);
                            if (decimals > this._decimals) {
                                this._validationMessage = String.Format("Invalid value. Max decimals {0} exceeded.", this._decimals);
                                return false;
                            }
                        }
                    }
                    if (this._integral > 0) {
                        var integral = Math.floor(d);
                        if (integral.toString().length > this._integral) {
                            this._validationMessage = String.Format("Invalid value. Decimals not allowed.", this._integral);
                            return false;
                        }
                    }
                    this._valid = true;
                    this._validatedValue = d.toString();
                    return true;
    
                case DataType.WholeNumber:
                    if (Number.isNaN(Number(value))) {
                        this._validationMessage = "Value is not a number.";
                        return false;
                    }
                    var w = Number(value);
                    if (!this._allowNeg && w < 0) {
                        this._validationMessage = "Negative vlaue is invalid."
                        return false;
                    }
                    if (this._maxLength > 0) {
                        if (w.toString().length > this._maxLength) {
                            this._validationMessage = String.Format("Invalid value. Max length {0} exceeded.", this._maxLength);
                            return false;
                        }
                    }
                    
                    // no integrals validation, maxlength validated above
                    let parts = w.toString().split('.');
                    if (parts.length > 1) {
                        var wDecimals = Number(d.toString().split('.')[1].length);
                        if (wDecimals > 0) {
                            this._validationMessage = "Invalid value. Decimals not allowed.";
                            return false;
                        }
                    }
    
                    this._valid = true;
                    this._validatedValue = d.toString();
                    return true;
    
                case DataType.String:
                    if (!isString(value)) {
                        this._validationMessage = "Value is not a string.";
                        return false;
                    }
                    if (this._maxLength > 0) {
                        if (value.length > this._maxLength) {
                            this._validationMessage = String.Format("Invalid value. Max length {0} exceeded.", this._maxLength);
                            return false;
                        }
                    }
                    this._valid = true;
                    this._validatedValue = value;
                    return true;
    
                case DataType.Date:
                    // TBD
                    return true;
            }
    
            this._validationMessage = "Internal error: Invalid data date.";
            return false;
        } catch (error) {
            console.error(error);
            this._validationMessage = "Unexpected error!";
            return false;
        }
    }
}


export class RangeValidator extends BaseValidator {
    private _minValue: number;
    private _maxValue: number;

    constructor(minValue: number, maxValue: number) {
        super();
        this._minValue = minValue;
        this._maxValue = maxValue;
    }

    validate(value: any) : boolean {
        this._valid = false;
        this._validationMessage = "";
        this._validatedValue = "";
        if (!isPrimitive(value)) return false;

        try {
            if (Number.isNaN(Number(value))) {
                this._validationMessage = "Value is not a number";
                return false;
            }

            let n: number = Number(value);
            if (n < this._minValue || n > this._maxValue) {
                this._validationMessage = String.Format("Value out of range. Value must be between {1} and {2}.", this._minValue, this._maxValue);
            }

            return false;
        } catch (error) {
            console.error(error);
            this._validationMessage = "Unexpected error!";
            return false;
        }
    }
}


export class PasswordComplexityValidator extends BaseValidator {
    private readonly _minLength: number;
    private readonly _minScore: number;
    
    constructor(minLength: number, minScore: number) {
        super();
        this._minLength = minLength;
        this._minScore = minScore;
        this.evalData = 0;
    }

    validate(value: string) : boolean {
        this._valid = false;
        this._validationMessage = "";
        this._validatedValue = "";

        if (String.IsNullOrWhiteSpace(value)) {
            this._valid = true;
            return true;
        }

        try {
            if (value.length < this._minLength) {
                this._validationMessage = String.Format("Password must be at least {0} characters long.", this._minLength);
                return false;
            }
            
            let result = zz(value);
            this.evalData = result.score as number;
            if (result.score < this._minScore) {
                this._validationMessage = "Password does not meet complexity requirements.";
                return false;
            }

            this._validatedValue =  value;
            this._valid = true;
            return true;                
        } catch (error) {
            console.error(error);
            this._validationMessage = "Unexpected error!";
            return false;            
        }
    }
}