import {UIStore} from './UIStore'
import * as Server from '../web-store/ServerClasses'
import { ProductDTO, SubscriptionDTO, GetEntitiesResponse, EulaDTO, Asset, AgreementAcceptance, CreateAssetsRequest, AssetType, CreateAssetsResponse } from '../web-store/ServerClasses';
import { UIPurchaseWizardStore, EulaFields } from './UIPurchaseWizardStore';
import { toJS } from 'mobx';
import moment, { isDate } from 'moment';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';
import { UIFormStore } from './UIFormStore';
import { HomeForm } from '../components/HomeForm';
import { UIVideosFormStore } from './UIVideosFormStore';

export class UIHomeFormStore extends UIFormStore {
    products: ProductDTO[];

    constructor(parent: UIStore) {
        super(parent);
        this.setBaseObject({ notUsed: 1});
        this.products = [];
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            // in user mode, only active products are retrieved
            let resp: Server.GetEntitiesResponse<ProductDTO> = await this.parentStore.serverApi.getEntities("products");
            if (resp.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(resp);
                return;
            }
            this.products = resp.entities;
            this.parentStore.addForm(HomeForm.create(this));
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();            
        }
    }

    private async _getProduct(product: ProductDTO): Promise<ProductDTO> {
        this.parentStore.uiTransition.show();
        try {
            // in user mode, only active products are retrieved
            let productsResponse: Server.GetEntitiesResponse<ProductDTO> = await this.parentStore.serverApi.getEntities("products", ["id=" + product.id]);
            if (productsResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(productsResponse);
                return null;
            } 
            if (productsResponse.entities.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("The selected product is not active.");
                return null;
            }

            return productsResponse.entities[0];
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();
            return null;
        }      
    }

    goToProductUrl = (product: ProductDTO) => {
        const link = document.createElement("a");
        link.href = product.info.productUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    isProductActive = async (id: number): Promise<boolean> => {
        let productsResponse: Server.GetEntitiesResponse<ProductDTO> = await this.parentStore.serverApi.getEntities("products", ["id=" + id]);
        if (productsResponse.statusCode !== 200) {
            this.parentStore.messageStore.showMessageFromResponse(productsResponse);
            return false;
        } 
        if (productsResponse.entities.length <= 0) {
            this.parentStore.messageStore.showErrorMessage("The selected product is not active.");
            return false;
        }

        return true;
    }

    startTrial = async(product: ProductDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            // recheck if product is active
            if (await this.isProductActive(product.id) !== true) return;
            
            // check if trial has already been done by this account for this product
            let trialsResponse: GetEntitiesResponse<SubscriptionDTO> = await this.parentStore.serverApi.getEntities<SubscriptionDTO>("subscriptions", ["productid=" + product.id.toString(), "istrial=1"]);
            if (trialsResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(trialsResponse);
                return;
            }
            if (trialsResponse.entities.length > 0) {
                this.parentStore.messageStore.showErrorMessage("You have already requested a trial for this product. If you want an additional trial, please send us a message throught the Contact link");
                return;
            }

            // check if eula has been signed for this product/company/trial - this is for offline acceptance
            let eulaAccepted: boolean = false;
            let eulaAcceptance: AgreementAcceptance = null;
            // 2020-05-09 15:04:05 : JS does not want offline acceptance
            // let eulaAcceptancesResponse: GetEntitiesResponse<EulaAcceptance> = await this.parentStore.serverApi.getEntities<EulaAcceptance>("agreementacceptances", 
            //     ["trial=1",
            //      "productid=" + product.id.toString()
            //      // company is implicit for user auth
            //     ]);
            // if (eulaAcceptancesResponse.statusCode !== 200) {
            //     this.parentStore.messageStore.showMessageFromResponse(eulaAcceptancesResponse);
            //     return;
            // }
            // if (eulaAcceptancesResponse.entities.length > 0) {
            //     eulaAcceptance = eulaAcceptancesResponse.entities[0];
            //     if (isNullOrUndefined(eulaAcceptance.expiryDate)) {
            //         eulaAccepted = true;
            //     } else {
            //         eulaAccepted = moment(eulaAcceptancesResponse.entities[0].expiryDate).isAfter(moment.utc(), "days");
            //     }
            // }              

            // create asset for eula
            let eulasResponse: CreateAssetsResponse =  await this.parentStore.serverApi.postData<CreateAssetsRequest, CreateAssetsResponse>("assets", {
                assetType: AssetType.Agreement,
                id: product.eulaId,
                publicId: "",
                }, "POST");
            if (eulasResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(eulasResponse);
                return;
            }
            if (isNullOrUndefined(eulasResponse.assets) || eulasResponse.assets.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("EULA record could not be retrieved.")
                return;
            }
            let asset: Asset = eulasResponse.assets[0];

            let eulaFields: EulaFields = {
                acceptanceId: 0,
                accepted: eulaAccepted,
                organization: "",
                name: "",
                title: "",
                email: "",
                date: undefined,
                preAccepted: false
            };

            if (this.parentStore.rootStore.ClientConfig.lazyData.webStore) {
                if (this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields) {
                    eulaFields = {...this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields};
                    // eulaFields.date = new Date();
                    eulaFields.preAccepted = false;
                }
            }

            // if  (eulaAccepted === true) {
            //     eulaFields.acceptanceId = (eulaAcceptance.id);
            //     eulaFields.organization = (eulaAcceptance.acceptCompany);
            //     eulaFields.name = (eulaAcceptance.acceptName);
            //     eulaFields.title = (eulaAcceptance.acceptTitle);
            //     eulaFields.email = (eulaAcceptance.acceptEmail);
            //     eulaFields.date = new Date(eulaAcceptance.acceptDate);
            //     eulaFields.preAccepted = true;
            // }

            product.pricingConfiguration.productId = product.id;
            
            let store: UIPurchaseWizardStore = new UIPurchaseWizardStore(this.parentStore, product, eulaFields);
            store.purchaseMode = false;
            store.pricingMode = false;
            store.eulaAssetId = asset.id;
            // not required store.fsProductPath = product.info.fsProductPath;
            store.showForm();

        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }

    startPricing = async(product: ProductDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            // recheck if product is active
            if (await this.isProductActive(product.id) !== true) return;

            let eulaFields: EulaFields = {
                acceptanceId: 0,
                accepted: false,
                organization: "",
                name: "",
                title: "",
                email: "",
                date: undefined,
                preAccepted: false
            };

            product.pricingConfiguration.productId = product.id;

            let store: UIPurchaseWizardStore = new UIPurchaseWizardStore(this.parentStore, product, eulaFields);
            store.purchaseMode = false;
            store.pricingMode = true;
            store.eulaAssetId = "";
            store.showForm();

        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }    
    }

    startPurchase = async(product: ProductDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            // recheck if product is active
            if (await this.isProductActive(product.id) !== true) return;

            // customer can purchase multiple subscriptions of the same product, so no duplicate check for purchases

            // check if eula has been signed for this product/company/trial - this is for offline acceptance
            let eulaAccepted: boolean = false;
            let eulaAcceptance: AgreementAcceptance = null;
            // 2020-05-09 15:02:24 : JS does not want the option of offline acceptance
            // let eulaAcceptancesResponse: GetEntitiesResponse<EulaAcceptance> = await this.parentStore.serverApi.getEntities<EulaAcceptance>("eulaacceptances", 
            //     ["trial=0",
            //      "productid=" + product.id.toString()
            //      // company is implicit for user auth
            //     ]);
            // if (eulaAcceptancesResponse.statusCode !== 200) {
            //     this.parentStore.messageStore.showMessageFromResponse(eulaAcceptancesResponse);
            //     return;
            // }
            // if (eulaAcceptancesResponse.entities.length > 0) {
            //     eulaAcceptance = eulaAcceptancesResponse.entities[0];
            //     if (isNullOrUndefined(eulaAcceptance.expiryDate)) {
            //         eulaAccepted = true;
            //     } else {
            //         eulaAccepted = moment(eulaAcceptancesResponse.entities[0].expiryDate).isAfter(moment.utc(), "days");
            //     }
            // }              

            // create asset for eula
            let eulasResponse: CreateAssetsResponse =  await this.parentStore.serverApi.postData<CreateAssetsRequest, CreateAssetsResponse>("assets", {
                assetType: AssetType.Agreement,
                id: product.eulaId,
                publicId: "",
                }, "POST");
            if (eulasResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(eulasResponse);
                return;
            }
            if (isNullOrUndefined(eulasResponse.assets) || eulasResponse.assets.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("EULA agreement record could not be retrieved.")
                return;
            }
            
            // create asset for remote install agreement, if enabled
            let riaResponse: CreateAssetsResponse =  null;
            if (product.remoteInstallEnabled === true) {
                riaResponse =  await this.parentStore.serverApi.postData<CreateAssetsRequest, CreateAssetsResponse>("assets", {
                    assetType: AssetType.Agreement,
                    id: product.remoteInstallAgreementId,
                    publicId: "",
                    }, "POST");
                if (riaResponse.statusCode !== 200) {
                    this.parentStore.messageStore.showMessageFromResponse(riaResponse);
                    return;
                }
                if (isNullOrUndefined(riaResponse.assets) || riaResponse.assets.length <= 0) {
                    this.parentStore.messageStore.showErrorMessage("Remote install agreement record could not be retrieved.")
                    return;
                }                
            }

            let eulaFields: EulaFields = {
                //acceptanceId: (eulaAcceptancesResponse.entities.length <= 0) ? 0 : (eulaAcceptancesResponse.entities[0].id),
                acceptanceId: 0,
                accepted: eulaAccepted,
                organization: "",
                name: "",
                title: "",
                email: "",
                date: undefined,
                preAccepted: false
            };

            if (this.parentStore.rootStore.ClientConfig.lazyData.webStore) {
                if (this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields) {
                    eulaFields = {...this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields};
                    // eulaFields.date = new Date();
                    eulaFields.preAccepted = false;
                }
            }

            product.pricingConfiguration.productId = product.id;

            let store: UIPurchaseWizardStore = new UIPurchaseWizardStore(this.parentStore, product, eulaFields);
            store.purchaseMode = true;
            store.pricingMode = false;
            store.eulaAssetId = eulasResponse.assets[0].id;
            store.remoteInstallAssetId = (product.remoteInstallEnabled === true) ? riaResponse.assets[0].id : "";
            store.showForm();

        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }  

    _testCallBack = async (data: any) => {
        this.parentStore.uiTransition.show();
        try {
            console.log(JSON.stringify(data, null, 4));
        } catch (error) {
            console.error(error);
        } finally {
            this.parentStore.uiTransition.show();
        }
    }

    public testPurchase = async (product: ProductDTO) => {
        this.parentStore.uiTransition.show();
        try {
            
            (window as any).func1 = this._testCallBack;
            
            let fs = window["fastspring" as any] as any;
            // *FJ8J is CVC code
            var data: any = {
                items: [
                    {
                        product: "sub-lynx-monitor-v3-7",
                        quantity: 1,
                        pricing: {
                            price: {
                                USD: 4500.20
                            }
                        }
                    }
                ]
            }          

            let response: Server.FSPayloadResponse = await this.parentStore.serverApi.postData<any, Server.FSPayloadResponse>("fspayload", data, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }

            // fs.builder.secure(data, "");
            // console.log(response.secureKey);
            fs.builder.secure(response.securePayload, response.secureKey);
            // console.log("session: " + kk);
            fs.builder.checkout();
            
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }  
    }
    
    gotoVideos = async(productRef: ProductDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            // recheck if product is active and refetch videos as user may 
            // have just started a trial/purcahse and needs access to them
            // right away (i.e. without refresh/logging off/back on)
            let product: ProductDTO = await this._getProduct(productRef);
            if (isNullOrUndefined(product)) return;

            let store: UIVideosFormStore = new UIVideosFormStore(this.parentStore, product);
            store.showForm();

        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }  
}