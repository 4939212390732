import { AppStore } from "./AppStore";
import { ITheme, ISemanticColors } from "office-ui-fabric-react/lib/Styling";
import { ServerApi } from "../web-store/ServerApi";
import { observable, action, computed } from "mobx";
import { UITransitionStore } from "./UITransitionStore";
import { UIFooterStore } from "./UIFooterStore";
import { UIConfirmDialogStore } from "./UIConfirmDialogStore";
import { isNullOrUndefined } from "../web-store/WebStoreUtil";
import { UISidebarStore } from "./UISidebarStore";
import { UIUserMenuStore } from "./UIUserMenuStore";
import { UIMessageStore } from "./UIMessageStore";
import { ContextualMenuItemType, ICommandBarItemProps } from "office-ui-fabric-react";
import { UIHomeFormStore } from "./UIHomeFormStore";

export enum InitForm {
    signIn = "signIn",
    signUp = "signUp",
    confirmEmail = "confirmEmail",
    forgotPassword = "forgotPassword",
    resetPassword = "resetPassword",
}

export class UIStore {
    rootStore: AppStore;
    theme: ITheme;
    readonly serverApi: ServerApi;
    @observable initForm: InitForm;
    @observable formStack: JSX.Element[];
    @observable uiTransition: UITransitionStore;
    @observable confirmDialogStore: UIConfirmDialogStore;
    @observable messageStore: UIMessageStore;
    @observable sideBarStore: UISidebarStore;
    @observable userMenuStore: UIUserMenuStore;
    // footerStore: UIFooterStore;

    constructor(root: AppStore, theme: ITheme, serverApi: ServerApi) {
        this.sideBarStore = new UISidebarStore(this);
        this.userMenuStore = new UIUserMenuStore(this);
        this.messageStore = new UIMessageStore(this);
        this.rootStore = root;
        this.theme = theme;
        this.serverApi = serverApi;
        this._appInit();
    }

    private _appInit(): void {
        this.initForm = InitForm.signIn;
        // this.footerStore = new UIFooterStore(this);
        this.confirmDialogStore = new UIConfirmDialogStore();
        this.formStack = [];
    }

    public preLoginInit(newTheme: ITheme) {
        this.theme = newTheme;
        this.messageStore.clear();
        // this requires resource manager. don't put this in appInit
        this.uiTransition = new UITransitionStore(this);
        // this.sideBarStore.initMenus();
        // this.statusStore.clearMessage();
        this.formStack = [];
    }    
    
    get linkColor() : string {
        return this.theme.semanticColors.link;
    }

    get semanticColors() : ISemanticColors {
        return this.theme.semanticColors;
    }

    @computed 
    get currentForm():  JSX.Element {
        return (this.formStack.length <= 0) ? null : this.formStack[this.formStack.length -1];
    }

    @action
    addForm = async (formElement: JSX.Element ): Promise<void> => {
        if (this.formStack.length > 0) {
            // if same form is added, do nothing
            if (this.formStack[this.formStack.length -1].type === formElement.type) {
                this.uiTransition.hide();
                return;
            }
        }
        // if (contentType === FormType.Home) {
        //     if (this.formStack.length > 0) {
        //         // warn user and return her to home
        //         this.formStack.splice(1, this.formStack.length -1);
        //         return;
        //     }
        // }
        // console.log(formElement.type);
        this.formStack.push(formElement);
        // in most cases, this has no effect. all non-modal components
        // MUST turn this off in componentDidMount
        this.uiTransition.show();
    }

    @action
    gotoHomeForm(): void {
        if (this.formStack.length > 0) {
            this.formStack.splice(1, this.formStack.length -1);
            return;
        }
    }

    @action
    removeForm = (key?: number): void => {
        if (!isNullOrUndefined(key)) {
            let idx: number = this.formStack.findIndex((f) => {
                if (!f.props.store) return false;
                if (!f.props.store.Key) return false;
                if (typeof f.props.store.Key === "number") return false;
                return f.props.store.Key === key
            });

            if (idx <= 0) return;
            this.formStack.splice(idx, 1);        
        } else {
            this.formStack.splice(this.formStack.length -1, 1);
        }
    }

    getPreviousFormStore = (): any => {
        return this.formStack[this.formStack.length - 2].props.store;
    }    

    setCommandBarItemsKeys(items: ICommandBarItemProps[], iconOnly?: boolean) {
        // this is a utility function for all command bars on forms
        const iconStyle = {
            fontSize: 16,
        }

        let key: number = 0;
        items.forEach(i => {
            key++;
            i.key = key.toString()
            i.itemType =  ContextualMenuItemType.Normal;
            i.iconProps.style = iconStyle;
            // shows name property as a callout
            if (!isNullOrUndefined(iconOnly)) i.iconOnly = iconOnly;
            if (i.subMenuProps) {
                if (i.subMenuProps.items) {
                    i.subMenuProps.items.forEach(si =>{
                        si.key = key.toString();
                    });
                }
            }
        });
    }
}