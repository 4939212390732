import * as React from 'react';
import {Checkbox} from '@fluentui/react/lib/Checkbox';
import { TextField } from '@fluentui/react/lib/TextField';
import { UIAccountFormStore } from '../stores/UIAccountFormStore';
import { observer } from 'mobx-react';
import * as Server from '../web-store/ServerClasses'
import { FormHeader } from './FormHeader';
import { ICommandBarItemProps, CommandBar } from '@fluentui/react';
import { UIOrganizationFormStore } from '../stores/UIOrganizationFormStore';
import { ValidationTextField } from './ValidationTextField';

class OrganizationFormProps {
    store: UIOrganizationFormStore;
}

@observer
export class OrganizationForm extends React.Component<OrganizationFormProps, any> {

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIOrganizationFormStore) {
        return <OrganizationForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [
            {
                key: "",
                text: "Save",
                iconProps: {
                    iconName: "Save",
                },
                onClick: () => { this.props.store.save() }
            }                          
        ];

        this.props.store.addCloseMenu(items);

        this.props.store.parentStore.setCommandBarItemsKeys(items);

        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    render() {
        const adminMode = this.props.store.parentStore.rootStore.domainStore.AdminMode;
        
        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content">
                    <FormHeader header="Organization" uiStore={this.props.store.parentStore} />

                    <div className="ms-Grid content-form">

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    readOnly={!adminMode}
                                    label="Name"
                                    autoFocus={adminMode}
                                    store={this.props.store}
                                    fieldId="name"
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="Address Line 1"
                                    autoFocus={!adminMode}
                                    store={this.props.store}
                                    fieldId="addressLine1"
                                />
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="Address Line 2"
                                    store={this.props.store}
                                    fieldId="addressLine2"
                                />
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="Address Line 3"
                                    store={this.props.store}
                                    fieldId="addressLine3"
                                />
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="City"
                                    store={this.props.store}
                                    fieldId="city"
                                />
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="State"
                                    store={this.props.store}
                                    fieldId="state"
                                />
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="Zip Code"
                                    store={this.props.store}
                                    fieldId="zip"
                                />
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationTextField 
                                    label="Country"
                                    store={this.props.store}
                                    fieldId="country"
                                />
                            </div>                    
                        </div>    

                    </div>
                </div>
            </div>
        );
    }
}
