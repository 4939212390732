enum LocalStoreKey {
    KeyUser = "ws-stgk-user",
    KeyTheme = "ws-stgk-theme",
    KeyUIData = "ws-ui"
}

export interface LocalUserInfo {
    userId: string;
    usha: string;
    token: string;
    keepLoggedIn: boolean;
    userName: string;
    asAdmin: boolean;
}

export class LocalStore {
    
    public get UserInfo(): LocalUserInfo {
        return this._getLocalObject<LocalUserInfo>(LocalStoreKey.KeyUser);
    }  

    public set UserInfo(userInfo: LocalUserInfo ) {
        this._setLocalObject<LocalUserInfo>(LocalStoreKey.KeyUser, userInfo);
    }  
    
    private _getLocalObject = <T>(key: string): T => {
        try {
            let json = localStorage.getItem(key);
            return JSON.parse(json) as T;
        } catch (error) {
           console.error(error);
           return null;
        }
    }

    private _setLocalObject = <T>(key: string, obj: T): boolean => {
        try {
            localStorage.setItem(key, JSON.stringify(obj));
            return true;
        } catch (error) {
           console.error(error);
           return false;
        }
    }
}