import * as React from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { UIStore } from '../stores/UIStore';
import { observer } from "mobx-react";
import { IconButton, FontSizes } from '@fluentui/react';

export interface FormHeaderProps {
    header: string;
    uiStore: UIStore;
}

@observer
export class FormHeader extends React.Component<FormHeaderProps, any> {

    _closeForm = () => {
        this.props.uiStore.removeForm();
    }

    render() {
        return (
            <div style={{fontSize: FontSizes.xLarge }} >
                {
                    this.props.header
                }
        </div>
        )
    }
}


// was
// render() {
//     return (
//         <div className="page-header">
//             <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }} >
//                 <Label styles={
//                     {
//                         root: {
//                             fontSize: 24
//                         }
//                     }
//                     }> 
//                     {
//                         this.props.header
//                     }
//                 </Label>
//                 <div style={{ paddingRight: 20 }}>
//                     <IconButton 
//                         iconProps={
//                             {
//                                 iconName: "Cancel"
//                             }
//                         }
//                         onClick={ this._closeForm }
//                         title="Close"
//                     />                        
//                 </div>
//             </div>
//     </div>
//     )
// }