import {UIStore} from './UIStore'
import * as Server from '../web-store/ServerClasses'
import { UIFormStore } from './UIFormStore';
import { VideosForm } from '../components/VideosForm';
import { observable } from 'mobx';

export class UIVideosFormStore extends UIFormStore {
    product: Server.ProductDTO
    @observable showVideo: boolean;
    @observable videoTitle: string;
    videoRef: any
    videoResponse: Server.VideoReponse;

    constructor(parent: UIStore, product: Server.ProductDTO) {
        super(parent);
        this.setBaseObject({ notUsed: 1});
        this.product = product;
        this.showVideo = false;
        this.videoTitle = "";
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            this.parentStore.addForm(VideosForm.create(this));
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();            
        }
    }
    
    hideVideo = () => {
        this.showVideo = false;
        this.videoTitle = "";
        this.videoResponse = null;
    }

    gotoVideo = async (video: Server.ProductVideo) => {
        // https://player.vimeo.com/video/292766005?color=dc611f&title=0&byline=0&portrait=0&badge=0
        this.videoResponse = null;
        try {
            // create video asset
            let assetRequest: Server.CreateAssetsRequest = {
                assetType: Server.AssetType.Video,
                id: video.id,
                publicId: this.product.id.toString() 
            }

            let assetResponse: Server.CreateAssetsResponse = await this.parentStore.serverApi.postData<Server.CreateAssetsRequest, Server.CreateAssetsResponse>("assets", assetRequest, "POST");
            if (assetResponse.statusCode != 200)  {
                this.parentStore.messageStore.showMessageFromResponse(assetResponse);
                return;
            }
            if (assetResponse.assets.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("No assets were returned.");
                return;
            }

            this.videoResponse = await this.parentStore.serverApi.getVideo(assetResponse.assets[0]);
            if (this.videoResponse.statusCode != 200)  {
                this.parentStore.messageStore.showMessageFromResponse(this.videoResponse);
                return;
            }
            // console.log(videoResponse);

            let deleteAssetRequest: Server.DeleteAssetsRequest = {
                ids: [assetResponse.assets[0].id]
            }
            await this.parentStore.serverApi.postData("assets", deleteAssetRequest, "DELETE");

            // this will indirectly trigger layerMounmted which will get the video and render it
            this.videoTitle = video.description;
            this.showVideo = true;

        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        }
    }

    layerMounmted = () => {
        if (this.videoResponse !== null) this.getVideo();
    }

    getVideo = () => {
        try {
            var mimeCodec = 'video/mp4; codecs="avc1.420033, mp4a.40.5"'

            if (!this.videoRef.current) {
                console.log("Invalid ref!");
                return;
            }

            if ('MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
                var mediaSource = new MediaSource;
                const url = URL.createObjectURL(mediaSource);

                this.videoRef.current.src = url;

                mediaSource.addEventListener('sourceopen', () => {
                const videoSourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                videoSourceBuffer.addEventListener('error', (e) => {
                    console.log(e);
                });

                videoSourceBuffer.appendBuffer(this.videoResponse.data);
            });
            } else {
                console.error('Unsupported MIME type or codec: ', mimeCodec);
                return;
            }
        } catch (error) {
            console.error(error);
        }
    }  

    tryMediaSource = () => {
        try {
            //let assetUrl: string = "bunny.mp4";
            let assetUrl: string = "test_frag.mp4";
            // var mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
            // var mimeCodec = 'video/mp4; codecs="avc1.640029, mp4a.40.5"'
            // var mimeCodec = 'video/mp4; codecs="avc1.640040, mp4a.40.5"'
            var mimeCodec = 'video/mp4; codecs="avc1.420033, mp4a.40.5"'

            if (!this.videoRef.current) {
                console.log("Invalid ref!");
                return;
            }
    
            if (this.videoRef.current && 'MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
                var mediaSource = new MediaSource;
                const url = URL.createObjectURL(mediaSource);

                this.videoRef.current.src = url;

                mediaSource.addEventListener('sourceopen', () => {
                const videoSourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                videoSourceBuffer.addEventListener('error', (e) => {
                    console.log(e);
                });

                // this is just an express route that returns an mp4 file using `res.sendFile`
                fetch(assetUrl).then((response) => {
                    console.log("Status:"  + response.status);
                    return response.arrayBuffer();
                }).then((videoData) => {
                    // console.log(videoData);
                    // videoSourceBuffer.addEventListener('updateend', () => {
                    //     mediaSource.endOfStream();
                    //     //this.videoRef.current.play();
                    //     this.playVideo(this.videoRef.current);
                    //     console.log("got here3");
                    //     //console.log(mediaSource.readyState); // ended
                    //   });
                    videoSourceBuffer.appendBuffer(videoData);
                    console.log("got here2");
                });
            });
            } else {
                console.error('Unsupported MIME type or codec: ', mimeCodec);
                return;
            }
        } catch (error) {
            console.error(error);
        }
    }    
}