import {observable, action} from 'mobx';
import { UIEditFormStore } from './UIEditFormStore';
import { UIStore } from './UIStore';
import { RequiredValidator } from '../web-store/Validators';
import * as Server from '../web-store/ServerClasses'
import { ContactForm } from '../components/ContactForm';

export interface ContactFormData {
    subject: string;
    message: string;
}

export class UIContactFormStore extends UIEditFormStore<ContactFormData> {
    @observable sentMessage: string;

    constructor(parent: UIStore) {
        super(parent);
        let data: ContactFormData = {
            subject: "",
            message: ""
        }
        this.sentMessage = "";
        this.setBaseObject(data);
        this.createValidationFields();
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "subject",
                propName: "subject",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                ]
            },
            {
                fieldId: "message",
                propName: "message",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                ]
            }
        ]
    }

    @action
    showForm = async () => {
        this.parentStore.uiTransition.show();
        try {
              this.parentStore.addForm(ContactForm.create(this));
        } catch (error) {
            this.parentStore.uiTransition.hide();            
        }
    }
     
    sendMessage = async () : Promise<void> => {
        let data: ContactFormData = this.baseObject as ContactFormData;
        try {
            if (!this.validateForm()) 
                return;
            this.parentStore.uiTransition.show();

            let request: Server.SendMessageRequest = {
                subject: data.subject,
                message: data.message
            }

            let response: Server.GenericResponse = await this.parentStore.serverApi.postData<Server.SendMessageRequest, Server.GenericResponse>("sendmessage", request, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }

            this.sentMessage = "Thank you for contacting us. We will respond to you within one business day.";
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error as Error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }
}
