import {UIStore} from './UIStore'
import { SearchFormStore, IColumnProps } from './UISearchFormStore';
import { Selection } from "office-ui-fabric-react/lib/DetailsList";
import { AccountDTO } from '../web-store/ServerClasses';
import { AdminAccountsSearchForm } from '../components/AdminAccountsSearchForm';
import { UIAccountFormStore } from './UIAccountFormStore';

export class UIAdminAccountsFormStore extends SearchFormStore<AccountDTO> {
    selection: Selection;
    readonly isTrial: boolean;

    constructor(parent: UIStore) {
        super(parent, "firstName");
        this.setBaseObject({ notUsed: 1});
        this.selection = new Selection();
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("accounts", (records, response) => {                 
                if (records) {
                    this.parentStore.addForm(AdminAccountsSearchForm.create(this));
                }
            });
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }
 
    setColumns(): IColumnProps[] {
        const columns: IColumnProps[] = [
            {
                key: "1",
                name: "Id",
                fieldName: "publicId",
                isSortedDescending: false,
                isSorted: true
            },
            {
                key: "2",
                name: "Email",
                fieldName: "emailId",
                isSortedDescending: false,
                isSorted: true
            },            
            {
                key: "3",
                name: "name",
                fieldName: "firstName",
                isSortedDescending: false,
                isSorted: false
            },            
            {
                key: "4",
                name: "company",
                fieldName: "lastName",
                isSortedDescending: false,
                isSorted: false
            }                      
        ];

        return columns;
    }

    protected getFilterRecordValue(r: AccountDTO): string {
        return r.firstName + r.lastName;
    }

    refresh = async () => {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("accounts", null);
        } finally {
            this.parentStore.uiTransition.hide();
        } 
    }

    gotoAccount = async(account: AccountDTO) => {
        new UIAccountFormStore(this.parentStore).showForm(account.publicId);
    }
}