import * as React from 'react';
import {observer} from 'mobx-react';
import { TextField, ITextFieldProps } from '@fluentui/react/lib/TextField';
import { isUndefined, isNullOrUndefined } from 'util';
import { Icon } from '@fluentui/react';
import { IValidationFieldProps } from '../web-store/ValidationFieldProps';
import { ValidationField } from '../web-store/ValidationField';
import { ServerApi } from '../web-store/ServerApi';
import { PasswordComplexityValidator } from '../web-store/Validators';

export interface IValidationPasswordFieldProps extends IValidationFieldProps, ITextFieldProps {
    ignoreComplexityRequirement?: boolean
}

@observer
export class ValidationPasswordField extends React.Component<IValidationPasswordFieldProps, any> {
    
    constructor(props:IValidationPasswordFieldProps, context:any) {
        super(props, context);
        let vf: ValidationField = this.props.store.getValidationField(props);
        if (props.ignoreComplexityRequirement === true) return;
        if (!isNullOrUndefined(vf)) {
            if (!isNullOrUndefined(vf.validators)) {
                vf.validators.push(new PasswordComplexityValidator(this.props.store.parentStore.rootStore.MinPasswordLength, 
                    this.props.store.parentStore.rootStore.MinPasswordScore));
            }
        }
    }

    render() {

        const props: IValidationFieldProps = this.props as IValidationFieldProps;
        let vf: ValidationField = this.props.store.getValidationField(props);

        const onchanged =  vf ? (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, v?: string) => 
            { 
                this.props.store.validateField(vf, v); 
                if (!isUndefined(vf.errorMessage)) {
                    if (this.props.onChange) this.props.onChange(event, v);
                }
            } 
            : this.props.onChange;

        let errorMessage: string = null;
        let valid: boolean = false;
        if (vf) {
            if (!isUndefined(vf.errorMessage)) {
                errorMessage = vf.errorMessage;
            }
            if (!isNullOrUndefined(vf.validators)) {
                valid = vf.validators.findIndex(x => x.valid !== true) < 0;
            }
        }

        const value: string = isUndefined(this.props.value) ? (vf ? (this.props.store.getFormValue(vf) as string) : "") : this.props.value;

        return(
            <TextField 
                {...this.props } 
                onChange={ onchanged}  
                errorMessage={ errorMessage }
                validateOnFocusOut={true}
                value={value}
                type="password"
            />
        )
    }
}


// 2020-03-08 16:22:08 : was: 
// return(
//     <div style={{width: "100%", display: "flex", alignItems: "flex-end"}}>
//         <div style={{width: "90%" }}>
//             <TextField 
//                 {...this.props } 
//                 onChange={ onchanged}  
//                 errorMessage={ errorMessage }
//                 validateOnFocusOut={true}
//                 value={value}
//             />
//         </div>
//         <div style={{paddingTop: 5, paddingLeft: 5 }}>
//             <Icon
//                 iconName={ valid === true ? "Accept" :  "Warning"}
//                 style={{ fontSize: 24, color: valid === true ? "green" :  "red" }}
//             />
//         </div>
//     </div>
// )