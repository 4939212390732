import * as React from 'react';
import { observer } from "mobx-react";
import { AppProps } from './App';

@observer
export class PageContainer extends React.Component<AppProps, any> {

    constructor(props: AppProps, context: any) {
        super(props, context);
    }

    render() {
        return this.props.store.uiStore.currentForm;
    }
}