import { UIStore } from "./UIStore";
import { observable, action, computed, toJS } from "mobx";
import { ProductConfiguration, ProdcutItemValue, ProductItem, ProductItemType, ProductItemChoiceConfiguration, ProductItemSlabConfiguration, SubscriptionDTO, SubscriptionRequest, IdResponse, CostReqest as CostRequest, CostResponse, FSPayloadResponse, ProductDTO, FSConfirmation, AccountDTO, GetEnityResponse, ProductVideo } from "../web-store/ServerClasses";
import { UIFormStore } from "./UIFormStore";
import { PurchaseWizardForm } from "../components/PurchaseWizardForm";
import { IDropdownOption } from "office-ui-fabric-react";
import { DialogMode, DialogResult } from "./UIConfirmDialogStore";
import moment from "moment";
import { RequiredValidator, DataTypeValidator, DataType } from "../web-store/Validators";
import { isArray, isNullOrUndefined } from "../web-store/WebStoreUtil";
import { UISubscriptionsFormStore } from "./UISubscriptionsFormStore";

export enum PurchaseWizardStep {
    Config = 1,
    AcceptEula = 2,
    RemoteInstall = 3,
    Confirm = 4
}

export interface EulaFields {
    acceptanceId: number;
    accepted: boolean;
    organization: string;
    name: string;
    title: string;
    email: string;
    date?: Date;
    preAccepted: boolean;
}

export interface CostData {
    subscriptionCost: number;
    remoteInstallCost: number;
    totalCost: number; 
}

export class UIPurchaseWizardStore extends UIFormStore {
    product: ProductDTO;
    productConfig: ProductConfiguration;
    @observable itemValues: ProdcutItemValue[];
    @observable step: PurchaseWizardStep;
    @observable disableConfirm: boolean;
    @observable remoteInstallRequested: boolean;
    purchaseMode: boolean;
    pricingMode: boolean;
    eulaAssetId: string;
    remoteInstallAssetId: string;
    @observable costData: CostData;
    private _subscriptionsFormStore: UISubscriptionsFormStore;
    private _renewalSubscription: SubscriptionDTO;
    private remoteInstallAgreement: EulaFields;
    private eulaAgreement: EulaFields;

    constructor(parent: UIStore, product: ProductDTO, eulaFields: EulaFields) {
        super(parent);
        this.product = product;
        this.productConfig = product.pricingConfiguration;
        this.step = PurchaseWizardStep.Config;
        this.disableConfirm = false;
        this.costData = {
            subscriptionCost: 0,
            remoteInstallCost: 0,
            totalCost: 0
        };
        this.remoteInstallRequested = false;
        this.itemValues = this.productConfig.items.map(i => {
            let piv: ProdcutItemValue = {
                item: i,
                value: (i.type == ProductItemType.choice ? 
                        (i.config as ProductItemChoiceConfiguration).defaultChoices.slice() :
                        (i.config as ProductItemSlabConfiguration).defaultValue
                    )
            };
            return piv;
        });
 

        this.setBaseObject(eulaFields);
        this.eulaAgreement = this.baseObject;
        this.remoteInstallAgreement = {
            acceptanceId: 0,
            accepted: false,
            organization: "",
            name: "",
            title: "",
            email: "",
            date: undefined,
            preAccepted: false            
        }

        this.createValidationFields();
        this._resetCart();

        this._subscriptionsFormStore = null;
        this._renewalSubscription = null;
    }

    setItemValuesFromPreviousSubscription(subscription: SubscriptionDTO, subscriptionsFormStore: UISubscriptionsFormStore) {
        let items: any[] = subscription.productData.items;
        for (let index = 0; index < items.length; index++) {
            const element: {id: string, values: number[] } = items[index];
            
            let piv: ProdcutItemValue = this.itemValues.find(iv => iv.item.id === element.id);
            if (isNullOrUndefined(piv) !== true) {
                piv.value = (piv.item.type == ProductItemType.choice ? element.values.slice() : element.values[0]);
            }
        }

        this._renewalSubscription = subscription;
        this._subscriptionsFormStore = subscriptionsFormStore;
    }

    _setAgreementValidation(stepNumber: number) {
        if (stepNumber=== PurchaseWizardStep.AcceptEula) {
            this.setBaseObject(this.eulaAgreement);

        } else if (stepNumber== PurchaseWizardStep.RemoteInstall){
            this.setBaseObject(this.remoteInstallAgreement);
        }
    }

    createValidationFields() {
        this.validationFields = [       
            {
                fieldId: "accepted",
                propName: "accepted",
                errorMessage: "",
                validators: []
            },                                   
            {
                fieldId: "organization",
                propName: "organization",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },                                   
            {
                fieldId: "name",
                propName: "name",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "title",
                propName: "title",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 100, 0, 0, false)]
            },
            {
                fieldId: "email",
                propName: "email",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            }                        
        ]
    }
    
    private _resetCart() {
        try {
            let fs = window["fastspring" as any] as any;
            fs.builder.reset() 
            console.log("Cart has been reset.");
        } catch (error) {
            console.error(error);
        }
    }

    @action
    showForm = async () => {
        this.parentStore.uiTransition.show();
        try {
            // this._setTestEulaData();
            // this.setBaseObject(this.eulaFields);
            this.parentStore.addForm(PurchaseWizardForm.create(this));                    
        } catch (error) {
            this.parentStore.uiTransition.hide();            
        }
    }

    @action
    confirmCancel = () => {
        if (this.pricingMode === true) {
            this.cancel();
            return;
        }

        this.parentStore.confirmDialogStore.callback = (context, result) => { if (result === DialogResult.Yes) this._deleteAsset(); }
        this.parentStore.confirmDialogStore.dialogMode = DialogMode.OKCancel;
        this.parentStore.confirmDialogStore.showDialog("Confirm", "Do you want to cancel your purchase/trial?");
    }

    private _deleteAsset = async () => {
        try {
            let data: any = {
                ids: [ this.eulaAssetId, this.remoteInstallAssetId ]
            };

            await this.parentStore.serverApi.postData("assets", data, "DELETE");
        } catch (error) {
            
        } finally {
            this.cancel();
        }
    }

    @computed
    get DisableConfirm(): boolean {
        if (this.step !== PurchaseWizardStep.Confirm) return false;
        return this.disableConfirm;
    }

    @action
    gotoNextStep = async() => {
      let stepNumber = this.step as number;
      if (stepNumber == PurchaseWizardStep.AcceptEula) {
        if (this._validateAgreement(stepNumber) !== true)
            return;
            if (this.product.remoteInstallEnabled !== true || this.remoteInstallRequested !== true ) {
                // go to confirm directly if remote install is not enabled
                stepNumber++;
            }

        } else if (stepNumber === PurchaseWizardStep.RemoteInstall) {
            if (this.product.remoteInstallEnabled !== true) {
                // should not happen
                console.error("Cannot go to remote install step when it is not enabled!");
            } else {
                if (this._validateAgreement(stepNumber) !== true)
                    return;    
            }
        } else if (stepNumber === PurchaseWizardStep.Confirm) {
          if (this.purchaseMode === true) { 
              this._confirmPurchase();
          } else {
            this._confirmTrial();
          }
          return;
      }
      
      stepNumber++;

      if (stepNumber == PurchaseWizardStep.Confirm) {
          // calculate cost, so it can be displayed in the confirm form
          this.costData = {...await this._calculateCost()};
      }

      this._setAgreementValidation(stepNumber);
      this.step = stepNumber;
    }

    @action
    gotoPreviousStep = () => {
      let stepNumber = this.step as number;
      if (stepNumber === PurchaseWizardStep.Config) return;
      stepNumber--;
      this._setAgreementValidation(stepNumber);
      this.step = stepNumber;
    }

    @action
    selectEulaDate = (date: Date | null | undefined) => {
        let eulaFields: EulaFields = this.baseObject as EulaFields;

        if (isNullOrUndefined(date)) {
            eulaFields.date = undefined;
            return;
        }
        eulaFields.date = date;
    }

    private _validateAgreement(stepNumber: number): boolean {
        let agreementFields: EulaFields = this.baseObject as EulaFields;

        if (agreementFields.accepted !== true ) {
            this.parentStore.confirmDialogStore.dialogMode = DialogMode.OK;
            this.parentStore.confirmDialogStore.showDialog("Agreeement Acceptance", "You must agree to the agreement to proceed with this purchase or trial.");
            return false;
        }

        if (!this.validateForm()) 
            return false;

        if (agreementFields.email.indexOf("@") <= 0) {
            this.parentStore.confirmDialogStore.dialogMode = DialogMode.OK;
            this.parentStore.confirmDialogStore.showDialog("Agreeement Acceptance", "The email is invalid.");
            return false;
        }

        if (isNullOrUndefined(agreementFields.date)) {
            this.parentStore.confirmDialogStore.dialogMode = DialogMode.OK;
            this.parentStore.confirmDialogStore.showDialog("Agreement Acceptance", "Acceptance Date is invalid.");
            return false;
        }

        if (moment(agreementFields.date) > moment()) {
            this.parentStore.confirmDialogStore.dialogMode = DialogMode.OK;
            this.parentStore.confirmDialogStore.showDialog("Agreement Acceptance", "Acceptance Date cannot be in the future.");
            return false;
        }
        
        if (agreementFields.preAccepted !== true) {
            let diff: number =  moment(moment()).diff(moment(agreementFields.date), "days");
            if (diff > 0) {
                this.parentStore.confirmDialogStore.dialogMode = DialogMode.OK;
                this.parentStore.confirmDialogStore.showDialog("Agreement Acceptance", "Acceptance Date must be the current date.");
                return false;
            }
        }

        if (stepNumber === PurchaseWizardStep.AcceptEula) {
            this.eulaAgreement = {...this.baseObject};
        } else if (stepNumber === PurchaseWizardStep.RemoteInstall) {
            this.remoteInstallAgreement = {...this.baseObject};
        }
                    
        return true;
    }

    private _confirmTrial = async() => {
        this.parentStore.uiTransition.show();
        this.disableConfirm = true;
        try {
            
            let eulaFields: EulaFields = this.baseObject as EulaFields;
            
            let sr: SubscriptionRequest = {
                renewaPubliclId: null,
                accountId: 0,
                acceptCompany: eulaFields.organization,
                acceptDate: eulaFields.date,
                acceptEmail: eulaFields.email,
                acceptName: eulaFields.name,
                acceptTitle: eulaFields.title,
                eulaAcceptanceId: eulaFields.acceptanceId,
                isTrial: true,
                legalSignatory: true,
                productId: this.productConfig.productId,
                productData: {
                    id: this.productConfig.id,
                    items: this.itemValues.map(iv => { return {
                        id: iv.item.id,
                        values: (isArray(iv.value) === true) ? (iv.value as number[]).slice() : [ iv.value ]
                    }})
                },
                paymentOrderId: "",
                paymentReferenceId: "",
                cost: 0,
                installCost: 0
            };

            let response: IdResponse = await this.parentStore.serverApi.postData<SubscriptionRequest, IdResponse>("subscriptions", sr, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }

            this.cancel();
            
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
            this.disableConfirm = false;
        }
    }

    private _confirmPurchase = async() => {
        this.disableConfirm = true;
        this.parentStore.uiTransition.show();
        try {                 
            let startedPayment: boolean = await this._startPayment();
            if (startedPayment !== true) 
                this.parentStore.uiTransition.hide();
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();
        }

        // if the call succeeds, uiTransition is kept visible and is closed in _paymentConfirmed
    }

    _paymentConfirmed = async (confirmation: FSConfirmation) => {
        this.parentStore.uiTransition.show();
        try {
            console.log(JSON.stringify(confirmation, null, 4));
            if (confirmation == null) {
                this.parentStore.messageStore.showMessage(true, "The transaction was cancelled.");
                this.disableConfirm  = false;
                return;
            }

            if (isNullOrUndefined(confirmation.id) || isNullOrUndefined(confirmation.reference)) {
                this.parentStore.messageStore.showMessage(true, "The payment gateway did not return an appropriate response.");
                return;
            }

            let eulaFields: EulaFields = this.baseObject as EulaFields;
            
            let sr: SubscriptionRequest = {
                accountId: 0,
                renewaPubliclId: isNullOrUndefined(this._renewalSubscription) ? null: this._renewalSubscription.publicId,
                acceptCompany: eulaFields.organization,
                acceptDate: eulaFields.date,
                acceptEmail: eulaFields.email,
                acceptName: eulaFields.name,
                acceptTitle: eulaFields.title,
                eulaAcceptanceId: eulaFields.acceptanceId,
                isTrial: false,
                legalSignatory: true,
                productId: this.productConfig.productId,
                productData: {
                    id: this.productConfig.id,
                    items: this.itemValues.map(iv => { return {
                        id: iv.item.id,
                        values: (isArray(iv.value) === true) ? (iv.value as number[]).slice() : [ iv.value ]
                    }})
                },
                paymentOrderId: confirmation.id,
                paymentReferenceId: confirmation.reference,
                cost: this.costData.subscriptionCost,
                installCost: this.costData.remoteInstallCost
            };

            let response: IdResponse = await this.parentStore.serverApi.postData<SubscriptionRequest, IdResponse>("subscriptions", sr, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }
            
            this.parentStore.confirmDialogStore.callback = (context, result) => { 
                if (result === DialogResult.Yes) this.cancel();
                // refresh subscriptions after renewal
                if (this._subscriptionsFormStore != null) this._subscriptionsFormStore.refresh();
            }

            this.parentStore.confirmDialogStore.dialogMode = DialogMode.OK;
            this.parentStore.confirmDialogStore.showDialog("Payment Processed", "Your payment was processed successfully. You can access your license keys and downloads from Subscriptions.");
      
        } catch (error) {
            console.error(error);
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }

    private _startPayment = async (): Promise<boolean> => {
        try {
            let costData: CostData = await this._calculateCost()
            if(isNullOrUndefined(costData)) {
                this.parentStore.messageStore.showMessage(true, "Unable to calculate cost.");
                return false;
            }

            let tags: any = this._getProductTags();

            let subScriptionCost: number = costData.subscriptionCost;
            let installCost: number = 0;
            if (this.remoteInstallRequested === true) {
               installCost = costData.remoteInstallCost;
            }

            // this cost data is inserted into subscriptions, not the one from fsOrder
            // fsOrder data goes into Orders/OrderItems
            this.costData = {...costData};
            
            (window as any).func1 = this._paymentConfirmed;
          
            // passing contact information works only if contact already exists in FS
            // let accountResponse: GetEnityResponse<AccountDTO> = await this.parentStore.serverApi.getEntity<AccountDTO>("accounts", ["id=" + this.parentStore.rootStore.domainStore.userAccount.publicId]);
            // if (accountResponse.statusCode !== 200) {
            //     this.parentStore.messageStore.showMessageFromResponse(accountResponse);
            //     return false;
            // }
            // let account: AccountDTO = accountResponse.entity;
            // contact: {
            //     firstName: account.firstName,
            //     lastName: account.lastName,
            //     company: account.company.name,
            //     postalCode: account.company.zip,
            //     city: account.company.city
            // },

            let fs = window["fastspring" as any] as any;
            // *FJ8J is CVC code
            var data: any = {
                items: [
                    {
                        product: this.product.info.fsProductPath,
                        quantity: 1,
                        pricing: {
                            price: {
                                USD: subScriptionCost
                            }
                        }
                    },                                    
                ]
            };
            
            if (this.remoteInstallRequested === true) {
                data.items.push({
                    product: this.product.info.fsInstallPath,
                    quantity: 1,
                    pricing: {
                        price: {
                            USD: installCost
                        }
                    }
                });
            }

            let response: FSPayloadResponse = await this.parentStore.serverApi.postData<any, FSPayloadResponse>("fspayload", data, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return false;
            }
            
            const account: AccountDTO = this.parentStore.rootStore.domainStore.userAccount;
            fs.builder.recognize({
                email: account.emailId,
                firstName: account.firstName, 
                lastName: account.lastName, 
                company:  account.company.name, //optional
                phone: "", //optional
                addressLine1: account.company.addressLine1,  //optional
                addressLine2: account.company.addressLine2,  //optional
                city: account.company.city,  //optional
                region: "",  //optional
                country: account.company.country,  //optional
                postalCode: account.company.zip //optional
            });	

            // fs.builder.secure(data, "");
            // console.log(response.secureKey);
            // fs.builder.tag({"e1Users": "100"});
            fs.builder.tag(tags);
            fs.builder.secure(response.securePayload, response.secureKey);
            // console.log("session: " + kk);
            fs.builder.checkout();
            return true;
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            return false;
        }
    } 

    @action
    dropDownChangeSingle = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        let piv: ProdcutItemValue = this.itemValues[item.data as number];
        (piv.value as number[])[0] = item.key as number;
        this._resetCost();
    }

    @action
    sliderChange = (vIndex: number, value: number): void => {
        let piv: ProdcutItemValue = this.itemValues[vIndex];
        (piv.value as number) = value;
        this._resetCost();
    }

    @action
    private _resetCost = () => {
        this.costData = {
            subscriptionCost: 0,
            remoteInstallCost: 0,
            totalCost: 0
        };    
    }

    @action
    estimateCost = async() => {
        this.parentStore.uiTransition.show();
        try {
            let costData: CostData = await this._calculateCost();
            if(isNullOrUndefined(costData)) {
                this.parentStore.messageStore.showMessage(true, "Unable to estimate cost.");
                return 
            }

            this.costData = {...costData};
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
            this.disableConfirm = false;
        }    
    }

    private _calculateCost = async(): Promise<CostData> => {
        try {
            let cr: CostRequest = {
                productId: this.productConfig.productId,
                trial: (this.purchaseMode === false),
                remoteInstall: this.remoteInstallRequested,
                productData: {
                    id: this.productConfig.id,
                    items: this.itemValues.map(iv => { return {
                        id: iv.item.id,
                        values: (isArray(iv.value) === true) ? (iv.value as number[]).slice() : [ iv.value ]
                    }})
                }
            };

            let response: CostResponse = await this.parentStore.serverApi.postData<CostRequest, CostResponse>("cost", cr, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return null;
            }

            return  { 
                subscriptionCost: parseFloat(response.cost.toFixed(2)), 
                remoteInstallCost: parseFloat(response.remoteInstallCost.toFixed(2)),
                totalCost: (parseFloat(response.cost.toFixed(2)) + parseFloat(response.remoteInstallCost.toFixed(2)))
            };
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            return null;
        }    
    }

    private _getProductTags() : {} {
        let tags:any = {};

        for (let index = 0; index < this.productConfig.items.length; index++) {
            const element = this.productConfig.items[index];
            switch (element.type) {
                case ProductItemType.choice:
                    let choiceConfig: ProductItemChoiceConfiguration = element.config as ProductItemChoiceConfiguration;
                    tags[element.id] = (this.itemValues[index].value as number[]).map(i => choiceConfig.choices.find(c => c.id === i).description).join(", ");
                    break;
            
                case ProductItemType.slab:
                    let slabConfig: ProductItemSlabConfiguration = element.config as ProductItemSlabConfiguration;
                    tags[element.id] = (this.itemValues[index].value as number).toString();
                    break;
            }
        }

        return tags;
    }

    public remoteInstallRequestChanged = (ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
        this.remoteInstallRequested = isChecked;    
    }

}