import * as React from 'react';
import { UIPurchaseWizardStore, PurchaseWizardStep, EulaFields } from '../stores/UIPurchaseWizardStore';
import { observer } from "mobx-react";
import { ICommandBarItemProps, CommandBar, Dropdown, IDropdownOption, Slider, PrimaryButton, Stack, TextField, IStackItemStyles, Checkbox, DatePicker, Text, IconButton, IStackItemTokens } from '@fluentui/react';
import { FormHeader } from './FormHeader';
import { ProductItemSlabConfiguration, ProductItemChoiceConfiguration, ProductItemType, ProductItemUIType, ProductItem, ProdcutItemValue } from '../web-store/ServerClasses';
import { ValidationCheckBox } from './ValidationCheckBox';
import { ValidationTextField } from './ValidationTextField';
import { toJS } from 'mobx';
import { utimes } from 'fs';
import { to2DecimalNumber } from '../web-store/WebStoreUtil';

export interface PurchaseWizardFormProps {
    store: UIPurchaseWizardStore
}

@observer
export class PurchaseWizardForm extends React.Component<PurchaseWizardFormProps, any> {

    static create(store: UIPurchaseWizardStore) {
        return <PurchaseWizardForm store={store} />;
    }

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    private _renderCommands() {
        let items: ICommandBarItemProps[] = [{
            key: "",
            text: "Close",
            iconProps: {
                iconName: "Cancel",
            },
            onClick: this.props.store.confirmCancel
        }];
        this.props.store.parentStore.setCommandBarItemsKeys(items);

        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    private _renderProductConfig() {
        
        let uiElements: JSX.Element[] = [];
        
        for (let index = 0; index < this.props.store.productConfig.items.length; index++) {
            const element = this.props.store.productConfig.items[index];

            // if in pricing mode (estimate) or purchase mode, show non-trial items
            if (element.hideForTrial === true && this.props.store.purchaseMode == false) {
               if (this.props.store.pricingMode == false) {
                   continue;
               }
            } 

            switch (element.type) {
                case ProductItemType.choice:
                    {
                        switch (element.uiType) {
                            case ProductItemUIType.DropDown:
                                uiElements.push(this._renderDropDown(element, element.config as ProductItemChoiceConfiguration, index));
                                break;
                        
                            case ProductItemUIType.RadioButton:
                                break;

                            case ProductItemUIType.CheckBox:
                                break;
                        }
                    }
                    break;
            
                case ProductItemType.slab:
                    {
                        switch (element.uiType) {
                            case ProductItemUIType.TextBox:
                                break;
                        
                            case ProductItemUIType.Slider:
                                uiElements.push(this._renderSlider(element, element.config as ProductItemSlabConfiguration, index));
                                break;
                        }
                    }                    
                    break;
            }
        }
        
        uiElements.push(this._renderInstallCheckBox(uiElements.length));

        // 2021-06-29 10:38:12 : display estimate price only in pricing or purchase mode
        if ((this.props.store.purchaseMode === true || this.props.store.pricingMode === true)) {
            uiElements.push(this._renderCost(uiElements.length))
        }

        if (this.props.store.purchaseMode === false && this.props.store.pricingMode === false) {
            uiElements.push(this._renderTrialInfo(uiElements.length))
        }

        return uiElements;
    }

    private _paddingStyle(): React.CSSProperties {
        return {
            padding: 10
        };
    }

    private _renderDropDown(productItem: ProductItem, choiceConfig: ProductItemChoiceConfiguration, vIndex: number) {
        let options: IDropdownOption[] = choiceConfig.choices.map(c => {
            let option: IDropdownOption = {
                key: c.id,
                text: c.description,
                data: vIndex            
            };
            return option;
        });

        return (
            <div className="ms-Grid-row" key={"pItem" + vIndex}>
                <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10" style={this._paddingStyle()} >
                    {
                        choiceConfig.allowMultiple ?
                        <Dropdown
                            label={productItem.description}
                            options={options}
                            multiSelect
                            selectedKeys={this.props.store.itemValues[vIndex].value as number[]}
                        />
                        :
                        <Dropdown
                            label={productItem.description}
                            options={options}
                            selectedKey={(this.props.store.itemValues[vIndex].value as number[])[0]}
                            onChange={this.props.store.dropDownChangeSingle}
                        />
                    }

                    <div style={{color: this.props.store.parentStore.linkColor, marginTop: 5}} >
                        { productItem.tip }
                    </div>
                </div>
            </div>
        )
    }

    private _renderSlider(productItem: ProductItem, slabConfig: ProductItemSlabConfiguration, vIndex: number) {
        return (
            <div className="ms-Grid-row" key={"pItem" + vIndex}>
                <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10" style={this._paddingStyle()} >
                    <Slider
                        label={productItem.description}
                        min={slabConfig.minValue}
                        max={slabConfig.maxValue}
                        step={1}
                        showValue={true}
                        onChange={(value: number) => { this.props.store.sliderChange(vIndex, value) }}
                        value={(this.props.store.itemValues[vIndex].value as number)}
                        snapToStep
                    />
                    <div style={{color: this.props.store.parentStore.linkColor, marginTop: 5}} >
                        { productItem.tip }
                    </div>
                </div>
            </div>
        );
    }

    private _renderInstallCheckBox(vIndex: number) {
        if (this.props.store.purchaseMode !== true) return null;
        if (this.props.store.product.remoteInstallEnabled !== true) return null;

        return (
            <div className="ms-Grid-row" key={"pItem" + vIndex}>
                <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10" style={this._paddingStyle()} >
                    <Checkbox
                        label="Remote Installation Service"
                        checked={this.props.store.remoteInstallRequested}
                        onChange={this.props.store.remoteInstallRequestChanged}
                    />
                </div>
            </div>
        );
    }    

    private _renderCost(vIndex: number) {
        return (
            <Stack key={"pItem" + vIndex} styles={{root: {marginTop: 20 }} } tokens={{childrenGap: 10}} >
                <Stack.Item>
                    <Stack horizontal verticalAlign={"center"} tokens={{childrenGap: 10}}>
                        <Stack.Item>
                            <PrimaryButton 
                                text="Estimate Price"
                                onClick={this.props.store.estimateCost}
                            />
                        </Stack.Item>
                        {
                            ((this.props.store.purchaseMode === true || this.props.store.pricingMode === true) && this.props.store.costData.subscriptionCost > 0) ?
                        <>
                        <Stack.Item>
                            <Text>
                                Subscription (annual):
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text>
                            {"$ " + to2DecimalNumber(this.props.store.costData.subscriptionCost)}
                            </Text>
                        </Stack.Item>
                        </>
                        : null
                        }

                        {
                            ((this.props.store.purchaseMode === true || this.props.store.pricingMode === true) && this.props.store.costData.remoteInstallCost > 0 && this.props.store.product.remoteInstallEnabled === true) ?
                            <>
                                <Stack.Item>
                                    <Text>
                                        Remote Installation:
                                    </Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <Text>
                                        {"$ " + to2DecimalNumber(this.props.store.costData.remoteInstallCost)}
                                    </Text>
                                </Stack.Item>
                            </>
                            :
                            null
                        }
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text>
                        All amounts are in USD and do not include VAT or Sales Tax.
                    </Text>
                </Stack.Item>
            </Stack>
        );    
    }

    private _renderTrialInfo(vIndex: number) {
        return (
        <Stack key={"pItem" + vIndex} styles={{root: {marginTop: 20 }} } tokens={{childrenGap: 10}} >
               <Stack.Item>
                <Text>
                    Trial Period:  {this.props.store.product.trialPeriod + " days."}
                </Text>
            </Stack.Item>
        </Stack>
        );
    }

    private _renderEulaAccept() {
        let url = this.props.store.parentStore.rootStore.serverApi.getAssetUrl(
            this.props.store.step === PurchaseWizardStep.AcceptEula ? this.props.store.eulaAssetId : this.props.store.remoteInstallAssetId, 
            true);

        const stackItemStyles: IStackItemStyles = {
            root: {
              width: 350
            }
        };

        let eulaFields: EulaFields = this.props.store.baseObject as EulaFields;

        return (
            <div style={{marginTop: 20}}>
                {
                    (eulaFields.preAccepted === true) ?
                    null : 
                    <embed src={url} type="application/pdf" style={{width: "100%", height: 500 }} /> 
                }
                <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                    <Stack.Item>
                        <Text>
                            To download the agreement, right click anywhere in the pane above and click Save as.
                        </Text>
                    </Stack.Item>
                </Stack>  

                {/* <embed src="http://localhost:31348/api/v1/xsendfile" type="application/pdf" style={{width: "100%", height: 600 }}/>  */}
                <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                    <Stack.Item>
                        <ValidationCheckBox
                            label="I accept the terms of the License Agreement and verify that I am authorized to accept this agreement on behalf of my organization"
                            store={this.props.store}
                            fieldId="accepted"
                            checked={this.props.store.baseObject["accepted"] as boolean}
                            disabled={eulaFields.preAccepted}
                        />
                    </Stack.Item>
                </Stack>  
    
                <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                    <Stack.Item styles={stackItemStyles}>
                        <ValidationTextField
                            label="Organization"
                            required={true}
                            store={this.props.store}                            
                            fieldId="organization"
                            disabled={eulaFields.preAccepted}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ValidationTextField
                            label="Authorized Name"
                            required={true}
                            store={this.props.store}                            
                            fieldId="name"
                            disabled={eulaFields.preAccepted}
                        />
                    </Stack.Item>
                </Stack>           

                <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                    <Stack.Item styles={stackItemStyles}>
                        <ValidationTextField
                            label="Title"
                            required={true}
                            store={this.props.store}                            
                            fieldId="title"
                            disabled={eulaFields.preAccepted}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ValidationTextField
                            label="Email"
                            required={true}
                            store={this.props.store}                            
                            fieldId="email"
                            disabled={eulaFields.preAccepted}
                        />
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                    <Stack.Item styles={stackItemStyles}>
                        <DatePicker
                            label="Date"
                            showMonthPickerAsOverlay={true}
                            isRequired={true}
                            onSelectDate={this.props.store.selectEulaDate}
                            value={this.props.store.baseObject["date"]}
                            disabled={eulaFields.preAccepted}
                        />
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                    <Stack.Item>
                        <Text variant="large" >
                            {
                                (eulaFields.preAccepted === true) ?
                                <>We have a signed License Agreement from your Organization.</> : 
                                <></>
                            }
                        </Text>
                    </Stack.Item>
                </Stack>  
            </div>
        );
        // Note: For offline acceptance, please send us a message through the contact link.
    }


    private _renderConfirm() {
        const itemTokens: IStackItemTokens = {margin: "0px 0px 0px 20px"};

        let subscriptionCost: string = to2DecimalNumber(this.props.store.costData.subscriptionCost);
        let installCost: string = to2DecimalNumber(this.props.store.costData.remoteInstallCost);
        let totalCost: string = to2DecimalNumber(this.props.store.costData.totalCost);
        
        return (
        <>
            <Stack tokens={{childrenGap: 10, padding: 10 }}>
                <Stack.Item>
                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                        <Stack.Item>
                            <IconButton
                                iconProps={{
                                    iconName: "Edit"
                                }}
                                onClick={() => { this.props.store.step = PurchaseWizardStep.Config } }
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="large">Product Configuration</Text>
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{padding: 10}} >
                        {
                           this._getConfigurationItemValue()
                        }
                    </Stack>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                        <Stack.Item>
                            <IconButton
                                iconProps={{
                                    iconName: "Edit"
                                }}
                                onClick={() => { this.props.store.step = PurchaseWizardStep.AcceptEula } }
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="large">License Agreement</Text>
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{padding: 10}} >
                        <Stack.Item tokens={itemTokens}>
                            <Text>Accepted: Yes</Text>
                        </Stack.Item>
                        </Stack>
                </Stack.Item>

                {
                    (this.props.store.remoteInstallRequested === true && this.props.store.purchaseMode === true) ? 
      
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                            <Stack.Item>
                                <IconButton
                                    iconProps={{
                                        iconName: "Edit"
                                    }}
                                    onClick={() => { this.props.store.step = PurchaseWizardStep.RemoteInstall } }
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="large">Remote Installation Agreement</Text>
                            </Stack.Item>
                        </Stack>
                        <Stack tokens={{padding: 10}} >
                            <Stack.Item tokens={itemTokens}>
                                <Text>Accepted: Yes</Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>              
                    
                    : null
                }

                {
                    (this.props.store.purchaseMode === true) ? 
      
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                            <Stack.Item>
                                <IconButton
                                    iconProps={{
                                        iconName: "ShoppingCart"
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="large">Price</Text>
                            </Stack.Item>
                        </Stack>
                        <Stack tokens={{padding: 10}} >
                            <Stack.Item tokens={itemTokens}>
                                { (this.props.store.purchaseMode === true && this.props.store.costData.subscriptionCost > 0) ?  
                                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                                        <Stack.Item styles={{root: {width: 200}}}>
                                            <Text block={true}>Subscription (yearly):</Text> 
                                        </Stack.Item>
                                        <Stack.Item>
                                            $ {subscriptionCost}
                                        </Stack.Item>
                                    </Stack>
                                    : null
                                }
                                { (this.props.store.purchaseMode === true && this.props.store.costData.remoteInstallCost > 0) ?  
                                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                                        <Stack.Item styles={{root: {width: 200}}}>
                                            <Text block={true}>Remote Installation:</Text> 
                                        </Stack.Item>
                                        <Stack.Item>
                                            $ {installCost}
                                        </Stack.Item>
                                    </Stack>
                                    : null
                                }
                                { (this.props.store.purchaseMode === true && this.props.store.costData.remoteInstallCost > 0) ?  
                                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                                        <Stack.Item styles={{root: {width: 200}}}>
                                            <Text block={true}>Total (for this order):</Text> 
                                        </Stack.Item>
                                        <Stack.Item>
                                           $ {totalCost}
                                        </Stack.Item>
                                    </Stack>
                                    : null
                                }
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    
                    : null
                }
                
            </Stack>               
        </>
        )
    }

    private _getConfigurationItemValue() {
        
        let uiElements: JSX.Element[] = [];
        let text: string = "";
        const itemTokens: IStackItemTokens = {margin: "0px 0px 0px 20px"};

        // console.log(toJS(this.props.store.itemValues));
        
        for (let index = 0; index < this.props.store.productConfig.items.length; index++) {
            const element = this.props.store.productConfig.items[index];

            text = element.description + ": "
            let piv: ProdcutItemValue = this.props.store.itemValues[index];

            switch (element.type) {
                case ProductItemType.choice:
                    {
                        switch (element.uiType) {
                            case ProductItemUIType.DropDown:
                                text += (element.config as ProductItemChoiceConfiguration).choices.find(c => c.id === (piv.value as number[])[0]).description;
                                break;
                        
                            case ProductItemUIType.RadioButton:
                                break;

                            case ProductItemUIType.CheckBox:
                                break;
                        }
                    }
                    break;
            
                case ProductItemType.slab:
                    {
                        switch (element.uiType) {
                            case ProductItemUIType.TextBox:
                                break;
                        
                            case ProductItemUIType.Slider:
                                text += (piv.value as number).toString();
                                break;
                        }
                    }                    
                    break;
            }

            uiElements.push(
                <Stack.Item tokens={itemTokens} key={"pItemValue" + index}>
                    <Text>{text}</Text>
                </Stack.Item>
            );
        }

        return uiElements;
    }
    

    private _renderStep() {       
        switch (this.props.store.step) {
            case PurchaseWizardStep.Config:
                return this._renderProductConfig();
                break;
        
            case PurchaseWizardStep.AcceptEula:
                return this._renderEulaAccept();
                break;

            case PurchaseWizardStep.RemoteInstall:
                return this._renderEulaAccept();
                    break;
    
            case PurchaseWizardStep.Confirm:
                return this._renderConfirm();
                break;

            default:
                return null;
                break;
        }
    }

    private _renderNextBack() {
        if (this.props.store.pricingMode === true) return null;

        return (
            <div style={{display: "flex", marginTop: 20}}>
                {
                    this.props.store.step === PurchaseWizardStep.Confirm ? 
                    null 
                    : 
                    <div style={{padding: 5}} >
                        <PrimaryButton
                            text="Back"
                            styles={{
                                root: {
                                    width: 150
                                }
                            }}
                            iconProps={
                                {
                                    iconName: "ChevronLeftSmall"
                                }
                            }
                            disabled={this.props.store.step === PurchaseWizardStep.Config }
                            onClick={this.props.store.gotoPreviousStep}
                        />
                    </div>
                }

                <div style={{padding: 5}} >
                    <PrimaryButton
                        text={this.props.store.step === PurchaseWizardStep.Confirm ? (this.props.store.purchaseMode ? "Payment" : "Confirm") : "Next"}
                        styles={{
                            root: {
                                width: 150
                            }
                        }}
                        iconProps={
                            {
                                iconName: this.props.store.step === PurchaseWizardStep.Confirm ? (this.props.store.purchaseMode ? "ShopServer" : "CheckList") : "ChevronRightSmall"
                            }
                        }
                        onClick={this.props.store.gotoNextStep}
                        disabled={this.props.store.DisableConfirm}
                    />
                </div>

            </div>
        );
    }

    render() {
        let header: string = "";
        switch (this.props.store.step) {
            case PurchaseWizardStep.Config:
                header = "Product Configuration: ";
                break;
        
            case PurchaseWizardStep.AcceptEula:
                header = "License Agreement: ";
                break;

            case PurchaseWizardStep.RemoteInstall:
                header = "Remote Installation Agreement: ";
                break;

            case PurchaseWizardStep.Confirm:
                header = "Confirm Purchase/Trial: ";
                break;
        }

        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content"> 
                    <FormHeader header={header + this.props.store.productConfig.name} uiStore={this.props.store.parentStore} />

                    <div className="ms-Grid">
                        { this._renderStep() }
                        { this._renderNextBack() }
                    </div>
                </div>
            </div>
        )
    }
}
