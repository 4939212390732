import { action} from 'mobx';
import {UIStore} from './UIStore'
import * as Server from '../web-store/ServerClasses'
import { UIEditFormStore } from './UIEditFormStore';
import { AccountForm } from '../components/AccountForm';
import { UIOrganizationFormStore } from './UIOrganizationFormStore';
import { UIChangePasswordStore } from './UIChangePasswordStore';
import { RequiredValidator, DataTypeValidator, DataType } from '../web-store/Validators';
import moment, { isDate } from 'moment';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';
// import { AccountForm } from '../components/AccountForm';

export class UIAccountFormStore extends UIEditFormStore<Server.AccountDTO> {
    
    constructor(parent: UIStore) {
        super(parent);
        this.createValidationFields();
    }

    @action
    showForm = async (publicId: string) => {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordFromFromApi("accounts", (account, reponse) => { 
                if (reponse.statusCode === 200) {
                    if (this.parentStore.rootStore.domainStore.AdminMode !== true) {
                        this.parentStore.rootStore.domainStore.userAccount.company = account.company;
                    }

                    // JSON date is not a real date. DatePicket throws a fit
                    // with toDateString() is not valid
                    account.expires = moment(account.expires).toDate();

                    // set base object after date reset
                    this.setBaseObject(account);

                    this.parentStore.addForm(AccountForm.create(this));
                }
              }, ["id=" + publicId]);
                    
        } catch (error) {
            this.parentStore.uiTransition.hide();            
        }
    }

    createValidationFields() {
        this.validationFields = [       
            {
                fieldId: "emailId",
                propName: "emailId",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },            
            {
                fieldId: "firstName",
                propName: "firstName",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },                                   
            {
                fieldId: "lastName",
                propName: "lastName",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },                                   
            {
                fieldId: "enabled",
                propName: "enabled",
                errorMessage: "",
                validators: []
            },
            {
                fieldId: "approved",
                propName: "approved",
                errorMessage: "",
                validators: []
            },
            {
                fieldId: "companyAdmin",
                propName: "companyAdmin",
                errorMessage: "",
                validators: []
            }
        ]
    }    

    gotoOrg = async() => {
        let acccount: Server.AccountDTO = this.getTypedObject<Server.AccountDTO>();
        new UIOrganizationFormStore(this.parentStore).showForm(acccount.company, this.updateCompany);
    }

    changePassword = (): void => {
        new UIChangePasswordStore(this.parentStore).showForm();
    }

    @action
    selectExpiryDate = (date: Date | null | undefined) => {
        let acccount: Server.AccountDTO = this.getTypedObject<Server.AccountDTO>();

        if (isNullOrUndefined(date)) {
            return;
        }
        acccount.expires = date;
    }

    updateCompany = (company: Server.Company) => {
        let acccount: Server.AccountDTO = this.getTypedObject<Server.AccountDTO>();
        acccount.company = {...company};
    }

    saveAccount = async() => {
        this.parentStore.uiTransition.show();
        try {
            let acccount: Server.AccountDTO = this.getTypedObject<Server.AccountDTO>();
            
            let response: Server.IdResponse = await this.parentStore.serverApi.postData<Server.AccountDTO, Server.IdResponse>
                ("accounts", acccount, "PUT");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }

            this.cancel();                    
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();            
        }     
    }
}
