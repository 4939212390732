import { observable, computed } from 'mobx';
import { AccountDTO, Administrator, LoginResponse } from '../web-store/ServerClasses';
import { ServerApi } from '../web-store/ServerApi';
import { AppStore } from './AppStore';
import { threadId } from 'worker_threads';
import { UIHomeFormStore } from './UIHomeFormStore';

export class DomainStore {
    @observable userAccount: AccountDTO;
    @observable adminAccount: Administrator;
    @observable _loggedIn: boolean;
    readonly serverApi: ServerApi;
    rootStore: AppStore;

    constructor(root: AppStore, serverApi: ServerApi) {
        this.rootStore = root;
        this.serverApi = serverApi;
        this.userAccount = null;
        this.adminAccount = null;
        this._loggedIn = false;
    }

    @computed
    get IsLoggedIn(): boolean {
        return this._loggedIn;
    }

    @computed
    get AdminMode(): boolean {
        return (this.adminAccount !== null);
    }

    get UserId() : string {
        if (this._loggedIn !== true) return "";
        return (this.AdminMode === true ? this.adminAccount.emailId : this.userAccount.emailId);
    }

    public logoff = async() => {
        this.rootStore.uiStore.uiTransition.show();
        try {
            await this.serverApi.logoff();
            this.rootStore.clearLocalUserInfo();
            this.rootStore.showgCaptchaBadge();
            // admin mode not relevant at logoff. 
            // it is reset in appInit
            this.setLoginStatus(null);
            await this.rootStore.appInit();
                
        } catch (error) {
            console.log(error);
        } finally {
            this.rootStore.uiStore.uiTransition.hide();
        }
    }

    public setLoginStatus(loginResponse: LoginResponse): void {
        // this._adminMode = asAdmin;
        if (loginResponse === null) {
            this._loggedIn = false;
            this.userAccount = null;
            this.adminAccount = null;
        } else if (loginResponse.statusCode === 200) {
            if (loginResponse.admin !== null) {
                this.adminAccount = loginResponse.admin;
                this.userAccount = null;
            } else {
                this.adminAccount = null;
                this.userAccount = loginResponse.account;
            }
            this._loggedIn = true;

            if (this.AdminMode !== true) {
                // home form is shown in user mode. in admin mode, just nav bar
                new UIHomeFormStore(this.rootStore.uiStore).showForm();
            }
        }
    }    
}

