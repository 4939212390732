import {observable, action} from 'mobx';
import { UIEditFormStore } from './UIEditFormStore';
import { UIStore } from './UIStore';
import { RequiredValidator } from '../web-store/Validators';
//import { ServerInsight } from '../web-store/ServerInsight';
import * as Server from '../web-store/ServerClasses'
import { ServerInsight } from '../web-store/ServerInsight';
import { ChangePasswordForm } from '../components/ChangePasswordForm';

export interface ChangePasswordData {
    userid: string;
    oldPassword: string;
    newPassword1: string;
    newPassword2: string;
}

export class UIChangePasswordStore extends UIEditFormStore<ChangePasswordData> {
    @observable showResetMessage: boolean;

    constructor(parent: UIStore) {
        super(parent);
        let data: ChangePasswordData = {
            userid: this.parentStore.rootStore.domainStore.UserId,
            newPassword1: "",
            newPassword2: "",
            oldPassword: "",
        }
        this.setBaseObject(data);
        this.createValidationFields();
        this.showResetMessage = false;
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "oldPassword",
                propName: "oldPassword",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                ]
            },
            {
                fieldId: "newPassword1",
                propName: "newPassword1",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                ]
            },
            {
                fieldId: "newPassword2",
                propName: "newPassword2",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                ]
            }
        ]
    }
    
    private _resetData() {
        const data: ChangePasswordData = this.getTypedObject<ChangePasswordData>();
        data.newPassword1 = "";
        data.newPassword2 = "";
        data.oldPassword = "";
    }

    @action
    showForm = async () => {
        this.parentStore.uiTransition.show();
        try {
              this.parentStore.addForm(ChangePasswordForm.create(this));
        } catch (error) {
            this.parentStore.uiTransition.hide();            
        }
    }

    @action
    hideResetMessage = (): void => {
        this.showResetMessage = false;
    }
    
    changePassword = async () : Promise<void> => {
        let data: ChangePasswordData = this.baseObject as ChangePasswordData;
        try {
            if (!this.validateForm()) 
                return;

            if (data.newPassword1 !== data.newPassword2) {
                this.parentStore.messageStore.showMessage(true, "Passwords don't match.");
                return;
            }

            this.parentStore.uiTransition.show();

            let ai: ServerInsight = new ServerInsight("appChangePwd");
            ai.data = {
                "userId": this.parentStore.rootStore.domainStore.UserId,
            }
            ai.update(this.parentStore.serverApi);

            let request: Server.ChangePasswordRequest = {
                emailId: this.parentStore.rootStore.domainStore.UserId,
                newPassword: data.newPassword1,
                oldPassword: data.oldPassword
            }

            let resp: Server.GenericResponse = await this.parentStore.serverApi.changePassword(request);
            this.parentStore.messageStore.showMessageFromResponse(resp);
            if (resp.statusCode === 200) {
                this.parentStore.messageStore.showInfoMessage("Your password has been changed.");
                this._resetData();
            } 
            
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error as Error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }
}
