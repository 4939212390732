import * as React from 'react';
import { observer } from "mobx-react"
import { Modal } from '@fluentui/react';
import { UITransitionStore } from '../stores/UITransitionStore';
import { Spinner, SpinnerSize, mergeStyleSets } from '@fluentui/react';

export interface UITransitionProps {
    store: UITransitionStore;
}

@observer
export class UITransition extends React.Component<UITransitionProps, any> { 
    
    public render() {

        // 2020-01-14 15:57:26 : need to override min height. otherwise it is
        // set to 176 by default fabric. for ui transition, 50 is enough as
        // inner div is set to 50
        const contentStyles = mergeStyleSets({
            container: {
              minHeight: 50,
              color: this.props.store.parentStore.theme.semanticColors.bodyText
            }
          });

        return (
            <Modal
                isOpen={this.props.store.Visible}
                isBlocking={true}
                containerClassName={contentStyles.container}
                // onDismiss={() => {this.props.store.hide();} }
            >
            <div 
                style={{ width: 200, height: 50, display: "flex", justifyContent: "center", alignItems: "center" }}
                // this is a safe guard to remove the transition. user can shift-click to get rid of it
                onClick={ (e) => { if (e.shiftKey) this.props.store.hide(); } }
            >
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 10 }}>
                        <Spinner
                            size={SpinnerSize.medium}
                        />
                    </div>
                    <div style={{ padding: 10 }}>
                        {this.props.store.message}
                    </div>
                </div>
            </div>
        </Modal>
        );
    }
}