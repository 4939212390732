import * as React from 'react';
import { observer } from 'mobx-react';
import * as Server from '../web-store/ServerClasses'
import { FormHeader } from './FormHeader';
import { ICommandBarItemProps, CommandBar, Stack, Link, Text } from '@fluentui/react';
import { ValidationTextField } from './ValidationTextField';
import { UIAdminSignupFormStore } from '../stores/UIAdminSignupFormStore';
import moment from 'moment';

class AdminSingupFormProps {
    store: UIAdminSignupFormStore;
}

@observer
export class AdminSignupForm extends React.Component<AdminSingupFormProps, any> {

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIAdminSignupFormStore) {
        return <AdminSignupForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [];
        let signup: Server.Signup = this.props.store.getTypedObject<Server.Signup>();

        if (signup.approved !== true) {
            items.push({
                key: "",
                text: "Approve",
                iconProps: {
                    iconName: "Unlock",
                },
                onClick: () => { this.props.store.confirmApproveSignup() }
            })
        }

        this.props.store.addCloseMenu(items);

        this.props.store.parentStore.setCommandBarItemsKeys(items);

        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    render() {
        let signup: Server.Signup = this.props.store.getTypedObject<Server.Signup>();
        let stackStyles = {root: {width: "80%" }};

        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content"> 
                    <FormHeader header="Signup" uiStore={this.props.store.parentStore} />

                    <Stack tokens={{childrenGap: 10, padding: 10 }} styles={stackStyles} >
                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Email"
                                fieldId="emailId"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 10}}>
                                <Stack.Item styles={{root: {width: "50%" }}} >
                                    <Text>
                                        Email Confirmed: {signup.emailConfirmed === true ? "Yes" : "No"}
                                    </Text>
                                </Stack.Item>
                                <Stack.Item align="end">
                                    <Stack horizontal tokens={{childrenGap: 10}}>
                                        <Stack.Item>
                                            <Text>
                                                Approved: {signup.approved === true ? "Yes" : "No"}
                                            </Text>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 10}}>
                                <Stack.Item styles={{root: {width: "50%" }}} >
                                    <Text>
                                        Signup Date: {moment( signup.signupDate).format("YYYY-MM-DD HH:mm:ss") + " UTC"}
                                    </Text>
                                </Stack.Item>
                                <Stack.Item align="end">
                                    <Stack horizontal tokens={{childrenGap: 10}}>
                                        <Stack.Item>
                                            <Text>
                                                Signup IP Address: 
                                            </Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Link target="_blank" href={"https://whatismyipaddress.com/ip/" + signup.ipAddress}>
                                                {signup.ipAddress}
                                            </Link>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 10}}>
                                <Stack.Item styles={{root: {width: "50%" }}} >
                                    <Text>
                                        Email Confirm Date: {moment( signup.emailConfirmDate).format("YYYY-MM-DD HH:mm:ss") + " UTC"}
                                    </Text>
                                </Stack.Item>
                                <Stack.Item align="end">
                                    <Stack horizontal tokens={{childrenGap: 10}}>
                                        <Stack.Item>
                                            <Text>
                                                Email Confirm IP Address: 
                                            </Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Link target="_blank" href={"https://whatismyipaddress.com/ip/" + signup.emailConfirmIp}>
                                                {signup.ipAddress}
                                            </Link>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="First Name"
                                fieldId="firstName"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Last Name"
                                fieldId="lastName"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Organization"
                                fieldId="companyName"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Address Line1"
                                fieldId="addressLine1"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Address Line2"
                                fieldId="addressLine2"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="City"
                                fieldId="city"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="State"
                                fieldId="state"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Zip"
                                fieldId="zip"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={true}
                                label="Country"
                                fieldId="country"
                                store={this.props.store}
                            />
                        </Stack.Item>

                    </Stack> 
    
                </div>
            </div>
        );
    }
}
