import React from "react";
import { UISidebarStore } from "../stores/UISidebarStore";
import { Nav, INavLink } from "office-ui-fabric-react";

interface SidebarProps {
  store: UISidebarStore;
}

export class Sidebar extends React.Component<SidebarProps, any> {
    private _sideBarRef: any;

    constructor(props: SidebarProps) {
        super(props);
        this._sideBarRef = React.createRef();
    }
 

  render() {
    let links: INavLink[] = this.props.store.uiStore.rootStore.domainStore.AdminMode ? this.props.store.adminMenus : 
      this.props.store.userMenus;

    return (
      <div className="app-sidebar-wrapper">
        <Nav
            groups={[
                {
                    links: links
                }
            ]
            }
        >
        </Nav>
      </div>       
    );
  }
}
