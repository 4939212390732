import { action} from 'mobx';
import {UIStore} from './UIStore'
import * as Server from '../web-store/ServerClasses'
import { UIEditFormStore } from './UIEditFormStore';
import { OrganizationForm } from '../components/OrganizationForm';
import { RequiredValidator, DataTypeValidator, DataType } from '../web-store/Validators';

export class UIOrganizationFormStore extends UIEditFormStore<Server.Company> {
    private updateCompany: (company: Server.Company) => void;

    constructor(parent: UIStore) {
        super(parent);
        this.createValidationFields();
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "name",
                propName: "name",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "addressLine1",
                propName: "addressLine1",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "addressLine2",
                propName: "addressLine2",
                errorMessage: "",
                validators: [
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },                                   
            {
                fieldId: "addressLine3",
                propName: "addressLine3",
                errorMessage: "",
                validators: [
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "city",
                propName: "city",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 100, 0, 0, false)]
            },
            {
                fieldId: "state",
                propName: "state",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 100, 0, 0, false)]
            },
            {
                fieldId: "zip",
                propName: "zip",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 100, 0, 0, false)]
            },
            {
                fieldId: "country",
                propName: "country",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 100, 0, 0, false)]
            }                              
        ]
    }    
    
    @action
    showForm = async (company: Server.Company, updateCompany: (company: Server.Company) => void) => {
        this.parentStore.uiTransition.show();
        try {
            this.setBaseObject(company);
            this.updateCompany = updateCompany;
            this.parentStore.addForm(OrganizationForm.create(this));
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }

    @action 
    save = async (): Promise<void> => {
        
        if (!this.validateForm()) return;
        const company: Server.Company = (this.baseObject as Server.Company);

        this.parentStore.uiTransition.show();

        try {          
            let resp: Server.IdResponse = await this.parentStore.serverApi.updateEntity("companies", company);
            this.parentStore.messageStore.showMessageFromResponse(resp);
            if (resp.statusCode === 200) {
                
                if (this.parentStore.rootStore.domainStore.AdminMode !== true)  {
                    this.parentStore.rootStore.domainStore.userAccount.company = {...this.baseObject};
                } else {
                    if (this.updateCompany) {
                        this.updateCompany({...this.baseObject});
                    }
                }

                this.parentStore.removeForm();
            }
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error as Error);
        }
        finally {
            this.parentStore.uiTransition.hide();
        }
    }    
}
