export function isNullOrUndefined(o: any): boolean {
    return (o === null || o === undefined);
}

export function isArray(a: any): boolean {
    try {
        let a2 = a.slice();
        return true;
    } catch (error) {
        return false;
    }
}

export function to2DecimalNumber(n: number): string {
    return (Math.round(n * 100) / 100).toFixed(2);
}