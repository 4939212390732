import {UIStore} from './UIStore'
import {observable, computed, action} from 'mobx';
import { isNullOrUndefined } from 'util';

export class UITransitionStore {
    parentStore: UIStore;
    @observable message: string;
    @observable private _visible: boolean;
    private _defaultMessage: string;

    constructor(parent: UIStore) {
        this.parentStore = parent;
        this._defaultMessage = "Processing ..."
        this.message = this._defaultMessage;
        this._visible = false;
    }

    @computed
    public get Visible(): boolean {
        return this._visible;
    }

    @action
    public show = (message?: string) => {
        if (!isNullOrUndefined(message)) this.message = message;
        this._visible = true;
    }

    @action
    hide = (reset?: boolean) : void => {
        if (reset === true) {
            this.message = this._defaultMessage;
        } 
        this._visible = false;
    }
}
