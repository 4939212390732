import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { observer } from 'mobx-react';
import { Link } from '@fluentui/react/lib/Link';
import { Text } from '@fluentui/react/lib/Text';
import { CenterContainerForm } from './CenterContainerForm';
import { ValidationTextField } from './ValidationTextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {ReCaptcha} from 'react-recaptcha-v3'
import { UIResetPasswordFormStore, ResetPassword } from '../stores/UIResetPasswordFormStore';
import { ValidationPasswordField } from './ValidationPasswordField';

interface ResetPasswordFormProps {
    store: UIResetPasswordFormStore;
}

@observer
export class ResetPasswordForm extends React.Component<ResetPasswordFormProps, any> {

    constructor(props: ResetPasswordFormProps) {
        super(props);
        this.props.store.ref = React.createRef();
    }

    public render() {
        const signInBoxStyle = CenterContainerForm.boxStyle(this.props.store.parentStore);
        const signInCellStyle = CenterContainerForm.cellStyle();      

        const resetPasswordData: ResetPassword = this.props.store.baseObject;

        return (
            <form>
                <CenterContainerForm>
                    <div style={signInBoxStyle}>
                        <div style={{display: "flex", paddingTop: 10, flexDirection: "column", alignItems: "center" }}>
                            <Text variant="mediumPlus">
                                Aellius Web Store
                            </Text>

                            <Text variant="mediumPlus">
                                Reset My Password
                            </Text>
                        </div>
                
                        <div style={signInCellStyle}>
                            <ValidationTextField 
                                value={resetPasswordData.userId} 
                                placeholder="User Id"
                                resizable={false}
                                autoFocus={true} 
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                fieldId="userId"
                                store={this.props.store}
                            />
                        </div>

                        <div style={signInCellStyle}>
                            <ValidationPasswordField 
                                value={resetPasswordData.password1} 
                                type="password"
                                placeholder="Password"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                fieldId="password1"
                                store={this.props.store}
                            />
                        </div>

                        <div style={signInCellStyle}>
                            <ValidationPasswordField 
                                value={resetPasswordData.password2} 
                                type="password"
                                placeholder="Confirm Password"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                fieldId="password2"
                                store={this.props.store}
                            />
                        </div>

                        <div style={signInCellStyle}>
                            <ReCaptcha 
                                sitekey={this.props.store.parentStore.rootStore.ClientConfig.recaptchaSiteKey}
                                action="resetPassword"
                                verifyCallback={this.props.store.verifyCaptcha}
                                ref={ref => this.props.store.ref = ref}
                            />                        
                        </div>

                        <div style={signInCellStyle}>
                            <PrimaryButton
                                style={{ height:40, width: '100%' }}
                                disabled={false}
                                checked={false}
                                text="Reset My Password"
                                type="submit"
                                onClick={(e) => {e.preventDefault(); this.props.store.resetPassword() }}
                            />      
                        </div>
                    </div>
                    

                    <div style={{...signInCellStyle, height: 52, maxWidth: 320}}>
                        <div style={{display: resetPasswordData.isLoading ? "block" : "none" }} >  
                            <Spinner size={ SpinnerSize.large } ariaLive='assertive'
                                styles={ CenterContainerForm.getSpinnerStyles  }
                            />
                         </div>

                        <Text styles={
                            {
                                root: {
                                    color: resetPasswordData.isError ? 
                                        this.props.store.parentStore.theme.semanticColors.errorText :
                                        this.props.store.parentStore.theme.semanticColors.bodyText
                                }
                            }}>
                            { resetPasswordData.message }
                        </Text>
                    </div>                     
                    
                    { (this.props.store.resetSuccess === true) ? 
                    
                        <div style={{...signInCellStyle, display: "flex", justifyContent: "center"}}>
                            <Link onClick={this.props.store.navigateToLogin}>
                                Back to Sign In
                            </Link>                             
                        </div>   
                        : 
                        null
                    }

                </CenterContainerForm>
            </form>

        );
    }
}
