import { UIStore } from "./UIStore";
import { action, observable,  } from "mobx";
import { CreateAssetsResponse, ProductDTO, DeleteAssetsRequest, Asset } from "../web-store/ServerClasses";
import { UIFormStore } from "./UIFormStore";
import { AssetsForm } from "../components/AssetsForm";
import copy from 'copy-to-clipboard';

export interface AssetFormData {
    product: ProductDTO;
    assetResponse: CreateAssetsResponse
}

export class UIAssetsFormStore extends UIFormStore {
    assetData: AssetFormData;
    @observable disableLicense: boolean;
    @observable disableInstall: boolean;

    constructor(parent: UIStore, assetData: AssetFormData) {
        super(parent);
        this.setBaseObject({notUsed: 1});
        this.assetData = assetData;
        this.disableLicense = false;
        this.disableInstall = false;
    }

    @action
    showForm = async () => {
        this.parentStore.uiTransition.show();
        try {
            this.parentStore.addForm(AssetsForm.create(this));                    
        } catch (error) {
            this.parentStore.uiTransition.hide();            
        }
    }

    protected beforeCancel = (cancelAction: {dontClose: boolean}) => {
        this._removeAssets();
        return cancelAction.dontClose = false;
    }

    private _removeAssets = async() => {
        let request: DeleteAssetsRequest = {
            ids: this.assetData.assetResponse.assets.map(a => a.id)
        }
        await this.parentStore.serverApi.postData("assets", request, "DELETE");
    }

    copyLicenseToClipboard = () => {
        try {
            copy(this.assetData.assetResponse.licenseKey);
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        }
    }

    downloadLicense = () => {
        this.disableLicense = true;
        let asset = this.assetData.assetResponse.assets.find(a => a.tag == "License");
        this._createPseudoLink(asset);
    }

    downloadInstall = () => {
        this.disableInstall = true;
        let asset = this.assetData.assetResponse.assets.find(a => a.tag == "Install");
        this._createPseudoLink(asset);
    }

    private _createPseudoLink(asset: Asset) {
        let url = this.parentStore.rootStore.serverApi.getAssetUrl(asset.id, false);
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}