import * as React from "react";
import { observer } from "mobx-react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { Link } from "office-ui-fabric-react/lib/Link";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as Server from "../web-store/ServerClasses"
import { FormHeader } from "./FormHeader";
import { UIProductsFormStore } from "../stores/UIProductsFormStore";
import { IColumnProps } from "../stores/UISearchFormStore";
import { PrimaryButton, ICommandBarItemProps, CommandBar, Stack, Text, Button } from "office-ui-fabric-react";
import { UIHomeFormStore } from "../stores/UIHomeFormStore";

class HomeFormProps {
    store: UIHomeFormStore;
}

@observer
export class HomeForm extends React.Component<HomeFormProps, any> {

    componentDidMount () {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIHomeFormStore) {
        return <HomeForm store={store} />;
    }

    private _renderProduct(product: Server.ProductDTO, index: number) {
        return (
         <Stack horizontal 
            verticalAlign="center" 
            key={"product" + index.toString()} 
            styles={{root:{ marginTop: 20, marginBottom: 20 } }} 
            tokens={{childrenGap: 10}}
        >
             <Stack.Item styles={{ root: { padding: 10} }}>
                 <img src={product.info.image} />
             </Stack.Item>
             <Stack.Item>
                 <Stack tokens={{childrenGap: 10}}>
                     <Stack.Item>
                        <Text variant="xLarge" >
                            {product.name + " v" + product.version}
                        </Text>
                     </Stack.Item>
                     <Stack.Item>
                        <Text variant="large" >
                            {product.info.tagLine}
                        </Text>
                     </Stack.Item>
                     <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10}}>
                        <PrimaryButton
                           text="Learn More"
                           onClick={() => this.props.store.goToProductUrl(product)}
                           iconProps= {
                            {
                                iconName: "Info"
                            }}                        
                        />

                        <PrimaryButton 
                           text="Buy"
                           onClick={() => this.props.store.startPurchase(product)}
                           iconProps= {
                            {
                                iconName: "ShoppingCart"
                            }}                        
                        />
                        
                        <PrimaryButton 
                           text="Trial"
                           onClick={() => this.props.store.startTrial(product)}
                           iconProps= {
                            {
                                iconName: "TestStep"
                            }}
                        />
                        
                        <PrimaryButton 
                           text="Pricing"
                           onClick={() => this.props.store.startPricing(product)}
                           iconProps= {
                            {
                                iconName: "Money"
                            }}
                        />
                     
                        <PrimaryButton 
                           text="Videos"
                           onClick={() => this.props.store.gotoVideos(product)}
                           iconProps= {
                            {
                                iconName: "Video"
                            }}
                        />
                     </Stack>
                 </Stack>
             </Stack.Item>
         </Stack>
        )
    }

    render() {
        
        return (
            <div className="page-form">
                <div className="page-content">
                    <Text variant="xLargePlus" >
                        Products
                    </Text>

                    {
                        this.props.store.products.map((p, i) => this._renderProduct(p, i))
                    }

                </div>
            </div>
        );
    }
}   