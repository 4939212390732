import * as React from "react";
import { UIStore } from "./UIStore";
import { observable } from "mobx";
import { UIAccountFormStore } from "./UIAccountFormStore";
import { AccountForm } from "../components/AccountForm";

export class UIUserMenuStore {
    public uiStore: UIStore;
    @observable isContextMenuVisible: boolean;
    // @observable contextMenuTarget: any;

    constructor(parent: UIStore) {
        this.uiStore = parent;
    }

    gotoAccount = async():  Promise<void> => {
        if (this.uiStore.rootStore.domainStore.AdminMode === true) {
            alert("This functionality has not yet been implemented.");
            // new UIAccountFormStore(this.uiStore).showForm(this.uiStore.rootStore.domainStore.adminAccount.publicId);
        } else {
            new UIAccountFormStore(this.uiStore).showForm(this.uiStore.rootStore.domainStore.userAccount.publicId);
        }
    }

    signOut = async () : Promise<void> => {
        this.uiStore.uiTransition.show();
        try {
            await this.uiStore.rootStore.domainStore.logoff();
        } finally {
            this.uiStore.uiTransition.hide();
        }
    }
}