import * as React from 'react';
import { Stack, IconButton, Button, initializeIcons, Text, IStackItemTokens } from '@fluentui/react';

export class TestVideoDownload extends React.Component<any,  any> {
    videoRef: any;
    
    constructor(props: any, context: any) {
        super(props, context);          
        this.videoRef = React.createRef();
    }

    private sourceOpen() {

    }

    componentDidMount() {
        // this.tryFileReader();
        this.tryMediaSource();
    }

    private async tryFileReader() {
        try {
            let assetUrl: string = "test.mp4";
            
            const response = await fetch(assetUrl)
            const data = await response.blob()
            console.log("got here1");

            const metadata = {
              type: "video/mp4"
            }
            let file: File = new File([data], assetUrl, metadata);
            const url = URL.createObjectURL(file);
            console.log("got here2");

            this.videoRef.current.src = url;
        } catch (error) {
            console.error(error);
        }
    }

    private tryMediaSource() {
        try {
            //let assetUrl: string = "bunny.mp4";
            let assetUrl: string = "test_frag.mp4";
            //var mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2';
            // var mimeCodec = 'video/mp4; codecs="avc1.640029, mp4a.40.5"'
            // var mimeCodec = 'video/mp4; codecs="avc1.640040, mp4a.40.5"'
            var mimeCodec = 'video/mp4; codecs="avc1.420033, mp4a.40.5"'
    
            if (this.videoRef.current && 'MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
                var mediaSource = new MediaSource;
                const url = URL.createObjectURL(mediaSource);

                this.videoRef.current.src = url;

                mediaSource.addEventListener('sourceopen', () => {
                const videoSourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                videoSourceBuffer.addEventListener('error', (e) => {
                    console.log(e);
                });

                // this is just an express route that returns an mp4 file using `res.sendFile`
                fetch(assetUrl).then((response) => {
                    console.log("Status:"  + response.status);
                    return response.arrayBuffer();
                }).then((videoData) => {
                    // console.log(videoData);
                    // videoSourceBuffer.addEventListener('updateend', () => {
                    //     mediaSource.endOfStream();
                    //     //this.videoRef.current.play();
                    //     this.playVideo(this.videoRef.current);
                    //     console.log("got here3");
                    //     //console.log(mediaSource.readyState); // ended
                    //   });
                    videoSourceBuffer.appendBuffer(videoData);
                    console.log("got here2");
                });
            });
            } else {
                console.error('Unsupported MIME type or codec: ', mimeCodec);
                return;
            }
        } catch (error) {
            console.error(error);
        }
    }

    private async playVideo(vf: HTMLVideoElement) {
        try {
            //await vf.play();
        } catch (error) {
            console.error("Error in play back: " + error);
        }
    }

    render() {
        initializeIcons();

        return (
            <>
                <Stack tokens={{childrenGap: 10, padding: 10 }} style={{width: 300 }} >
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10, padding: 10 }}>
                            <Stack.Item>
                               <video ref={this.videoRef} controls style={{width: 800, height: 600}} ></video>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>               
            </>
        )
    }
}
