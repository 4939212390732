import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom'
import './styles/web-store.css';
import { App } from './components/App';
import * as serviceWorker from './serviceWorker';
import { Landing } from './components/Landing';
import { AppStore } from './stores/AppStore';
import { initializeIcons } from '@fluentui/react';
import { loadTheme, getTheme } from  '@fluentui/react';
import { InitForm } from './stores/UIStore';
import {TestReactPdf} from './play/TestReactPdf';
import { pdfjs } from 'react-pdf';
import { TestAccordion } from './play/TestAccordion';
import { TestFSCheckout } from './play/TestFSCheckout';
import { isNullOrUndefined } from './web-store/WebStoreUtil';
import { TestVideoDownload } from './play/TestVideoDownload';

(() => {
    try {
        // testCrypto();
        // renderLanding();
        loadConfig();
        //  renderPlay();
        // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        
    } catch (error) {
        console.log(error);
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
)();


function renderNoInit (message: string) {
    const container = document.querySelector('#root');
    render(
        <div style={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "auto" }}>
            <div className="noInit">
                <div className="ms-fontSize-18" >
                    {message}
                </div>
            </div>
        </div>,
        container
    );
}

function renderLanding() {
    const container = document.querySelector('#root');
    render(
        <Landing  />,
        container
    );
}

function renderPlay() {
    const container = document.querySelector('#root');
    render(
        <TestVideoDownload />,
        container
    );
}

function loadConfig() {
    try {      
        const appStore: AppStore = new AppStore();
            appStore.appInit().then(() => {

                renderApp(appStore);
                //renderPlay();

        }).catch((err) => {
            console.error(err);
            renderNoInit("The application did not initialize successfully.");
        });
    } catch (error) {
        console.error(error);
        renderNoInit("Unable to load configuration.");
    }
}

function renderApp(store: AppStore) {
    try {
        const container = document.querySelector('#root');
          
        // appInit initializes queryParams in its store 
        if (store.queryParams.rp === "true" && store.queryParams.id) {
            store.uiStore.initForm = InitForm.resetPassword;
            store.passwordResetId = store.queryParams.id;
        }

        if (store.queryParams.ce === "true" && store.queryParams.data && store.queryParams.cdata) {
            store.uiStore.initForm = InitForm.confirmEmail;
        }
               
        initializeIcons();

        render(<App store={store} />, container);
    } catch (error) {
        renderNoInit(error.message)
    }
}