import * as React from 'react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout';
import { IconButton, DefaultButton  } from '@fluentui/react/lib/Button';
import { getRTL } from '@fluentui/react/lib/Utilities';
import { Icon } from '@fluentui/react';
import { observer } from 'mobx-react';
import { UIUserMenuStore } from '../stores/UIUserMenuStore';

export interface UserMenuProps {
    store: UIUserMenuStore;
}
  
@observer
export class UserMenu extends React.Component<UserMenuProps, any> {

  private _renderContextMenu() {

    let items: IContextualMenuItem[] = [
      {
        key: "account",
        iconProps: {
          iconName: "ContactInfo"
        },
        name: "My Account",
        onClick: () => {
          this.props.store.gotoAccount();
        },
      },             
      {
        key: 'signout',
        iconProps: {
          iconName: "PowerButton",
        },
        name: "Sign out",
        onClick: () => {
          this.props.store.signOut();
        }
      }
    ];

      const menuProps: IContextualMenuProps = {
          isBeakVisible: true,
          gapSpace: 0,
          beakWidth: 20,
          directionalHint: getRTL() ? DirectionalHint.bottomLeftEdge : DirectionalHint.bottomRightEdge,
          items: items     
      }

    return (
        <IconButton 
            menuProps={menuProps} 
            iconProps={
                {
                    iconName: "Contact"
                }
            } 
            title="Account" 
        />
    );
  }

  public render() {
    let userName: string = this.props.store.uiStore.rootStore.domainStore.AdminMode ? 
        this.props.store.uiStore.rootStore.domainStore.adminAccount.name :
        this.props.store.uiStore.rootStore.domainStore.userAccount.firstName 

    return (
      <div className="user-menu">
          <div style={{marginRight: 20 }}>
              { userName }
          </div>
         { this._renderContextMenu() }
      </div>
    );
  }
}
