import {observable, action} from 'mobx';
import {UIStore} from './UIStore'
import { String } from 'typescript-string-operations';
import { UIFormStore } from './UIFormStore';
import { IColumn } from '@fluentui/react';
import * as Server from '../web-store/ServerClasses'
import { isUndefined } from 'util';

export interface IColumnProps {
    key: string;
    name: string;
    fieldName: string;
    isSortedDescending: boolean;
    isSorted: boolean;
}

export class SearchFormStore<RecordType> extends UIFormStore {
    columns: IColumnProps[];
    records: RecordType[];
    protected readonly _filterPropName: string;

    @observable displayedRecords: RecordType[];
    @observable displayedColumns: IColumnProps[];
    @observable sortOrder: boolean;
    filterValue: string;

    constructor(parent: UIStore, filterPropName: string) {
        super(parent);
        this._filterPropName = filterPropName;
        this.columns = this.setColumns();
        this.displayedColumns = this.columns.slice();
        this.filterValue = "";
    }

    public async getRecordsFromApi(entityName: string, callbackfn: (records: RecordType[], response?: Server.BaseResponse) => void, params?: string[]) {
      try {
          let resp: Server.GetEntitiesResponse<RecordType> = await this.parentStore.serverApi.getEntities(entityName, params);
          // this.parentStore.messageStore.showInfoMessage(resp.message, (resp.statusCode !== 200));
          if (resp.statusCode === 200) {
            let records: RecordType[] = resp.entities.map(x => x as RecordType)
            this.setRecords(records);

            if (callbackfn) {
              callbackfn(records, resp);
            }
          } else {
            this.parentStore.messageStore.showMessageFromResponse(resp);
            this.parentStore.uiTransition.hide();
          }
        } catch (error) {
          this.parentStore.messageStore.showErrorMessage((error as Error).message);
          if (callbackfn) {
            callbackfn(null, null);
          }
        }    
    }

    @action
    protected setRecords(records?: RecordType[]) {
      if (!isUndefined(records)) this.records = records;

      if (String.IsNullOrWhiteSpace(this.filterValue)) {
          this.filterValue = "";
          this.displayedRecords = (this.records.length > 0) ? this.records.slice() : [];
        } else {
          this.displayedRecords = this.records.filter(i => this.getFilterRecordValue(i).toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1);
      }
    }

    protected getFilterRecordValue(r: RecordType): string {
      // default is to use _filterPropName,  which should point to a stirng property
      // override to use a custom value (concatenated etc.)
      return (r as any)[this._filterPropName] as string;
    }

    protected setColumns(): IColumnProps[] {
        // must be overridden to set columns
        return null;
    }

    filter = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
       if (String.IsNullOrWhiteSpace(text)) {
            this.filterValue = "";
        } else {
            this.filterValue = text;
       }
       this.setRecords();
    }

    @action
    sort = (items:  RecordType[], sortBy: string, descending = false):  RecordType[] => {
        if (descending) {
          return items.sort((a:  RecordType, b:  RecordType) => {
            if ((a as any)[sortBy] < (b as any)[sortBy]) {
              return 1;
            }
            if ((a as any)[sortBy] > (b as any)[sortBy]) {
              return -1;
            }
            return 0;
          });
        } else {
          return items.sort((a:  RecordType, b:  RecordType) => {
            if ((a as any)[sortBy] < (b as any)[sortBy]) {
              return -1;
            }
            if ((a as any)[sortBy] > (b as any)[sortBy]) {
              return 1;
            }
            return 0;
          });
        }
    }
    
    @action
    onColumnClick = (ev: React.MouseEvent<HTMLElement>, clickedColumn: IColumn): void => {
        if (String.IsNullOrWhiteSpace(clickedColumn.fieldName)) return;

        const columns = this.columns;
        const items = this.displayedRecords;

        let newItems: RecordType[] = items.slice();
        const newColumns: IColumnProps[] = columns.slice();
        const currColumn: IColumnProps = newColumns.filter((currCol: IColumnProps, idx: number) => {
          return clickedColumn.key === currCol.key;
        })[0];
        newColumns.forEach((newCol: IColumnProps) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });

        this.displayedColumns = newColumns;
        this.displayedRecords = this.sort(newItems, currColumn.fieldName, currColumn.isSortedDescending);
    }    
}
