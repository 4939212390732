import {UIFormStore} from './UIFormStore'
import { UIStore } from './UIStore';
import * as Server from '../web-store/ServerClasses'

export class UIEditFormStore<Entity> extends UIFormStore {

    constructor(parent: UIStore) {
        super(parent);
    }

    public async getRecordFromFromApi(entityName: string, callbackfn: (entity: Entity, response?: Server.BaseResponse) => void, params?: string[])  {
        try {
            let resp: Server.GetEnityResponse<Entity> = await this.parentStore.serverApi.getEntity<Entity>(entityName, params);
            //this.parentStore.messageStore.showMessageFromResponse(resp);

            if (resp.statusCode === 200) {
                this.setBaseObject(resp.entity);

                if (callbackfn) {
                    callbackfn(resp.entity, resp);
                }
            } else {
                this.parentStore.messageStore.showErrorMessage(resp.message);
                this.parentStore.uiTransition.hide();
            }
            
        } catch (error) {
            this.parentStore.messageStore.showErrorMessage((error as Error).message);
            if (callbackfn) {
                callbackfn(null, null);
              }
        }
        finally {
            this.parentStore.uiTransition.hide();
        }
    }  
}