import { action} from 'mobx';
import {UIStore} from './UIStore'
import * as Server from '../web-store/ServerClasses'
import { UIEditFormStore } from './UIEditFormStore';
import { RequiredValidator, DataTypeValidator, DataType } from '../web-store/Validators';
import { AdminSignupForm } from '../components/AdminSignupForm';
import { DialogMode, DialogResult } from './UIConfirmDialogStore';

export class UIAdminSignupFormStore extends UIEditFormStore<Server.Signup> {
    
    constructor(parent: UIStore) {
        super(parent);
        this.createValidationFields();
    }

    @action
    showForm = async (id: number) => {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordFromFromApi("signups", (signup, reponse) => { 
                if (reponse.statusCode === 200) {
                    this.parentStore.addForm(AdminSignupForm.create(this));
                }
              }, ["id=" + id.toString()]);

        } catch (error) {
            this.parentStore.uiTransition.hide();            
        }
    }

    createValidationFields() {
        this.validationFields = [       
            {
                fieldId: "emailId",
                propName: "emailId",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },            
            {
                fieldId: "firstName",
                propName: "firstName",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },                                   
            {
                fieldId: "lastName",
                propName: "lastName",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "signupDate",
                propName: "signupDate",
                errorMessage: "",
                //dataType: DataType.Date,
                validators: []
            },
            {
                fieldId: "companyName",
                propName: "companyName",
                errorMessage: "",
                validators: []
            },
            {
                fieldId: "addressLine1",
                propName: "addressLine1",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "addressLine2",
                propName: "addressLine2",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]            
            },
            {
                fieldId: "addressLine3",
                propName: "addressLine3",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "city",
                propName: "city",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "state",
                propName: "state",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "zip",
                propName: "zip",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            },
            {
                fieldId: "country",
                propName: "country",
                errorMessage: "",
                validators: [
                    new RequiredValidator(), 
                    new DataTypeValidator(DataType.String, 200, 0, 0, false)]
            }
        ]
    }    

    confirmApproveSignup = async() => {
        let signup: Server.Signup = this.getTypedObject<Server.Signup>();
        if (signup.approved === true) {
            this.parentStore.messageStore.showErrorMessage("This signup is already approved.");
            return;
        }

        this.parentStore.confirmDialogStore.callback = (context, result) => { if (result === DialogResult.Yes) this.approveSignup(); }
        this.parentStore.confirmDialogStore.dialogMode = DialogMode.OKCancel;
        this.parentStore.confirmDialogStore.showDialog("Confirm", "Are you sure you want to approve this signup?");
    }

    approveSignup = async() => {
        this.parentStore.uiTransition.show();
        try {
            let signup: Server.Signup = this.getTypedObject<Server.Signup>();
            let idDto: Server.IdDTO = {
                id: signup.id
            }
            
            let response: Server.GenericResponse = await this.parentStore.serverApi.postData<Server.IdDTO, Server.GenericResponse>
                ("signupapprove", idDto, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }

            this.cancel();
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();            
        }     
    }    
}
