import {action, observable} from 'mobx';
import {UIStore, InitForm} from './UIStore'
import {UIEditFormStore} from './UIEditFormStore'
import * as Server from '../web-store/ServerClasses'
import { RequiredValidator, EmailValidator } from '../web-store/Validators';
import { ServerInsight } from '../web-store/ServerInsight';
import { IComboBox, IComboBoxOption } from '@fluentui/react';

export interface WebStoreSignup {
    userId: string;
    password1: string;
    password2: string;
    firstName: string;
    lastName: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    acceptedTerms: boolean;
    captcha: string;
}

export class UISignupFormStore extends UIEditFormStore<WebStoreSignup> {
    ref: any;
    @observable registerDisabled: boolean;
    @observable signupReceived: boolean;
    @observable signupMessage: string;   
    @observable isError: boolean;   
    @observable isLoading: boolean;
    @observable rejectError: boolean;

    constructor(parent: UIStore, signupData: WebStoreSignup) {
        super(parent)
        signupData.country = "United States";
        this.setBaseObject(signupData);
        this.createValidationFields();
        this.registerDisabled = false;
        this.signupReceived = false;
        this.signupMessage = "";
        this.isError = false;
        this.isLoading = false;
        this.rejectError = false;
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "userId",
                propName: "userId",
                errorMessage: "",
                validators: [new EmailValidator()]
            },
            {
                fieldId: "password1",
                propName: "password1",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "password2",
                propName: "password2",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },            
            {
                fieldId: "firstName",
                propName: "firstName",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "lastName",
                propName: "lastName",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },            {
                fieldId: "companyName",
                propName: "companyName",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "addressLine1",
                propName: "addressLine1",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "addressLine2",
                propName: "addressLine2",
                errorMessage: "",
                validators: []
            },
            {
                fieldId: "addressLine3",
                propName: "addressLine3",
                errorMessage: "",
                validators: []
            },
            {
                fieldId: "city",
                propName: "city",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "state",
                propName: "state",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "zip",
                propName: "zip",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "acceptedTerms",
                propName: "acceptedTerms",
                errorMessage: "",
                validators: []
            }     
        ]
    }
    
    changeCountry  = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        let signupData: WebStoreSignup = this.baseObject;
        signupData.country = option.key as string;
    } 

    @action
    navigateToSignin = (): void => {
        this.parentStore.initForm = InitForm.signIn;
        this.isError = false;
        this.signupMessage = "";
    }

    @action
    resetCaptcha = (): void => {
        try {
            (this.baseObject as WebStoreSignup).captcha = "";
            if (this.ref != null) this.ref.execute();
        } catch (error) {
            console.error(error);
        }
    }

    @action
    verifyCaptcha = (response: string): void => {
        (this.baseObject as WebStoreSignup).captcha = response;
    }

    @action 
    clear = () => {
        (this.baseObject as WebStoreSignup) = {  
            userId: "",
            password1: "",
            password2: "",
            firstName: "",
            lastName: "",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            acceptedTerms: false,
            captcha: ""
        };
        this.resetCaptcha();
        this.rejectError = false;
        this.isLoading = false;
        this.signupMessage = "";
    }

    @action
    signUp = async (): Promise<void> => {
        let signupData: WebStoreSignup = this.baseObject;
        this.signupMessage = "";
        this.isError = false;

        this.registerDisabled = true;
        this.signupReceived = false;
        this.rejectError = false;

        this.parentStore.uiTransition.show();
        try {
            if (this.validateForm() !== true) return;

            if (signupData.acceptedTerms !== true) {
                this.isError = true;
                this.signupMessage = "You must accept the terms and conditions to register."
                return;
            }

            if (signupData.password1 != signupData.password2) {
                this.isError = true;
                this.signupMessage = "Passwords don't match."
                return;
            }
                            
            let request: Server.SignupRequest = {...signupData, 
                emailId: signupData.userId, 
                password: signupData.password2, 
                captcha: signupData.captcha, 
                brc: "" 
            };

            this.isLoading = true;
            let response: Server.GenericResponse = await this.parentStore.serverApi.postData<Server.SignupRequest, Server.GenericResponse>("signups", request, "POST");
            if (response.statusCode !== 200) {
                // this.signupMessage = "Your request could not be processed at this time."
                this.rejectError = true;
                return;
            }

            this.signupReceived = true;
            this.signupMessage = "Your registration request has been received. An email was sent to confirm your email address.\r\nPlease confirm it to complete your registration."
            
        } catch (error) {
            console.error(error);
            this.isError = true;
            this.signupMessage =  "An unexpected error occured.";
        } finally {
            this.parentStore.uiTransition.hide();
            this.registerDisabled = false;
            this.isLoading = false;
            this.resetCaptcha();
        }
    }
}
