import { action, observable} from 'mobx';
import * as Server from '../web-store/ServerClasses'
import { AppStore } from './AppStore';

export class UIConfirmEmailFormStore {
    appStore: AppStore;
    @observable confirmed: boolean;
    @observable error: boolean;
    @observable confirmMessage: string;
    
    constructor(appStore: AppStore) {
        this.appStore = appStore;
        this.confirmed = false;
        this.error = false;
        this.confirmMessage = "Please wait while we confirm your email.";
    }

    @action
    confirm = async () => {
        try {
            this.confirmed = false;

            let request: Server.ConfirmEmailRequest  = {
                data: this.appStore.queryParams.data,
                cData: this.appStore.queryParams.cdata
            }
            
            let response: Server.GenericResponse = await this.appStore.serverApi.postData<Server.ConfirmEmailRequest, Server.GenericResponse>("confirmemail", request, "POST");
            if (response.statusCode !== 200) {
                this.error= true;
                this.confirmMessage = response.message;
                return;
            }

            this.confirmed = true;
            this.confirmMessage = "Your email has been confirmed.You will be notified when your registration has been approved (typically, within 2 buisness days)."

        } catch (error) {
            this.confirmed = true;
            this.error= false;
            this.confirmMessage = (error as Error).message;
        } finally {
            setTimeout(() => {
                window.location.href =  "https://www.aellius.com";
            }, (5000));
        }
    }
}
