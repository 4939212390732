import * as React from "react";
import { observer } from "mobx-react";
import { FormHeader } from "./FormHeader";
import { PrimaryButton, ICommandBarItemProps, CommandBar, Stack, Text, Button, ITheme, FontWeights, mergeStyleSets, IconButton, Modal } from '@fluentui/react';
import { UIVideosFormStore } from "../stores/UIVideosFormStore";
import { ProductVideo } from "../web-store/ServerClasses";

class VideosFormPros {
    store: UIVideosFormStore;
}

@observer
export class VideosForm extends React.Component<VideosFormPros, any> {
    
    constructor(props: any) {
        super(props);          
        this.props.store.videoRef = React.createRef();
    }

    componentDidMount () {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIVideosFormStore) {
        return <VideosForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [];
        this.props.store.addCloseMenu(items);
        this.props.store.parentStore.setCommandBarItemsKeys(items);
        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    private _renderVideo(video: ProductVideo, index: number) {
        return (
         <Stack horizontal 
            verticalAlign="center" 
            key={"product" + index.toString()} 
            styles={{root:{ marginTop: 20, marginBottom: 20 } }} 
            tokens={{childrenGap: 10}}
        >
             <Stack.Item styles={{ root: { padding: 10} }}>
                 <img src={video.image} />
             </Stack.Item>
             <Stack.Item>
                 <Stack tokens={{childrenGap: 10}}>
                     <Stack.Item>
                        <Text variant="xLarge" >
                            {video.name}
                        </Text>
                     </Stack.Item>
                     <Stack.Item>
                        <Text variant="large" >
                            {video.description}
                        </Text>
                     </Stack.Item>
                     <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10}}>
                        <PrimaryButton
                           text="Watch"
                           onClick={() => this.props.store.gotoVideo(video)}
                           iconProps= {
                            {
                                iconName: "Info"
                            }
                        }                        
                        />
                     </Stack>
                 </Stack>
             </Stack.Item>
         </Stack>
        )
    }

    _renderModalVideo() {

        let theme: ITheme = this.props.store.parentStore.theme;

        const bodyHeight = document.documentElement.scrollHeight;
        const contentStyles = mergeStyleSets({
            container: {
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'stretch',
            },
            header: [
              theme.fonts.xLargePlus,
              {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
              },
            ],
            body: {
              flex: '4 4 auto',
              padding: '0 24px 24px 24px',
              height: bodyHeight - 200,
              overflowY: 'hidden',
              selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
              },
            },
          });
        const toggleStyles = { root: { marginBottom: '20px' } };
        const iconButtonStyles = {
            root: {
              color: theme.palette.neutralPrimary,
              marginLeft: 'auto',
              marginTop: '4px',
              marginRight: '2px',
            },
            rootHovered: {
              color: theme.palette.neutralDark,
            },
          };
        
        return (
            <Modal
                isOpen={this.props.store.showVideo}
                onDismiss={this.props.store.hideVideo}
                isBlocking={false}
                containerClassName={contentStyles.container}
                styles={{
                    main: { width: "80%" }
                }}
                layerProps={{
                    onLayerDidMount: this.props.store.layerMounmted
                }}
            >
            <div className={contentStyles.header}>
              <span>
                  {this.props.store.videoTitle}
              </span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close"
                onClick={this.props.store.hideVideo}
              />
            </div>
            <div className={contentStyles.body}>
                {/* <iframe src={this.props.store.videoLink} style={{width: "100%", height: "100%", border: 0 }} allow="autoplay; fullscreen"></iframe> */}
                <video ref={this.props.store.videoRef} controls style={{width: "100%", height: "100%", border: 0 }} ></video>
            </div>
          </Modal>
        );
    }

    render() {
        
        return (
            <div className="page-form">

                { this._renderCommands() }

                <div className="page-content">
                    <Text variant="xLargePlus" >
                        {this.props.store.product.name + " - Videos"}
                    </Text>

                    {
                        this.props.store.product.videos.map((v, i) => this._renderVideo(v, i))
                    }

                </div>

                { this._renderModalVideo() }
            </div>
        );
    }
}   