import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { observer } from 'mobx-react';
import { Link } from '@fluentui/react/lib/Link';
import { Text } from '@fluentui/react/lib/Text';
import { WebStoreLogin, UILoginFormStore } from '../stores/UILoginFormStore';
import { CenterContainerForm } from './CenterContainerForm';
import { ValidationTextField } from './ValidationTextField';
import { ValidationCheckBox } from './ValidationCheckBox';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ReCaptcha } from 'react-recaptcha-v3';
import { FontSizes } from '@fluentui/react';
import { SharedColors } from '@uifabric/fluent-theme'

interface LoginFormProps {
    store: UILoginFormStore;
}

@observer
export class LoginForm extends React.Component<LoginFormProps, any> {

    constructor(props: LoginFormProps) {
        super(props);
        this.props.store.ref = React.createRef();
    }

    public render() {
        const signInBoxStyle = CenterContainerForm.boxStyle(this.props.store.parentStore);
        const signInCellStyle = CenterContainerForm.cellStyle();      

        const loginData: WebStoreLogin = this.props.store.baseObject;

        return (
            <form>
                <CenterContainerForm>
                    <div style={signInBoxStyle}>
                        <div style={ {paddingTop: 10, textAlign: "center"} }  >
                            <img src="images/logo.png" />
                        </div>                       

                        <div style={ {paddingTop: 10, textAlign: "center"} }  >
                            <Text variant="mediumPlus">
                                Sign into Aellius Store
                            </Text>
                        </div>                       

                        <div style={signInCellStyle}>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                label="User ID"
                                resizable={false}
                                autoFocus={true} // why? because placeholder does not show on focus - changed to label 2020-09-23 17:28:45
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="userId"
                            />
                        </div>
                    
                        <div style={signInCellStyle}>
                            <ValidationTextField 
                                style={{ width: '100%' }}
                                label="Password"
                                resizable={false} 
                                type="password"
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="password"
                                autoComplete="current-password"
                                onKeyPress={(event: any) => { if (event.charCode === 13) { this.props.store.login()} } } 
                            />                            
                        </div>                            
                
                        {/*
                          removed this. keepLoggedIn is set to true always.
                         <div style={signInCellStyle}>
                            <ValidationCheckBox
                                label="Stay Signed In"
                                ariaDescribedBy={ 'descriptionID' }
                                store = {this.props.store}
                                fieldId="keepLoggedIn"
                            />
                        </div>    */}
                        
                        <div style={{...signInCellStyle, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            <Text variant="mediumPlus" >
                                By signing in, I agree to the 
                            </Text>
                            <Link
                                href="https://www.aellius.com/files/legal/Aellius Online Store ToS.pdf"
                                target="_blank"
                            >
                                Terms and Conditions
                            </Link>
                        </div>   

                        <div style={signInCellStyle}>
                            <ReCaptcha  
                                sitekey={this.props.store.recaptchaSiteKey}
                                action="login"
                                verifyCallback={this.props.store.verifyCaptcha}
                                ref={ref => this.props.store.ref = ref}
                            />                        

                            <PrimaryButton
                                id="LoginButton"
                                style={{ height:40, width: '100%', backgroundColor: (this.props.store.parentStore.rootStore.AdminMode === true) ? SharedColors.magenta20 : "" }}
                                iconProps={ { iconName: 'NavigateForward' } }
                                data-automation-id='signin'
                                disabled={false}
                                checked={false}
                                text="Sign In"
                                onClick={(e) => {e.preventDefault();  this.props.store.login()}}
                            />                                            
                        </div>    

                        { (this.props.store.parentStore.rootStore.AdminMode === false) &&
                        <>
                        <div style={signInCellStyle}>
                            <Text variant="mediumPlus">
                                Don't have an account? Signup
                            </Text>
                            <span> </span>
                            <Link onClick={this.props.store.navigateToSignup} styles={{ root: {fontSize: FontSizes.mediumPlus } }} >
                                here
                            </Link>                                
                            <span> </span>
                            <Text variant="mediumPlus">
                                for a free account
                            </Text>
                        </div>

                        <div style={{...signInCellStyle, display: "flex", justifyContent: "center"}}>
                            <Link onClick={this.props.store.navigateToForgotPassword}>
                                Forgot my password
                            </Link>   
                        </div> 
                        </>
                        }
                                           
                    </div>

                    <div style={{...signInCellStyle, height: 52, maxWidth: 320}}>
                        <div style={{display: loginData.isLoading ? "block" : "none", paddingTop: 10 }} >  
                            <Spinner size={ SpinnerSize.large } ariaLive='assertive'
                                styles={ CenterContainerForm.getSpinnerStyles  }
                            />
                         </div>

                        <Text styles={
                            {
                                root: {
                                    color: this.props.store.parentStore.theme.semanticColors.errorText
                                }
                            }}>
                            { loginData.loginMessage }
                        </Text>
                    </div> 
                </CenterContainerForm>
            </form>

        );
    }
}
