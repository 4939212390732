import * as React from 'react';
import { UIStore } from '../stores/UIStore';
import { ISpinnerStyleProps, ISpinnerStyles } from '@fluentui/react/lib/Spinner';
import { FontSizes } from '@uifabric/fluent-theme';

export class CenterContainerForm extends React.Component<any, any> {

    static boxStyle(uiStore: UIStore) :  React.CSSProperties {
        const signInBoxStyle: React.CSSProperties = {
            maxWidth: 320,
            minWidth:295,
            padding: 10,
            border: "2px solid " + uiStore.linkColor
        };
        
        return signInBoxStyle;
    }

    static cellStyle() :  React.CSSProperties {
        const signInCellStyle: React.CSSProperties = {
            padding: 10,
        };  

        return signInCellStyle;
    }

    static getSpinnerStyles = (props: ISpinnerStyleProps): ISpinnerStyles => {
        const { theme } = props;
        const { palette } = theme;

        return {
          label: [
            {
              fontSize: FontSizes.size16
            },
            {
              color: palette.themePrimary,
              marginTop: 25,
              textAlign: 'center',

            }
          ],
        };
    };       

    render() {
        return(
            <div style={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "auto" }}>
                {this.props.children}
            </div>
        )
    }
}