import * as React from 'react';
import { AppStore } from "../stores/AppStore";
import { WebStoreLogin, UILoginFormStore } from '../stores/UILoginFormStore';
import { LoginForm } from './LoginForm';
import { observer } from 'mobx-react';
import { InitForm } from '../stores/UIStore';
import { ForgotPassword, UIForgotPasswordFormStore } from '../stores/UIForgotPasswordFormStore';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { UITransition } from './UITransition';
import { ConfirmDialog } from './ConfirmDialog';
import { UserMenu } from './UserMenu';
import moment from 'moment';
import { Sidebar } from './Sidebar';
import { PageContainer } from './PageContainer';
import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import { WebStoreSignup, UISignupFormStore } from '../stores/UISignupFormStore';
import {SignupForm} from './SignupForm';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';
import { ResetPassword, UIResetPasswordFormStore } from '../stores/UIResetPasswordFormStore';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ConfirmEmailForm } from './ConfirmEmailForm';
import { UIConfirmEmailFormStore } from '../stores/UIConfirmEmailFormStore';

export interface AppProps {
    store: AppStore;
}

@observer
export class App extends React.Component<AppProps, any> {

    private _renderSignInForm() {
        let loginstore: WebStoreLogin = {
            userId: "" ,
            password: "",
            isError: false,
            isLoading: false,
            keepLoggedIn: false,
            loginMessage: "",
            captcha: ""
        };

        // default test values if present
        var testdata: any = this.props.store.ClientConfig.lazyData.webStore;
        if (!isNullOrUndefined(testdata)) {
            loginstore.userId = testdata.userId;
            loginstore.password = this.props.store.AdminMode ? testdata.passwordAdmin : testdata.password;
        }

        let store: UILoginFormStore = new UILoginFormStore(this.props.store.uiStore, loginstore);

        return (
            <div>
                <LoginForm store={store} />
            </div>
        );      
    }
  
    private _renderSignupForm() {
        let signupData: WebStoreSignup = {
            userId: "",
            password1: "",
            password2: "",
            firstName: "",
            lastName: "",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            acceptedTerms: false,
            captcha: ""
        };

        var testdata: any = this.props.store.ClientConfig.lazyData.webStore;
        if (!isNullOrUndefined(testdata)) {
            var sd = testdata.signUpData;
            if (!isNullOrUndefined(signupData)) {
                signupData = {...sd};
            }
        }

        let store: UISignupFormStore = new UISignupFormStore(this.props.store.uiStore, signupData);
        //store.resetCaptcha();

        return (
            <div>
                <SignupForm store={store} />
            </div>
        );      
    }

    private _renderConfirmEmailForm() {
        const store: UIConfirmEmailFormStore = new UIConfirmEmailFormStore(this.props.store);

        return (
            <ConfirmEmailForm store={store} />
        );
    }

    private _renderForgotPasswordForm() {
        let data: ForgotPassword = {
            userId: "" ,
            isError: false,
            isLoading: false,
            captcha: "",
            message: ""                        
        };
        
        let store: UIForgotPasswordFormStore = new UIForgotPasswordFormStore(this.props.store.uiStore, data);

        return (
            <div>
                <ForgotPasswordForm store={store} />
            </div>
        );      
    }

    private _renderResetPasswordForm() {
        let data: ResetPassword = {
            userId: "",
            captcha: "",
            message: "",
            password1: "",
            password2: "",
            isError: false,
            isLoading: false
        };
        
        let store: UIResetPasswordFormStore = new UIResetPasswordFormStore(this.props.store.uiStore, data);

        return (
            <div>
                <ResetPasswordForm store={store} />
            </div>
        );      
    }

    private _renderAdminApp() {
        return (
            <>
                <div className="app-sidebar">
                    <Sidebar store={this.props.store.uiStore.sideBarStore} />
                </div>
                <div className="app-page" id="app-page">
                    <div className="app-page-content" id="app-page-content">
                        <PageContainer store={this.props.store} />
                    </div>
                    <div className="app-page-error" id="app-page-error">
                        {
                            this.props.store.uiStore.messageStore.show ?
                            <MessageBar
                                messageBarType={this.props.store.uiStore.messageStore.messageType}
                                onDismiss={() => { this.props.store.uiStore.messageStore.clear() }}
                            >
                                {this.props.store.uiStore.messageStore.message}
                            </MessageBar>
                            : null 
                        }
                    </div>
                </div>                     
            </>
        );
    }
    
    private _renderUserApp() {
        return (
            <>
                <div className="app-sidebar">
                    <Sidebar store={this.props.store.uiStore.sideBarStore} />
                </div>
                <div className="app-page" id="app-page">
                    <div className="app-page-content" id="app-page-content">
                        <PageContainer store={this.props.store} />
                    </div>
                    <div className="app-page-error" id="app-page-error">
                        {
                            this.props.store.uiStore.messageStore.show ?
                            <MessageBar
                                messageBarType={this.props.store.uiStore.messageStore.messageType}
                                onDismiss={() => { this.props.store.uiStore.messageStore.clear() }}
                            >
                                {this.props.store.uiStore.messageStore.message}

                                {/* <b>Blocked MessageBar - single line, with dismiss button and truncated text.</b> Truncation is not available if you use action buttons
    or multiline and should be used sparingly. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi luctus, purus a lobortis
    tristique, odio augue pharetra metus, ac placerat nunc mi nec dui. Vestibulum aliquam et nunc semper scelerisque. Curabitur vitae orci
    nec quam condimentum porttitor et sed lacus. Vivamus ac efficitur leo. Cras faucibus mauris libero, ac placerat erat euismod et. Donec
    pulvinar commodo odio sit amet faucibus. In hac habitasse platea dictumst. Duis eu ante commodo, condimentum nibh pellentesque, laoreet
    enim. Fusce massa lorem, ultrices eu mi a, fermentum suscipit magna. Integer porta purus pulvinar, hendrerit felis eget, condimentum
    mauris.                                 */}
                            </MessageBar>
                            : null 
                        }
                    </div>
                </div>                     
            </>
        );
    }

    private _renderApp() {
        return (
            <>
                <UITransition store={this.props.store.uiStore.uiTransition} />
      
                <div className="app-container">                 

                    <div className="app-header" >

                        <div className="app-header-logo" >
                            <img src="images/logo.png" />
                        </div>

                        <div className="app-header-product" >
                            Aellius Store
                        </div>

                        <div className="app-header-right" >
                            { this.props.store.domainStore.IsLoggedIn ? 
                            <>
                                <div>
                                    <UserMenu store={this.props.store.uiStore.userMenuStore} /> 
                                </div>
                            </>
                            : 
                            null
                            
                            }
                        </div>

                    </div>

                        <div className="app-sidebar-page">
                            {
                                (this.props.store.domainStore.AdminMode === true) &&  
                                this._renderAdminApp()
                            }

                            {
                                (this.props.store.domainStore.AdminMode === false) &&  
                                this._renderUserApp()
                            }                        
                        </div>

                        { this._renderFooter() }
                </div>

                {
                    this.props.store.uiStore.confirmDialogStore.show ?
                        <ConfirmDialog store={this.props.store.uiStore.confirmDialogStore} />
                        : null
                }
            </>
        );    
    }

    private _renderFooter() {
        return (
            <div className="app-footer">
                <div className="cr" >
                    {
                        "© " + moment().format("YYYY") + " Aellius. All rights reserved."
                    }
                </div>
                <div className="ws">
                    <Link
                        href="https://www.aellius.com"
                        target="_blank"
                    >
                        www.aellius.com
                    </Link>

                </div>
                <div className="ws">
                    <Link
                        href="https://www.aellius.com/files/legal/Aellius Online Store ToS.pdf"
                        target="_blank"
                    >
                        Terms and Conditions
                    </Link>
                </div>
                <div className="ws">
                    {
                        "Version: " + this.props.store.initData.buildVersion
                    }
                </div>
            </div>
        );
    }    

    render() {
        try {
            //   return this._renderApp();
            
            if (this.props.store.domainStore.IsLoggedIn === false) {
                switch (this.props.store.uiStore.initForm) {
                    case InitForm.signIn:
                        return this._renderSignInForm();
                        break;

                    case InitForm.signUp:
                        return this._renderSignupForm();
                        break;

                    case InitForm.confirmEmail:
                        return this._renderConfirmEmailForm();
                        break;

                    case InitForm.forgotPassword:
                        return this._renderForgotPasswordForm();
                        break;

                    case InitForm.resetPassword:
                        return this._renderResetPasswordForm();
                        break;
                            
                    default:
                        return (<div>Invalid Form!</div>);
                        break;
                }
            } else if (this.props.store.domainStore.IsLoggedIn === true)  {

            // initialize sidebar/nav here as it checks if user is company admin in user mode   
            this.props.store.uiStore.sideBarStore.initMenus();

                return this._renderApp();
            } else  {
                return (<> </>)
            }

        } catch (error) {
            console.log(error);
            return null;
        }
    }
}