import * as React from "react";
import { observer } from "mobx-react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as Server from "../web-store/ServerClasses"
import { FormHeader } from "./FormHeader";
import { IColumnProps } from "../stores/UISearchFormStore";
import { ICommandBarItemProps, CommandBar, Link, Icon } from "office-ui-fabric-react";
import { UIAdminSignupsFormStore } from "../stores/UIAdminSignupsFormStore";
import moment from "moment";

class AdminSingupsSearchFormProps {
    store: UIAdminSignupsFormStore;
}

@observer
export class AdminSignupsSearchForm extends React.Component<AdminSingupsSearchFormProps, any> {

    componentDidMount () {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIAdminSignupsFormStore) {
        return <AdminSignupsSearchForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [{
            key: "",
            text: "Refresh",
            iconProps: {
                iconName: "Refresh",
            },
            onClick: () => { this.props.store.refresh() }
        }];
        this.props.store.addCloseMenu(items);
        this.props.store.parentStore.setCommandBarItemsKeys(items);
        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    private _getColumns() : IColumn[] {
        const columns: IColumn[] = [
            {
                key: "1",
                name: "Id",
                fieldName: "id",
                minWidth: 60,
                maxWidth: 60,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "number",
                isPadded: true,
            },
            {
                key: "2",
                name: "Approved",
                fieldName: "approved",
                minWidth: 60,
                maxWidth: 60,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                data: "image",
                isPadded: true,
                onRender: (item: Server.Signup) => {
                    return (
                        <div>
                            <Icon 
                                iconName={item.approved === true ? "SkypeCircleCheck" : "UnknownSolid"}
                                styles={{root: {color: item.approved === true ? this.props.store.parentStore.theme.palette.greenLight : this.props.store.parentStore.theme.palette.red } }}
                            />
                        </div>  
                    )
                }              
            },            
            {
                key: "3",
                name: "Email",
                fieldName: "emailId",
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.Signup) => {
                    return (
                        <Link onClick={() => this.props.store.gotoSignup(item)}>
                            {item.emailId}
                        </Link>  
                    )
                }                    
            },                                        
            {
                key: "4",
                name: "Name",
                fieldName: "firstName",
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.Signup) => {
                    return (
                        <div>
                            {item.firstName + " " + item.lastName}
                        </div>  
                    )
                }                 
            },                                        
            {
                key: "5",
                name: "Company",
                fieldName: "companyName",
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true               
            },
            {
                key: "6",
                name: "IP Address",
                fieldName: "ipAddress",
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true                                             
            },
            {
                key: "7",
                name: "Signup Date",
                fieldName: "signupDAte",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "date",
                isPadded: true,
                onRender: (item: Server.Signup) => {
                    return (
                        <div>
                            {moment(item.signupDate).format("YYYY-MM-DD")}
                        </div>  
                    )
                }                                                                  
            },
            {
                key: "8",
                name: "Approval Date",
                fieldName: "approvalDate",
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,                                             
                onRender: (item: Server.Signup) => {
                    return (
                        <div>
                            {moment.isDate(item.approvalDate) ? moment(item.approvalDate).format("YYYY-MM-DD") : ""}
                        </div>  
                    )
                }               
            }                                         
        ];

        // this.props.store.gotoAssets(item)
        // sync these overriden properties from props
        columns.forEach((c: IColumn, i: number) => {
            const currColumn: IColumnProps = this.props.store.displayedColumns.filter((currCol: IColumnProps, idx: number) => {
                return c.key === currCol.key;
              })[0];
            
            c.isSorted = currColumn.isSorted;
            c.isSortedDescending = currColumn.isSortedDescending;
            // not setting onColumnClick as not all cols are clickable
        });

        return columns;
    }

    detailList() {
        return (
            <div style={{ overflowX: "auto", marginTop: 10}}>
                <TextField
                    placeholder={"Filter by name"}
                    onChange={ this.props.store.filter }
                    value= {this.props.store.filterValue}
                />
                
                <div style={{height: 600, overflowY: "auto"}} data-is-scrollable={true}>
                    <DetailsList
                        items={ this.props.store.displayedRecords }
                        columns={ this._getColumns() }
                        compact={ false }
                        selectionMode={ SelectionMode.none }
                        layoutMode={ DetailsListLayoutMode.justified }
                        isHeaderVisible={ true }
                        constrainMode={ ConstrainMode.unconstrained }
                    />
                </div>
            </div>
        );          
    }

    render() {
        
        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content">
                    <FormHeader header={"Signups"} uiStore={this.props.store.parentStore} />

                    { this.detailList() }
                </div>
            </div>
        );
    }
}   