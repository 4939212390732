import { observable, action } from "mobx";
import { UIStore } from "./UIStore";
import { MessageBarType } from "office-ui-fabric-react";
import { String} from 'typescript-string-operations';
import { BaseResponse } from "../web-store/ServerClasses";
import { isNullOrUndefined } from "../web-store/WebStoreUtil";

export class UIMessageStore {
    private uiStore: UIStore;
    @observable show: boolean
    @observable message: string;
    @observable messageType: MessageBarType;

    constructor(uiStore: UIStore) {
        this.uiStore = uiStore;
        this.show = false;
        this.messageType = MessageBarType.info;
    }

    @action
    clear() {
        this.message = "";
        this.show = false;
    }

    @action
    showMessage = async (isError: boolean, format: string, ...args: any[]) => {
        this.message = (args && args.length > 0) ? String.Format(format, ...args): format;
        this.messageType = (isError === true ? MessageBarType.error : MessageBarType.error);
        this.show = true;
    }

    @action
    showMessageFromResponse = async (response: BaseResponse) => {
        if (isNullOrUndefined(response)) {
            this.showErrorMessage("No response was returned.");
        } else {
            if (response.statusCode == 401) {
                this.showErrorMessage(response.message + ". Your session may have expired. Refresh the page to log back in." );
            } else if (response.statusCode !== 200) {
                this.showErrorMessage(response.message);
            }
            // else: no info messages for success responses
        }
    }


    @action
    showInfoMessage = async (format: string, ...args: any[]) => {
        this.message = (args && args.length > 0) ? String.Format(format, ...args): format;
        this.messageType = MessageBarType.info;
        this.show = true;
    }

    @action
    showErrorMessage = async (format: string, ...args: any[]) => {
        this.message = (args && args.length > 0) ? String.Format(format, ...args): format;
        this.messageType = MessageBarType.error;
        this.show = true;
    } 

    @action
    showErrorFromException = async (error: any) => {
        console.error(error);
        let message: string = "";
    
        if (error instanceof Error) {
          message = ((error as Error).message);
        }
        else {
          message = error.toString();
        }

        this.message = message;
        this.messageType = MessageBarType.error;
        this.show = true;
    }     
}