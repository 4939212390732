import {action, observable} from 'mobx';
import { String } from 'typescript-string-operations';
import {UIStore, InitForm} from './UIStore'
import {UIEditFormStore} from './UIEditFormStore'
import * as Server from '../web-store/ServerClasses'
import { RequiredValidator, EmailValidator } from '../web-store/Validators';
import { ServerInsight } from '../web-store/ServerInsight';

export interface ResetPassword {
    message: string;   
    isError: boolean;   
    userId: string;
    password1: string;
    password2: string;
    captcha: string;
    isLoading: boolean
}

export class UIResetPasswordFormStore extends UIEditFormStore<ResetPassword> {
    ref: any;
    @observable resetSuccess: boolean;

    constructor(parent: UIStore, resetData: ResetPassword) {
        super(parent)
        this.setBaseObject(resetData);
        this.createValidationFields();
        this.resetSuccess = false;
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "userId",
                propName: "userId",
                errorMessage: "",
                validators: [new EmailValidator()]
            },
            {
                fieldId: "password1",
                propName: "password1",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "password2",
                propName: "password2",
                errorMessage: "",
                validators: [new RequiredValidator()]
            }            
        ]
    }

    @action
    resetCaptcha = (): void => {
        try {
            (this.baseObject as ResetPassword).captcha = "";
            if (this.ref != null) this.ref.execute();
        } catch (error) {
            console.error(error);
        }
    }

    @action
    verifyCaptcha = (response: string): void => {
        (this.baseObject as ResetPassword).captcha = response;
    }

    @action
    resetPassword = async () : Promise<void> => {
        let data: ResetPassword = (this.baseObject as ResetPassword);
        data.message = "";
        data.isError = false;
        this.resetSuccess = false;
        let serverApi = this.parentStore.serverApi;

        try {
            if (!this.validateForm()) 
                return;
            
            if (data.password1 !== data.password2) {
                data.isError = true;
                data.message = "Passwords do not match.";
                return;
            }

            if (this.parentStore.rootStore.ClientConfig.disableRecaptcha === false) {
                if (String.IsNullOrWhiteSpace(data.captcha)) {
                    data.isError = true;
                    data.message = "Unable to verify that this is not a bot.";
                    return;
                }
            }   

            data.isLoading = true;
            data.isError = false;

            let ai: ServerInsight = new ServerInsight("appResetPwd");
            ai.data = {
                "userId": data.userId,
            }
            ai.update(serverApi);

            let request: Server.ResetPasswordRequest = {
                resetId: this.parentStore.rootStore.passwordResetId,
                brc: (this.parentStore.rootStore.ClientConfig.disableRecaptcha === true) ? "tuktuk" : "none",
                captcha: data.captcha,
                emailId: data.userId,
                isAdmin: false,
                password: data.password1
            }

            let response: Server.GenericResponse = await serverApi.postData<Server.ResetPasswordRequest, Server.GenericResponse>("resetpassword", request, "POST");
            if (response.statusCode !== 200) {
                if (String.IsNullOrWhiteSpace(response.message)) {
                    data.message = "Your request could not processed at this time.";
                } else {
                    data.message = response.message;
                }
                data.isError = true;
                return;
            }

            data.message = "Your password has been reset.";
            data.userId = "";
            data.password1 = "";
            data.password2 = "";
            this.resetSuccess = true;
        } catch (error) {
            data.message = (error as Error).message;            
        } finally {
            data.isLoading = false;
            this.resetCaptcha();
        }
    }

    @action
    navigateToLogin = (): void => {
        // document.location.href = window.location.href.split('?')[0]
        // https://stackoverflow.com/questions/22753052/remove-url-parameters-without-refreshing-page
        window.history.replaceState({}, document.title, "/");
        this.parentStore.initForm = InitForm.signIn;
    }
}
