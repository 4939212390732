import {observable, action, toJS, computed} from 'mobx';
import {ValidationField} from '../web-store/ValidationField';
import { String } from 'typescript-string-operations';
import {IValidationFieldProps} from '../web-store/ValidationFieldProps'
import { isUndefined } from 'util';
import { UIStore } from './UIStore';
import { ICommandBarItemProps } from '@fluentui/react';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';
import { DataType } from '../web-store/Validators';
import moment from 'moment';

export class UIFormStore {
    parentStore: UIStore;
    @observable baseObject: any;
    @observable validationFields: ValidationField[];
    private readonly _key: number;
    private static _nextKey: number = 0;

    constructor(parent: UIStore) {
        this.parentStore = parent;
        this.validationFields = [];
        this._key = UIFormStore.nextKey();
    }

    static nextKey() : number {
        return ++this._nextKey;
    }

    public get Key(): number {
        return this._key;
    }

    protected setBaseObject(entity: any) {
        this.baseObject = {...entity};
    }

    @action
    setFormValue = (field: ValidationField, value: (string | boolean)): void => {
        if (!field) return;

        if (!this.baseObject.hasOwnProperty(field.propName)) {
            console.error("Invalid propertry " + field.propName);
            return;
        }
        this.baseObject[field.propName] = value;
    }

    getFormValue = (field: ValidationField): (string | boolean) => {
        if (!this.baseObject.hasOwnProperty(field.propName)) {
            console.error("Invalid propertry " + field.propName);
            return "";
        }

        if (!isNullOrUndefined(field.dataType)) {
            if (field.dataType === DataType.Date) {
                if (moment.isDate(this.baseObject[field.propName])) {
                    return moment(this.baseObject[field.propName]).format("YYYY-MM-DD");
                }
            }
        }

        return this.baseObject[field.propName];
    }

    getValidationField(props: IValidationFieldProps) : ValidationField {
        return this.validationFields.find(x => x.fieldId === props.fieldId);
    }

    hasValidators(field: ValidationField): boolean {
        if (isNullOrUndefined(field.validators)) return false;
        return (field.validators.length > 0);
    }

    getValidationFieldErrorMessage = (field: ValidationField, value: string): string => {
        field.errorMessage = "";
        if (!this.hasValidators(field)) {
            this.setFormValue(field, value);
            field.errorMessage = "";
            return "";
        }
        
        let messages: string[] = [];
        let valid: boolean = true;
        
        // 08/08/2018: return on first error. too many errors confuse the user
        for (let i = 0; i < field.validators.length; i++) {
            const vf = field.validators[i];
            if (!vf.validate(value)) {
                messages.push(vf.validationMessage);
                valid = false;
                break;
            }
        }

        this.setFormValue(field,  valid ? field.validators[0].validatedValue : value);

        // try {
        // } catch (error) {
        //     console.error(field.validators.length);
        //     throw error;
        // }


        return messages.length > 0 ? messages.join(" ") : "";
    }

    validateField = (field: ValidationField, value: string): void => {
        field.errorMessage = this.getValidationFieldErrorMessage(field, value);
    }

    protected validateForm = (): boolean => {
        let valid: boolean = true;

        for (let i = 0; i < this.validationFields.length; i++) {
            const vf = this.validationFields[i];
            var value: any = this.getFormValue(vf);
            if (typeof(value) === "string" || isUndefined(value)) {
                var message: string = this.getValidationFieldErrorMessage(vf, (value as string));
                if (!String.IsNullOrWhiteSpace(message)) {
                    valid = valid && false;
                    vf.errorMessage = message;
                } else {
                    vf.errorMessage = "";
                }
            }
        }

        return valid;
    }

    @action 
    cancel = (): void => {
        let cancelAction = {
            dontClose: false
        }
        this.beforeCancel(cancelAction);
        if (cancelAction.dontClose === true) return;

        this.parentStore.messageStore.clear();
        this.parentStore.removeForm();
    }

    protected beforeCancel = (cancelAction: {dontClose: boolean}): void => {
        cancelAction.dontClose = false;
    }

    addCloseMenu = (items: ICommandBarItemProps[]) => {
        items.push({
            key: "",
            text: "Close",
            iconProps: {
                iconName: "Cancel",
            },
            onClick: this.cancel
        });  
    }

    getTypedObject<T>() {
        return this.baseObject as T;
    }
}