import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { observer } from 'mobx-react';
import { Link } from '@fluentui/react/lib/Link';
import { Text } from '@fluentui/react/lib/Text';
import { CenterContainerForm } from './CenterContainerForm';
import { ValidationTextField } from './ValidationTextField';
import { ValidationCheckBox } from './ValidationCheckBox';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { UISignupFormStore, WebStoreSignup } from '../stores/UISignupFormStore';
import { Stack, IComboBoxOption, ComboBox } from '@fluentui/react';
import { sign } from 'crypto';
import { ValidationPasswordField } from './ValidationPasswordField';
import { ReCaptcha } from 'react-recaptcha-v3';

interface SignupFormProps {
    store: UISignupFormStore;
}

@observer
export class SignupForm extends React.Component<SignupFormProps, any> {

    constructor(props: SignupFormProps) {
        super(props);
        this.props.store.ref = React.createRef();
    }

    public render() {
        // const signInBoxStyle = CenterContainerForm.boxStyle(this.props.store.parentStore);
        // const signInCellStyle = CenterContainerForm.cellStyle();      
        const signupData: WebStoreSignup = this.props.store.baseObject;
        const signInBoxStyle = {...CenterContainerForm.boxStyle(this.props.store.parentStore)}
        signInBoxStyle.maxWidth = 600;
        signInBoxStyle.minWidth = 600;

        // style={{maxWidth: 600, minWidth: 600, padding: 10, border: "2px solid " + this.props.store.parentStore.linkColor }}
        // && c.name.startsWith("U")

        const countryOptions: IComboBoxOption[] = this.props.store.parentStore.rootStore.initData.countries
            .filter(c => c.enabled === true).map((c) => {
            return {
                key: c.name,
                text: c.name
            };
        });

        if (this.props.store.signupReceived) {
            signInBoxStyle.display = "none";
        }

        return (
            <CenterContainerForm >
                <form style={signInBoxStyle}>
                    <Stack tokens={{childrenGap: 10}}>
                        <Stack.Item align="center" >
                            <img src="images/logo.png" />
                        </Stack.Item>

                        <Stack.Item align="center" >
                            <Text variant="mediumPlus">
                                Aellius Store - Create an Account
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: "100%" }}
                                placeholder="Corporate Email"
                                resizable={false}
                                autoFocus={false} // why? becuase placeholder does not show on focus
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="userId"
                            />
                            <Text>
                                Note: We do not accept non-corporate email account (Gmail, Yahoo, Outlook etc).
                            </Text>
                        </Stack.Item>

                        <Stack horizontal horizontalAlign="space-evenly" tokens={{childrenGap: 10}}>
                            <Stack.Item styles={{root: { width: "100%"} }} >
                                <ValidationPasswordField
                                    style={{ width: '100%' }}
                                    placeholder="Password"
                                    resizable={false}
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    autoComplete="new-password"
                                    fieldId="password1"
                                />                                    
                            </Stack.Item>

                            <Stack.Item styles={{root: { width: "100%"} }} >
                                <ValidationPasswordField
                                    style={{ width: '100%' }}
                                    placeholder="Retype Password"
                                    resizable={false}
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    autoComplete="new-password"
                                    fieldId="password2"
                                />                                    
                            </Stack.Item>
                        </Stack>
                        
                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                placeholder="Organization"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="companyName"
                            />                                    
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                placeholder="First Name"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="firstName"
                            />                                    
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                placeholder="Last Name"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="lastName"
                            />                                    
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                placeholder="Address Line1"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="addressLine1"
                            />                                    
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                placeholder="Address Line2"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="addressLine2"
                            />                                    
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField
                                style={{ width: '100%' }}
                                placeholder="Address Line3"
                                resizable={false}
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                store = {this.props.store}
                                fieldId="addressLine3"
                            />                                    
                        </Stack.Item>
                        
                        <Stack horizontal horizontalAlign="space-evenly" tokens={{childrenGap: 10}}>
                            <Stack.Item styles={{root: { width: "33%"} }} >
                                <ValidationTextField
                                    style={{ width: '100%' }}
                                    placeholder="City"
                                    resizable={false}
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    fieldId="city"
                                />                                    
                            </Stack.Item>

                            <Stack.Item styles={{root: { width: "33%"} }} >
                                <ValidationTextField
                                    style={{ width: '100%' }}
                                    placeholder="State"
                                    resizable={false}
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    fieldId="state"
                                />                                    
                            </Stack.Item>

                            <Stack.Item styles={{root: { width: "33%"} }} >
                                <ValidationTextField
                                    style={{ width: '100%' }}
                                    placeholder="Zip"
                                    resizable={false}
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    fieldId="zip"
                                />                                    
                            </Stack.Item>
                        </Stack>

                        <Stack.Item>
                            <ComboBox
                                allowFreeform={false}
                                autoComplete={"on"}
                                options={countryOptions}
                                selectedKey={signupData.country}
                                onChange={this.props.store.changeCountry}
                            />
                        </Stack.Item>

                        <Stack horizontal tokens={{childrenGap: 10}} styles={{ root:{padding: 10 }}} >
                            <Stack.Item>
                                <ValidationCheckBox
                                    label="I accept the terms and conditions"
                                    store = {this.props.store}
                                    fieldId="acceptedTerms"
                                />                                 
                            </Stack.Item>
                            <Stack.Item>
                                <Link
                                    href="https://www.aellius.com/files/legal/Aellius Online Store ToS.pdf"
                                    target="_blank"
                                >
                                    Terms and Conditions
                                </Link>
                            </Stack.Item>
                        </Stack>

                        <Stack.Item align="center" styles={{root:{ width: "100%"}}}>
                            <ReCaptcha  
                                sitekey={this.props.store.parentStore.rootStore.ClientConfig.recaptchaSiteKey}
                                action="signup"
                                verifyCallback={this.props.store.verifyCaptcha}
                                ref={ref => this.props.store.ref = ref}
                            />                        

                            <Stack horizontal horizontalAlign="center" tokens={{childrenGap: 10}}>
                                <PrimaryButton 
                                    style={{ height:40, width: "40%" }}
                                    text="Register"
                                    onClick={(e) => {e.preventDefault(); this.props.store.signUp()}}
                                    disabled={this.props.store.registerDisabled}
                                />
                                <PrimaryButton 
                                    style={{ height:40, width: "40%" }}
                                    text="Clear"
                                    onClick={(e) => {this.props.store.clear()}}
                                    disabled={this.props.store.registerDisabled}
                                />                                
                            </Stack>
                        </Stack.Item>

           
                    </Stack>
                </form>

                <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={{childrenGap: 10}} styles={{root: {marginTop: 20 } }} >
                    <div style={{display: this.props.store.isLoading ? "block" : "none", paddingTop: 10 }} >  
                        <Spinner size={ SpinnerSize.large } ariaLive='assertive'
                            styles={ CenterContainerForm.getSpinnerStyles}
                        />
                    </div>

                    {this.props.store.rejectError ? 
                    
                        <Text variant="mediumPlus"
                            styles={
                            {
                                root: {
                                    color: this.props.store.parentStore.theme.semanticColors.errorText
                                }
                            }}>
                            Your request could not be processed at this time. The most likely reasons are: 
                            You used a non-corporate email account, your passwords don't meet complexity 
                            requirements.
                        </Text>   
                        :

                        <Text variant="mediumPlus"
                            styles={
                            {
                                root: {
                                    color: this.props.store.isError ? this.props.store.parentStore.theme.semanticColors.errorText : "inherit"
                                }
                            }}>
                            { this.props.store.signupMessage }
                        </Text>                    
                
                    }

                </Stack>
            
                <Stack tokens={{childrenGap: 10}}>
                    <Stack.Item align="center" >
                        <Link 
                            onClick={this.props.store.navigateToSignin}
                        >
                            Back to Sign In
                        </Link>                             
                    </Stack.Item>
                </Stack>
                
            </CenterContainerForm>
        );
    }
}
