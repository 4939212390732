import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { observer } from 'mobx-react';
import { FormHeader } from './FormHeader';
import { ICommandBarItemProps, CommandBar, Stack, PrimaryButton, Text } from '@fluentui/react';
import { ChangePasswordData } from '../stores/UIChangePasswordStore';
import { ValidationPasswordField } from './ValidationPasswordField';
import { UIContactFormStore, ContactFormData } from '../stores/UIContactFormStore';
import { ValidationTextField } from './ValidationTextField';

class ContactFormProps {
    store: UIContactFormStore;
}

@observer
export class ContactForm extends React.Component<ContactFormProps, any> {

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIContactFormStore) {
        return <ContactForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [];
        this.props.store.addCloseMenu(items);

        this.props.store.parentStore.setCommandBarItemsKeys(items);

        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    render() {
        const contactData = this.props.store.baseObject as ContactFormData;
        
        return (
            <div className="page-form">

                { this._renderCommands() }

                <div className="page-content">
                    <FormHeader header="Contact Us" uiStore={this.props.store.parentStore} />

                    <Stack tokens={{childrenGap: 10, padding: 10 }}>
                        <Stack.Item>
                            <ValidationTextField 
                                label="Subject"
                                store={this.props.store}
                                fieldId="subject"
                                autoFocus
                            />
                        </Stack.Item>

                        <Stack.Item styles={{root: { height: 300 }}}>
                            <ValidationTextField 
                                label="Message"
                                store={this.props.store}
                                fieldId="message"
                                multiline={true}
                                styles={{field: {
                                    minHeight: 250
                                }}}
                            />
                        </Stack.Item>                

                        <Stack.Item>
                            <PrimaryButton 
                                text="Send"
                                iconProps={
                                    {
                                        iconName: "Mail"
                                    }
                                }
                                onClick={this.props.store.sendMessage}
                            />                        
                        </Stack.Item>      

                        <Stack.Item>
                            <Text styles={{root: {color: this.props.store.parentStore.linkColor } }} >
                                {this.props.store.sentMessage}
                            </Text>
                        </Stack.Item>                                             
                    </Stack>
                </div>
            </div>
        );
    }
}
