import {UIStore} from './UIStore'
import { SearchFormStore, IColumnProps } from './UISearchFormStore';
import { Selection } from "office-ui-fabric-react/lib/DetailsList";
import { SubscriptionDTO, CreateAssetsResponse, CreateAssetsRequest, AssetType, OrderDTO } from '../web-store/ServerClasses';
import { UIAssetsFormStore } from './UIAssetsFormStore';
import { OrdersSearchForm } from '../components/OrdersSearchForm';

export class UIOrdersFormStore extends SearchFormStore<OrderDTO> {
    selection: Selection;
    readonly isTrial: boolean;

    constructor(parent: UIStore, isTrial: boolean) {
        super(parent, "name");
        this.setBaseObject({ notUsed: 1});
        this.isTrial = isTrial;
        this.selection = new Selection();
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("orders", (records, response) => {                 
                if (records) { 
                    this.parentStore.addForm(OrdersSearchForm.create(this));
                }
            }, ["istrial=" + (this.isTrial === true ? "1": "0")]);
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();            
        }
    }
 
    setColumns(): IColumnProps[] {
        const columns: IColumnProps[] = [
            {
                key: "1",
                name: "Product",
                fieldName: "orderTitle",
                isSortedDescending: false,
                isSorted: true
            },
            {
                key: "2",
                name: "Date",
                fieldName: "orderDate",
                isSortedDescending: false,
                isSorted: true
            },            
            {
                key: "3",
                name: "Amount",
                fieldName: "paymentTotal",
                isSortedDescending: false,
                isSorted: false
            },
            {
                key: "4",
                name: "Invoice",
                fieldName: "-",
                isSortedDescending: false,
                isSorted: false
            }                        
        ];

        if (this.parentStore.rootStore.domainStore.AdminMode === true) {
            columns.push({
                key: "5",
                name: "Organization",
                fieldName: "organization",
                isSortedDescending: false,
                isSorted: false
            })
        }

        return columns;
    }

    protected getFilterRecordValue(r: OrderDTO): string {
        return r.orderTitle;
    }

    refresh = async () => {
        await this.getRecordsFromApi("orders", null);
    }

    gotoAssets = async(trial: SubscriptionDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            
            let request: CreateAssetsRequest = {
                assetType: AssetType.Trial,
                publicId: trial.publicId,
                id: 0
            };

            let response: CreateAssetsResponse = await this.parentStore.serverApi.postData("assets", request, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }
            
            new UIAssetsFormStore(this.parentStore, {
                product: trial.product,
                assetResponse: response
            }).showForm();
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }
}