import {observable, action} from 'mobx';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';

export enum DialogMode {
    OK = 0,
    OKCancel = 1,
    YesNo = 2,
    YesNoCancel = 3
}

export enum DialogResult {
    Cancel = 0,
    Yes = 1, // same as OK
    No = 2, 
}

export class UIConfirmDialogStore {
    @observable title: string;
    @observable message: string;
    @observable show: boolean;
    @observable dialogMode: DialogMode;
    context: any;
    resoureText: string;
    callback : (context: any, result: DialogResult) => void;

    constructor() {
        this.show = false;
        this.dialogMode = DialogMode.OKCancel;
    }

    @action
    showDialog = (title?: string, message?: string) => {
        // caller can set title/message directly. if set directly
        // those values will be used
        // if overriden in this call, those values will be used
        // else use resource manager if set

        if (!isNullOrUndefined(title)) {
            this.title = title;
        } 

        if (!isNullOrUndefined(message)) {
            this.message = message;
        }

        this.show = true;
    }
    
    @action
    dismissDialog = (result: DialogResult) => {
        this.show = false;
        if (!isNullOrUndefined(this.callback)) this.callback(this.context, result);
        this.callback = null;
    }

    @action
    clearCallBack = () => {
        this.callback = null;
    }
}
