import * as React from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { observer } from "mobx-react";
import { IconButton, FontSizes, Stack, Icon, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { CenterContainerForm } from './CenterContainerForm';
import { UIConfirmEmailFormStore } from '../stores/UIConfirmEmailFormStore';

export interface ConfirmEmailFormProps {
    store: UIConfirmEmailFormStore;
}

@observer
export class ConfirmEmailForm extends React.Component<ConfirmEmailFormProps, any> {

    componentDidMount(){
        this.props.store.confirm();
    }
   
    render() {
        return (
            <CenterContainerForm>
                <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center">
                        {this.props.store.confirmed === false && this.props.store.error === false &&    
                            <Spinner
                                size={SpinnerSize.medium}
                            />
                        }

                        {this.props.store.confirmed === true && 
                            <Icon iconName="SkypeCircleCheck" />                        
                        }

                        {this.props.store.error === true && 
                            <Icon iconName="Error" styles={{root: {color: this.props.store.appStore.uiStore.theme.semanticColors.errorBackground } }}  />                        
                        }

                        <Text variant="large">
                            { this.props.store.confirmMessage }
                        </Text>
                </Stack>
            </CenterContainerForm>
        )
    }
}
