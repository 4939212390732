import {UIStore} from './UIStore'
import { SearchFormStore, IColumnProps } from './UISearchFormStore';
import { Selection } from "office-ui-fabric-react/lib/DetailsList";
import { SubscriptionDTO, CreateAssetsResponse, CreateAssetsRequest, AssetType, ProductDTO, GetEntitiesResponse, AgreementAcceptance, Asset } from '../web-store/ServerClasses';
import { SubscriptionsSearchForm } from '../components/SubscriptionsSearchForm';
import { UIAssetsFormStore } from './UIAssetsFormStore';
import moment from 'moment';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';
import { EulaFields, UIPurchaseWizardStore } from './UIPurchaseWizardStore';

export class UISubscriptionsFormStore extends SearchFormStore<SubscriptionDTO> {
    selection: Selection;
    readonly isTrial: boolean;

    constructor(parent: UIStore, isTrial: boolean) {
        super(parent, "name");
        this.setBaseObject({ notUsed: 1});
        this.isTrial = isTrial;
        this.selection = new Selection();
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("subscriptions", (records, response) => {                 
                if (records) { 
                    this.parentStore.addForm(SubscriptionsSearchForm.create(this));
                }
            }, ["istrial=" + (this.isTrial === true ? "1": "0")]);
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();            
        }
    }
 
    setColumns(): IColumnProps[] {
        const columns: IColumnProps[] = [
            {
                key: "1",
                name: "Product",
                fieldName: "name",
                isSortedDescending: false,
                isSorted: true
            },
            {
                key: "2",
                name: "Product",
                fieldName: "name",
                isSortedDescending: false,
                isSorted: true
            },            
            {
                key: "3",
                name: "Ends",
                fieldName: "endDate",
                isSortedDescending: false,
                isSorted: false
            },
            {
                key: "4",
                name: "Assets",
                fieldName: "-",
                isSortedDescending: false,
                isSorted: false
            },
            {
                key: "5",
                name: "Renew",
                fieldName: "-",
                isSortedDescending: false,
                isSorted: false
            }         
        ];

        if (this.parentStore.rootStore.domainStore.AdminMode === true) {
            columns.push({
                key: "6",
                name: "Organization",
                fieldName: "organization",
                isSortedDescending: false,
                isSorted: false
            })
        }

        return columns;
    }

    protected getFilterRecordValue(r: SubscriptionDTO): string {
        return r.product.name;
    }
  

    refresh = async () => {
        try {
            await this.getRecordsFromApi("subscriptions", null, ["istrial=" + (this.isTrial === true ? "1": "0")]);
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        }
    }

    gotoAssets = async(subscription: SubscriptionDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            
            let request: CreateAssetsRequest = {
                assetType: AssetType.Trial,
                publicId: subscription.publicId,
                id: 0
            };

            let response: CreateAssetsResponse = await this.parentStore.serverApi.postData("assets", request, "POST");
            if (response.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(response);
                return;
            }
            
            new UIAssetsFormStore(this.parentStore, {
                product: subscription.product,
                assetResponse: response
            }).showForm();
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }

    isRenewableNow(subscription: SubscriptionDTO): boolean {
        let renewable: boolean = moment(subscription.renewableDate).isSameOrBefore(moment.utc(), "days");        
        return renewable;
    }

    gotoRenew = async(subscription: SubscriptionDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            if (this.isRenewableNow(subscription) !== true) {
                this.parentStore.messageStore.showErrorMessage("The selected subscription is not eligible for renewal at this time.");
                return;
            }

            let productsResponse: GetEntitiesResponse<ProductDTO> = await this.parentStore.serverApi.getEntities("products", ["id=" + subscription.productId.toString()]);
            if (productsResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(productsResponse);
                return;
            } 
            if (productsResponse.entities.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("The selected product is not acitive.");
                return;
            }

            let product: ProductDTO = productsResponse.entities[0];
    
            // check if eula has been signed for this product/company/trial - this is for offline acceptance
            let eulaAccepted: boolean = false;
            let eulaAcceptance: AgreementAcceptance = null;
            // let eulaAcceptancesResponse: GetEntitiesResponse<EulaAcceptance> = await this.parentStore.serverApi.getEntities<EulaAcceptance>("eulaacceptances", 
            //     ["trial=0",
            //      "productid=" + product.id.toString()
            //      // company is implicit for user auth
            //     ]);
            // if (eulaAcceptancesResponse.statusCode !== 200) {
            //     this.parentStore.messageStore.showMessageFromResponse(eulaAcceptancesResponse);
            //     return;
            // }
            // if (eulaAcceptancesResponse.entities.length > 0) {
            //     eulaAcceptance = eulaAcceptancesResponse.entities[0];
            //     if (isNullOrUndefined(eulaAcceptance.expiryDate)) {
            //         eulaAccepted = true;
            //     } else {
            //         eulaAccepted = moment(eulaAcceptancesResponse.entities[0].expiryDate).isAfter(moment.utc(), "days");
            //     }
            // }              

            // create asset for eula
            let eulasResponse: CreateAssetsResponse =  await this.parentStore.serverApi.postData<CreateAssetsRequest, CreateAssetsResponse>("assets", {
                assetType: AssetType.Agreement,
                id: product.eulaId,
                publicId: "",
                }, "POST");
            if (eulasResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(eulasResponse);
                return;
            }
            if (isNullOrUndefined(eulasResponse.assets) || eulasResponse.assets.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("EULA record could not be retrieved.")
                return;
            }
            let asset: Asset = eulasResponse.assets[0];

            let eulaFields: EulaFields = {
                acceptanceId: 0,
                accepted: eulaAccepted,
                organization: "",
                name: "",
                title: "",
                email: "",
                date: undefined,
                preAccepted: false
            };

            if (this.parentStore.rootStore.ClientConfig.lazyData.webStore) {
                if (this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields) {
                    eulaFields = {...this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields};
                    // eulaFields.date = new Date();
                    eulaFields.preAccepted = false;
                }
            }

            // if  (eulaAccepted === true) {
            //     eulaFields.acceptanceId = (eulaAcceptance.id);
            //     eulaFields.organization = (eulaAcceptance.acceptCompany);
            //     eulaFields.name = (eulaAcceptance.acceptName);
            //     eulaFields.title = (eulaAcceptance.acceptTitle);
            //     eulaFields.email = (eulaAcceptance.acceptEmail);
            //     eulaFields.date = new Date(eulaAcceptance.acceptDate);
            //     eulaFields.preAccepted = true;
            // }

            product.pricingConfiguration.productId = product.id;

            let store: UIPurchaseWizardStore = new UIPurchaseWizardStore(this.parentStore, product, eulaFields);
            store.purchaseMode = true;
            store.eulaAssetId = asset.id;
            store.setItemValuesFromPreviousSubscription(subscription, this);
            store.showForm();
     
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }    
}