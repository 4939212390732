import * as React from 'react';
import {observer} from 'mobx-react';
import { TextField, ITextFieldProps } from '@fluentui/react/lib/TextField';
import { IValidationFieldProps } from '../web-store/ValidationFieldProps';
import { ValidationField } from '../web-store/ValidationField';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';


export interface IValidationTextFieldProps extends IValidationFieldProps, ITextFieldProps {
    
}

@observer
export class ValidationTextField extends React.Component<IValidationTextFieldProps, any> {

    render() {

        const props: IValidationFieldProps = this.props as IValidationFieldProps;
        let vf: ValidationField = this.props.store.getValidationField(props);

        //const hasvalidators = vf ? this.props.store.hasValidators(vf) : false;
        
        const onchanged =  vf ? (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, v?: string) => 
            { 
                this.props.store.validateField(vf, v); 
                if (!isNullOrUndefined(vf.errorMessage)) {
                    if (this.props.onChange) this.props.onChange(event, v);
                }
            } 
            : this.props.onChange;

        let errorMessage: string = null;
        if (vf) {
            if (!isNullOrUndefined(vf.errorMessage)) {
                errorMessage = vf.errorMessage;
            }
        }

        const value: string = isNullOrUndefined(this.props.value) ? (vf ? (this.props.store.getFormValue(vf) as string) : "") : this.props.value;
        //this.props.store.validationFields[1].errorMessage

        return(
            <TextField 
                {...this.props } 
                //onGetErrorMessage={ hasvalidators ? (v: string) => { return this.props.store.getValidationFieldErrorMessage(vf, v) }  : this.props.onGetErrorMessage} 
                onChange={ onchanged}  
                errorMessage={ errorMessage }
                validateOnFocusOut={true}
                value={value}
            />
        )
    }
}