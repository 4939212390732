import {UIStore} from './UIStore'
import { SearchFormStore, IColumnProps } from './UISearchFormStore';
import { Selection } from "office-ui-fabric-react/lib/DetailsList";
import { Signup } from '../web-store/ServerClasses';
import { UIAccountFormStore } from './UIAccountFormStore';
import { AdminSignupsSearchForm } from '../components/AdminSignupsSearchForm';
import { UIAdminSignupFormStore } from './UIAdminSignupFormStore';

export class UIAdminSignupsFormStore extends SearchFormStore<Signup> {
    selection: Selection;
    readonly isTrial: boolean;

    constructor(parent: UIStore) {
        super(parent, "firstName");
        this.setBaseObject({ notUsed: 1});
        this.selection = new Selection();
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("signups", (records, response) => {                 
                if (records) {
                    this.parentStore.addForm(AdminSignupsSearchForm.create(this));
                }
            });
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }
 
    setColumns(): IColumnProps[] {
        const columns: IColumnProps[] = [
            {
                key: "1",
                name: "Id",
                fieldName: "id",
                isSortedDescending: false,
                isSorted: true
            },
            {
                key: "2",
                name: "Approved",
                fieldName: "approved",
                isSortedDescending: false,
                isSorted: true
            },
            {
                key: "3",
                name: "Email",
                fieldName: "emailId",
                isSortedDescending: false,
                isSorted: true
            },            
            {
                key: "4",
                name: "First Name",
                fieldName: "firstName",
                isSortedDescending: false,
                isSorted: false
            },             
            {
                key: "5",
                name: "Last Name",
                fieldName: "firstName",
                isSortedDescending: false,
                isSorted: false
            },           
            {
                key: "6",
                name: "Company",
                fieldName: "companyName",
                isSortedDescending: false,
                isSorted: false
            },            
            {
                key: "7",
                name: "IP",
                fieldName: "ipAddress",
                isSortedDescending: false,
                isSorted: false
            },            
            {
                key: "8",
                name: "Signup Date",
                fieldName: "signupDate",
                isSortedDescending: false,
                isSorted: false
            },            
            {
                key: "9",
                name: "Approval Date",
                fieldName: "approvalDate",
                isSortedDescending: false,
                isSorted: false
            }      
        ];

        return columns;
    }

    protected getFilterRecordValue(r: Signup): string {
        return r.firstName + r.lastName;
    }

    refresh = async () => {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("signups", null);
        } finally {
            this.parentStore.uiTransition.hide();
        } 
    }

    gotoSignup = async(signup: Signup) => {
        new UIAdminSignupFormStore(this.parentStore).showForm(signup.id);
    }
}