import {action, observable} from 'mobx';
import { String } from 'typescript-string-operations';
import {UIStore, InitForm} from './UIStore'
import {UIEditFormStore} from './UIEditFormStore'
import * as Server from '../web-store/ServerClasses'
import { RequiredValidator, EmailValidator } from '../web-store/Validators';
import { ServerInsight } from '../web-store/ServerInsight';

export interface ForgotPassword {
    message: string;   
    isError: boolean;   
    userId: string;
    isLoading: boolean;
    captcha: string;
}

export class UIForgotPasswordFormStore extends UIEditFormStore<ForgotPassword> {
    ref: any;

    constructor(parent: UIStore, forgotData: ForgotPassword) {
        super(parent)
        this.setBaseObject(forgotData);
        this.createValidationFields();
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "userId",
                propName: "userId",
                errorMessage: "",
                validators: [new EmailValidator()]
            }
        ]
    }

    @action
    resetCaptcha = (): void => {
        try {
            (this.baseObject as ForgotPassword).captcha = "";
            if (this.ref != null) this.ref.execute();
        } catch (error) {
            console.error(error);
        }
    }

    @action
    verifyCaptcha = (response: string): void => {
        (this.baseObject as ForgotPassword).captcha = response;
    }

    @action
    navigateToLogin = (): void => {
        this.parentStore.initForm = InitForm.signIn;
    }

    @action
    forgotPassword = async () : Promise<void> => {
        let data: ForgotPassword = (this.baseObject as ForgotPassword);
        data.message = "";
        data.isError = false;
        let serverApi = this.parentStore.serverApi;
        try {
            if (!this.validateForm()) 
                return;
            
            if (this.parentStore.rootStore.ClientConfig.disableRecaptcha === false) {
                if (String.IsNullOrWhiteSpace(data.captcha)) {
                    data.isError = true;
                    data.message = "Unable to verify that this is not a bot.";
                    return;
                }
            }   

            data.isLoading = true;
            data.isError = false;

            let ai: ServerInsight = new ServerInsight("appForgotPwd");
            ai.data = {
                "userId": data.userId,
            }
            ai.update(serverApi);

            let request: Server.ForgotPasswordRequest = {
                brc: (this.parentStore.rootStore.ClientConfig.disableRecaptcha === true) ? "tuktuk" : "none",
                captcha: data.captcha,
                emailId: data.userId,
                isAdmin: false
            }

            let resp: Server.GenericResponse = await serverApi.forgotPassword(request);
            // if (resp.statusCode !== 200) {
            //     data.message = "Your request could not processed at this time.";
            //     data.isError = true;
            //     return;
            // }
            // display message as is "if you have an account ..."
            data.message = resp.message;
            data.userId = "";
        } catch (error) {
            data.message = (error as Error).message;
        } finally {
            data.isLoading = false;
            this.resetCaptcha();
        }
    }
}
