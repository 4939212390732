import {UIStore} from './UIStore'
import {DialogResult, DialogMode} from './UIConfirmDialogStore'
import { SearchFormStore, IColumnProps } from './UISearchFormStore';
import { ProductsSearchForm } from "../components/ProductsSearchForm";
import { Selection } from "office-ui-fabric-react/lib/DetailsList";
import { ProductDTO, SubscriptionDTO, GetEntitiesResponse, EulaDTO, Asset, AgreementAcceptance, CreateAssetsRequest, AssetType, CreateAssetsResponse, FSPayloadResponse } from '../web-store/ServerClasses';
import { UIPurchaseWizardStore, EulaFields } from './UIPurchaseWizardStore';
import { toJS } from 'mobx';
import moment, { isDate } from 'moment';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';

export class UIProductsFormStore extends SearchFormStore<ProductDTO> {
    
    selection: Selection;

    constructor(parent: UIStore) {
        super(parent, "name");
        this.setBaseObject({ notUsed: 1});
        this.selection = new Selection();
    }

    async showForm() {
        this.parentStore.uiTransition.show();
        try {
            await this.getRecordsFromApi("products", (records, response) => {                 
                if (records) { 
                    //records.forEach(x => x.key = x.id);
                    this.parentStore.addForm(ProductsSearchForm.create(this));
                }
            });
                
        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
            this.parentStore.uiTransition.hide();            
        }
    }
 
    setColumns(): IColumnProps[] {
        const columns: IColumnProps[] = [
            {
                key: "1",
                name: "Product",
                fieldName: "name",
                isSortedDescending: false,
                isSorted: true
            },
            {
                key: "2",
                name: "Buy",
                fieldName: "-",
                isSortedDescending: false,
                isSorted: false
            },
            {
                key: "3",
                name: "Trial",
                fieldName: "-",
                isSortedDescending: false,
                isSorted: false
            }                        
        ];

        return columns;
    }

    refresh = async () => {
        await this.getRecordsFromApi("products", null);
    }

    startTrial = async(product: ProductDTO) => {
        this.parentStore.uiTransition.show(); 
        try {
            // check if trial has already been done by this account for this product
            let trialsResponse: GetEntitiesResponse<SubscriptionDTO> = await this.parentStore.serverApi.getEntities<SubscriptionDTO>("subscriptions", ["productid=" + product.id.toString(), "istrial=1"]);
            if (trialsResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(trialsResponse);
                return;
            }
            if (trialsResponse.entities.length > 0) {
                this.parentStore.messageStore.showErrorMessage("You have already requested a trial for this product. If you want an additional trial, please send us a message throught the Contact link");
                return;
            }

            // check if eula has been signed for this product/company/trial - this is for offline acceptance
            let eulaAccepted: boolean = false;
            let eulaAcceptance: AgreementAcceptance = null;
            // let eulaAcceptancesResponse: GetEntitiesResponse<EulaAcceptance> = await this.parentStore.serverApi.getEntities<EulaAcceptance>("eulaacceptances", 
            //     ["trial=1",
            //      "productid=" + product.id.toString()
            //      // company is implicit for user auth
            //     ]);
            // if (eulaAcceptancesResponse.statusCode !== 200) {
            //     this.parentStore.messageStore.showMessageFromResponse(eulaAcceptancesResponse);
            //     return;
            // }
            // if (eulaAcceptancesResponse.entities.length > 0) {
            //     eulaAcceptance = eulaAcceptancesResponse.entities[0];
            //     if (isNullOrUndefined(eulaAcceptance.expiryDate)) {
            //         eulaAccepted = true;
            //     } else {
            //         eulaAccepted = moment(eulaAcceptancesResponse.entities[0].expiryDate).isAfter(moment.utc(), "days");
            //     }
            // }              

            // create asset for eula
            let eulasResponse: CreateAssetsResponse =  await this.parentStore.serverApi.postData<CreateAssetsRequest, CreateAssetsResponse>("assets", {
                assetType: AssetType.Agreement,
                id: product.eulaId,
                publicId: "",
                }, "POST");
            if (eulasResponse.statusCode !== 200) {
                this.parentStore.messageStore.showMessageFromResponse(eulasResponse);
                return;
            }
            if (isNullOrUndefined(eulasResponse.assets) || eulasResponse.assets.length <= 0) {
                this.parentStore.messageStore.showErrorMessage("EULA record could not be retrieved.")
                return;
            }
            let asset: Asset = eulasResponse.assets[0];

            let eulaFields: EulaFields = {
                acceptanceId: 0,
                accepted: eulaAccepted,
                organization: "",
                name: "",
                title: "",
                email: "",
                date: undefined,
                preAccepted: false
            };

            if (this.parentStore.rootStore.ClientConfig.lazyData.webStore) {
                if (this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields) {
                    eulaFields = {...this.parentStore.rootStore.ClientConfig.lazyData.webStore.eulaFields};
                    // eulaFields.date = new Date();
                    eulaFields.preAccepted = false;
                }
            }

            // if  (eulaAccepted === true) {
            //     eulaFields.acceptanceId = (eulaAcceptance.id);
            //     eulaFields.organization = (eulaAcceptance.acceptCompany);
            //     eulaFields.name = (eulaAcceptance.acceptName);
            //     eulaFields.title = (eulaAcceptance.acceptTitle);
            //     eulaFields.email = (eulaAcceptance.acceptEmail);
            //     eulaFields.date = new Date(eulaAcceptance.acceptDate);
            //     eulaFields.preAccepted = true;
            // }

            product.pricingConfiguration.productId = product.id;

            let store: UIPurchaseWizardStore = new UIPurchaseWizardStore(this.parentStore, product, eulaFields);
            store.purchaseMode = false;
            store.pricingMode = false;
            store.eulaAssetId = asset.id;
            store.showForm();

        } catch (error) {
            this.parentStore.messageStore.showErrorFromException(error);
        } finally {
            this.parentStore.uiTransition.hide();
        }
    }  
}