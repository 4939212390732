import * as React from 'react';
import {Checkbox} from '@fluentui/react/lib/Checkbox';
import { TextField } from '@fluentui/react/lib/TextField';
import { UIAccountFormStore } from '../stores/UIAccountFormStore';
import { observer } from 'mobx-react';
import * as Server from '../web-store/ServerClasses'
import { FormHeader } from './FormHeader';
import { ICommandBarItemProps, CommandBar, Icon, Stack, Text, DatePicker } from '@fluentui/react';
import { ValidationField } from '../web-store/ValidationField';
import { ValidationTextField } from './ValidationTextField';
import { ValidationCheckBox } from './ValidationCheckBox';

class AccountFormProps {
    store: UIAccountFormStore;
}

@observer
export class AccountForm extends React.Component<AccountFormProps, any> {

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIAccountFormStore) {
        return <AccountForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [
            {
                key: "",
                text: "Change Password",
                iconProps: {
                    iconName: "Lock",
                },
                onClick: this.props.store.changePassword
            },
            {
                key: "",
                text: "Organization",
                iconProps: {
                    iconName: "Org",
                },
                onClick: this.props.store.gotoOrg
            }                                  
        ];

        if (this.props.store.parentStore.rootStore.domainStore.AdminMode === true) {
            // replace password with save in admin mode.
            // admins should not change user's password
            items.splice(0, 1, {
                key: "",
                text: "Save",
                iconProps: {
                    iconName: "Save",
                },
                onClick: () => { this.props.store.saveAccount() }
            }          
            );
        }

        this.props.store.addCloseMenu(items);

        this.props.store.parentStore.setCommandBarItemsKeys(items);

        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    render() {
        const account = this.props.store.baseObject as Server.AccountDTO;
        let stackStyles = {root: {width: "80%" }};
        const userMode = (this.props.store.parentStore.rootStore.domainStore.AdminMode === false);

        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content"> 
                    <FormHeader header="Account" uiStore={this.props.store.parentStore} />

                    <Stack tokens={{childrenGap: 10, padding: 10 }} styles={stackStyles} >
                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={userMode}
                                label="User ID"
                                fieldId="emailId"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                readOnly={userMode}
                                label="First Name"
                                fieldId="firstName"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <ValidationTextField 
                                label="Last Name"
                                fieldId="lastName"
                                store={this.props.store}
                            />
                        </Stack.Item>

                        <Stack.Item>
                            <TextField 
                                label="Organization"
                                readOnly={true}
                                value={account.company.name}                               
                            />
                        </Stack.Item>

                        { (this.props.store.parentStore.rootStore.AdminMode === false) &&
                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 10, padding: 10 }}>
                                <Stack.Item>
                                    <Icon 
                                        iconName="Warning"
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Text>
                                        Your account information cannot be changed here. If 
                                        you need to change it, please send us a message from
                                        the Contact link.
                                    </Text>
                                </Stack.Item>

                            </Stack>
                        </Stack.Item>
                        }

                        { (this.props.store.parentStore.rootStore.AdminMode === true) &&
                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 30, padding: 10 }}>
                                <Stack.Item>
                                    <ValidationCheckBox 
                                        label="Enabled"
                                        fieldId="enabled"
                                        store={this.props.store}
                                        checked={account.enabled}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ValidationCheckBox 
                                        label="Approved"
                                        fieldId="approved"
                                        store={this.props.store}
                                        checked={account.approved}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ValidationCheckBox 
                                        label="Organization Admin"
                                        fieldId="companyAdmin"
                                        store={this.props.store}
                                        checked={account.companyAdmin}
                                    />
                                </Stack.Item>
                                <Stack.Item>
     
                                </Stack.Item>                            
                            </Stack>
                        </Stack.Item>
                        }

                        { (this.props.store.parentStore.rootStore.AdminMode === true) &&
                        <Stack.Item>
                            <DatePicker
                                label="Expires"
                                showMonthPickerAsOverlay={true}
                                onSelectDate={this.props.store.selectExpiryDate}
                                value={account.expires}
                            />
                        </Stack.Item>
                        }   
                    </Stack> 
    
                </div>
            </div>
        );
    }

    createMarkup() {
        return {__html: '<p>kk</p>'};
    }
}
