import * as React from "react";
import { observer } from "mobx-react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as Server from "../web-store/ServerClasses"
import { FormHeader } from "./FormHeader";
import { IColumnProps } from "../stores/UISearchFormStore";
import { PrimaryButton, ICommandBarItemProps, CommandBar } from "office-ui-fabric-react";
import { UISubscriptionsFormStore as UISubscriptionsFormStore } from "../stores/UISubscriptionsFormStore";
import moment from "moment";

class SubscriptionsSearchFormProps {
    store: UISubscriptionsFormStore;
}

@observer
export class SubscriptionsSearchForm extends React.Component<SubscriptionsSearchFormProps, any> {

    componentDidMount () {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UISubscriptionsFormStore) {
        return <SubscriptionsSearchForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [];
        this.props.store.addCloseMenu(items);
        this.props.store.parentStore.setCommandBarItemsKeys(items);
        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    private _getColumns() : IColumn[] {
        const columns: IColumn[] = [
            {
                key: "1",
                name: "Product",
                fieldName: "name",
                minWidth: 200,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.SubscriptionDTO) => {
                    return (
                        <div>
                            {item.product.name + ", v" + item.product.version} 
                        </div>  
                    )
                }
            },
            {
                key: "2",
                name: "Id",
                fieldName: "publicId",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true
            },            
            {
                key: "3",
                name: (this.props.store.isTrial === true) ?  "Trial Ends": "Subscription Ends",
                fieldName: "endDate",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.SubscriptionDTO) => {
                    return (
                        <div>
                            {moment(item.endDate).format("YYYY-MM-DD")}
                        </div>  
                    )
                }            
            },
            {
                key: "4",
                name: "Assets",
                fieldName: "-",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.SubscriptionDTO) => {
                    return (
                        <PrimaryButton text="Download"
                            onClick={() => {this.props.store.gotoAssets(item)} }
                        />                    
                    )
                }
            },
            {
                key: "5",
                name: "Renew",
                fieldName: "-",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.SubscriptionDTO) => {
                    return (
                        this.props.store.isRenewableNow(item) ? 
                        <PrimaryButton text="Renew"
                            onClick={() => {this.props.store.gotoRenew(item)} }
                        /> : null
                    )
                }
            }                                              
          ];

        if (this.props.store.parentStore.rootStore.AdminMode === true) {
            columns.push(
                {
                    key: "6",
                    name: "Organization",
                    fieldName: "organization",
                    minWidth: 250,
                    maxWidth: 300,
                    isRowHeader: true,
                    isResizable: false,
                    isSorted: false,
                    isSortedDescending: false,
                    onColumnClick: this.props.store.onColumnClick,
                    data: "string",
                    isPadded: true                 
                }    
            );
        }

        // sync these overrides properties from props
        columns.forEach((c: IColumn, i: number) => {
            const currColumn: IColumnProps = this.props.store.displayedColumns.filter((currCol: IColumnProps, idx: number) => {
                return c.key === currCol.key;
              })[0];
            
            c.isSorted = currColumn.isSorted;
            c.isSortedDescending = currColumn.isSortedDescending;
            // not setting onColumnClick as not all cols are clickable
        });

        return columns;
    }

    detailList() {
        return (
            <div style={{ overflowX: "auto", marginTop: 10}}>
                <TextField
                    placeholder={"Filter by name"}
                    onChange={ this.props.store.filter }
                    value= {this.props.store.filterValue}
                />
                
                <div style={{height: 600, overflowY: "auto"}} data-is-scrollable={true}>
                    <DetailsList
                        items={ this.props.store.displayedRecords }
                        columns={ this._getColumns() }
                        compact={ false }
                        selectionMode={ SelectionMode.none }
                        layoutMode={ DetailsListLayoutMode.justified }
                        isHeaderVisible={ true }
                        constrainMode={ ConstrainMode.unconstrained }
                    />
                </div>
            </div>
        );          
    }

    render() {
        
        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content">
                    <FormHeader header={this.props.store.isTrial ? "Trials" : "Subscriptions"} uiStore={this.props.store.parentStore} />

                    { this.detailList() }
                </div>
            </div>
        );
    }
}   