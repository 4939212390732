import * as React from 'react';
import {Checkbox, ICheckboxProps} from '@fluentui/react/lib/Checkbox';
import { isUndefined } from 'util';
import { IValidationFieldProps } from '../web-store/ValidationFieldProps';
import { ValidationField } from '../web-store/ValidationField';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';

export interface IValidatedCheckBoxProps extends IValidationFieldProps, ICheckboxProps {
    onValue?: string;
    offValue?: string;
}

export class ValidationCheckBox extends React.Component<IValidatedCheckBoxProps, any> {
    
    render() {
        const props: IValidationFieldProps = this.props as IValidationFieldProps;
        let vf: ValidationField = this.props.store.getValidationField(props);

        const onchange = (ev: React.FormEvent<HTMLElement>, isChecked: boolean) => 
        { 
            if (isUndefined(this.props.onValue) || isUndefined(this.props.offValue)) {
                this.props.store.setFormValue(vf, isChecked)
                return;
            } 
            
            this.props.store.setFormValue(vf, isChecked ?  this.props.onValue : this.props.offValue)
        };

        // let checked: boolean = false; //this.props.checked;
        // if (this.props.checked === undefined) {
        //     if (isUndefined(this.props.onValue) || isUndefined(this.props.offValue)) {
        //         checked = this.props.store.getFormValue(vf) as boolean;
        //     } else {
        //         checked = (this.props.store.getFormValue(vf) === this.props.onValue);
        //     }
        // } else {
        //     checked = this.props.checked;
        // }
        
        return(
            <Checkbox {...this.props } 
                onChange={onchange}
            />
        )
    }
}