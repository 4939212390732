import {observable, action} from 'mobx';
import { UIStore } from './UIStore';
import { INavLink } from '@fluentui/react';
import { allowStateReadsStart } from 'mobx/lib/internal';
import { UIProductsFormStore } from './UIProductsFormStore';
import { UISubscriptionsFormStore } from './UISubscriptionsFormStore';
import { UIContactFormStore } from './UIContactFormStore';
import { UIOrdersFormStore } from './UIOrdersFormStore';
import { UIAdminAccountsFormStore } from './UIAdminAccountsFormStore';
import { UIAdminSignupsFormStore } from './UIAdminSignupsFormStore';

export class UISidebarStore {
    uiStore: UIStore;
    @observable adminMenus: INavLink[];
    @observable userMenus: INavLink[];
    @observable opened: boolean;

    constructor(uiStore: UIStore) {
        this.uiStore = uiStore;
        this.opened = true;
    }

    initMenus() {
        this.adminMenus = [
            {
                key: "signups",
                name: "Sign Ups",
                url: "",
                icon: "PageHeaderEdit",
                onClick: this._gotoSignups
            },
            {
                key: "accounts",
                name: "Accounts",
                url: "",
                icon: "AccountBrowser",
                onClick: this._gotoAccounts
            },
            {
                key: "products",
                name: "Products",
                url: "",
                icon: "Product",
                onClick: this._gotoProducts,
            },
            {
                key: "purchases",
                name: "Purchases",
                url: "",
                icon: "StoreLogoMed20",
                onClick: this._gotoPurchases
            },
            {
                key: "trials",
                name: "Trials",
                url: "",
                icon: "TestStep",
                onClick: this._gotoTrials
            },
            {
                key: "orders",
                name: "Orders",
                url: "",
                icon: "ClipboardList",
                onClick: this._gotoOrders
            },
            {
                key: "contact",
                name: "Contact",
                url: "",
                icon: "Mail",
                onClick: this._gotoContact
            },
            {
                key: "faq",
                name: "FAQ",
                url: "",
                icon: "StatusCircleQuestionMark",
                onClick: this._gotoFaq
            }  
        ]

        this.userMenus = [
            {
                key: "subscriptions",
                name: "Subscriptions",
                url: "",
                icon: "StoreLogoMed20",
                onClick: this._gotoPurchases
            },
            {
                key: "trials",
                name: "Trials",
                url: "",
                icon: "TestStep",
                onClick: this._gotoTrials
            },
            {
                key: "orders",
                name: "Orders",
                url: "",
                icon: "ClipboardList",
                onClick: this._gotoOrders
            },
            {
                key: "contact",
                name: "Contact",
                url: "",
                icon: "Mail",
                onClick: this._gotoContact
            },
            {
                key: "faq",
                name: "FAQ",
                url: "https://support.aellius.com/hc/en-us/sections/360011931473-Store-FAQ",
                target: "_blank",
                icon: "StatusCircleQuestionMark",
            }            
        ];
        
        if (this.uiStore.rootStore.AdminMode === false) {
            if (this.uiStore.rootStore.domainStore.userAccount.companyAdmin !== true) {
                this.userMenus = this.userMenus.splice(this.userMenus.findIndex(u => u.key == "orders"));
            }
        }
    }

    public _gotoProducts = (): void => {
        this.uiStore.messageStore.clear();
        new UIProductsFormStore(this.uiStore).showForm();
        // this.uiStore.confirmDialogStore.message = "testing";
        // this.uiStore.confirmDialogStore.showDialog();
        // this.uiStore.messageStore.showInfoMessage("testing")
    }

    private _gotoPurchases = (): void => {
        this.uiStore.messageStore.clear();
        new UISubscriptionsFormStore(this.uiStore, false).showForm();
    }

    private _gotoTrials = (): void => {
        this.uiStore.messageStore.clear();
        new UISubscriptionsFormStore(this.uiStore, true).showForm();
    }
   
    private _gotoOrders = (): void => {
        this.uiStore.messageStore.clear();
        new UIOrdersFormStore(this.uiStore, false).showForm();
    }

    private _gotoSignups = (): void => {
        this.uiStore.messageStore.clear();
        new UIAdminSignupsFormStore(this.uiStore).showForm();
    }

    private _gotoAccounts = (): void => {
        this.uiStore.messageStore.clear();
        new UIAdminAccountsFormStore(this.uiStore).showForm();
    }

    private _gotoContact = (): void => {
        this.uiStore.messageStore.clear();
        new UIContactFormStore(this.uiStore).showForm();
    }

    private _gotoFaq = (): void => {
        this.uiStore.messageStore.clear();
    }    
}