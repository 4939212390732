import { observable } from 'mobx';
import { LocalStore, LocalUserInfo } from './LocalStore';
import { DomainStore } from './DomainStore';
import { ServerApi } from '../web-store/ServerApi';
import { UIStore } from './UIStore';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';
import {ClientConfig} from '../web-store/ClientConfig'
import { BrowserInfo, detect } from "detect-browser";
import { LoginResponse, AppInitResponse } from '../web-store/ServerClasses';
import { getTheme, createTheme, IPartialTheme, ITheme, loadTheme } from '@fluentui/react/lib/Styling';
import { loadReCaptcha } from 'react-recaptcha-v3'
import { Customizations } from '@fluentui/react';

export class AppStore {
    localStore: LocalStore;
    domainStore: DomainStore;
    uiStore: UIStore;
    serverApi: ServerApi;  
    private _clientConfig: ClientConfig;
    private _browserInfo: BrowserInfo;
    passwordResetId: string;
    initData: AppInitResponse;
    private _minPasswordScore: number;
    private _minPasswordLength: number;
    private _gCapthaCssRule: number;
    queryParams: any;
  
    constructor() {
        this.serverApi = new ServerApi();
        this.localStore = new LocalStore();
        this.domainStore = new DomainStore(this, this.serverApi);       
        this.uiStore = new UIStore(this, getTheme(), this.serverApi);
        this._gCapthaCssRule = -1;
    }

    public get AdminMode(): boolean {
        if (isNullOrUndefined(this.queryParams)) return false;
        return (this.queryParams.admin == "true");
    }

    public get MinPasswordScore(): number {
        return this._minPasswordScore;
    }
      
    public get MinPasswordLength(): number {
      return this._minPasswordLength;
    }
    
    public get ClientConfig() : ClientConfig {
        return this._clientConfig;
    }

    public get BrowserInfo() : BrowserInfo {
        return this._browserInfo;
    }

    appInit = async() => {
        await this.preLoginInit();
        
        this.getQueryStringParams(window.location.search);

        this.initData = await this.serverApi.appInit();

        if (isNullOrUndefined(this.initData)) {
            throw new Error("An error occured in initializing this app.")
            return;
        }

        this._minPasswordScore = this.initData.features.pwds as number;
        this._minPasswordLength = this.initData.features.pwdl as number;
  
        let userInfo: LocalUserInfo = this.localStore.UserInfo;
        if (isNullOrUndefined(userInfo)) return;

        if (isNullOrUndefined(userInfo.userId) || isNullOrUndefined(userInfo.usha) || isNullOrUndefined(userInfo.token)) return;

        let loginResponse: LoginResponse = await this.serverApi.validateLogin(userInfo.usha, userInfo.token);
        if (loginResponse.statusCode !== 200) {
            this.clearLocalUserInfo();
            return;
        }

        this.removegCaptchaBadge();
        this.domainStore.setLoginStatus(loginResponse);
    }

    removegCaptchaBadge = () => {
        let css: CSSStyleSheet = document.styleSheets[0] as CSSStyleSheet;
        this._gCapthaCssRule = css.insertRule(".grecaptcha-badge {visibility: hidden}", 0);
    }

    showgCaptchaBadge = () => {
        if (this._gCapthaCssRule < 0) return;
        let css: CSSStyleSheet = document.styleSheets[0] as CSSStyleSheet;
        css.removeRule(this._gCapthaCssRule);
    }

    preLoginInit = async (): Promise<void> => {
        this.readBrowser();

        this._clientConfig = null;
        this._clientConfig = await this.serverApi.getClientConfig();
        if (!this._clientConfig) 
            // this is a hard coded messages as config will have language
            throw new Error("Unable to load client configuration");

        // console.log(this.ClientConfig.palette);

        // let myTheme: ITheme = loadTheme({palette: this.ClientConfig.palette});
        let myTheme: ITheme = createTheme({palette: this.ClientConfig.palette});
        // console.log(myTheme.semanticColors);

        Customizations.applySettings({ theme: myTheme });

        document.body.style.color =  myTheme.semanticColors.bodyText;
        document.body.style.backgroundColor = myTheme.semanticColors.bodyBackground;

        // see https://stackoverflow.com/questions/44897499/property-addrule-and-insertrule-does-not-exist-on-type-stylesheet
        // may need to use addRule for IE
        let css: CSSStyleSheet = document.styleSheets[0] as CSSStyleSheet;
        css.insertRule("a:visited {color: " + myTheme.semanticColors.link + "}", 0);

        css.insertRule(".ms-DatePicker-table td {color: " +  myTheme.semanticColors.bodyText + "}", 0);
        css.insertRule(".ms-DatePicker-header div {color: " +  myTheme.semanticColors.bodyText + "}", 0);
        css.insertRule(".th.ms-DatePicker-weekday {color: " +  myTheme.semanticColors.bodyText + "}", 0);
        css.insertRule("button.ms-DatePicker-prevMonth {color: " +  myTheme.semanticColors.bodyText + "}", 0);
        css.insertRule("button.ms-DatePicker-nextMonth {color: " +  myTheme.semanticColors.bodyText + "}", 0);
        css.insertRule(".ms-DatePicker-table td[class*=dayIsHighlighted][class*=daySelection] {background-color: " +  myTheme.semanticColors.disabledBodySubtext + " !important}", 0);

        loadReCaptcha(this._clientConfig.recaptchaSiteKey);
        
        // load resource manager here - future

        this.uiStore.preLoginInit(myTheme);
    }    

    private readBrowser() : void {
        const bi: BrowserInfo = detect() as BrowserInfo;
        if (bi) {
            this._browserInfo = bi;
        }
        else {
            this._resetBrowserInfo();
        }
    }
    
    private _resetBrowserInfo(): void {
        // using junk values to satisfy type checking
        this._browserInfo = {
            name: "fxios",
            version: "Unknown",
            os: "BeOS"
        }
    }

    public clearLocalUserInfo(): void  {
        let userInfo: LocalUserInfo = this.localStore.UserInfo;
        if (isNullOrUndefined(userInfo)) return;

        userInfo.usha = null;
        userInfo.userName = null;
        userInfo.token = null;
        // keep asAdmin, userid, environment

        this.localStore.UserInfo = userInfo;
    }
    
    public getQueryStringParams (query: string): void {
        this.queryParams = query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params:any, param:string) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    }
}

