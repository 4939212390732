import * as React from "react";
import { observer } from "mobx-react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from "office-ui-fabric-react/lib/DetailsList";
import { Link } from "office-ui-fabric-react/lib/Link";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as Server from "../web-store/ServerClasses"
import { FormHeader } from "./FormHeader";
import { UIProductsFormStore } from "../stores/UIProductsFormStore";
import { IColumnProps } from "../stores/UISearchFormStore";
import { PrimaryButton, ICommandBarItemProps, CommandBar } from "office-ui-fabric-react";

class ProductsSearchFormProps {
    store: UIProductsFormStore;
}

@observer
export class ProductsSearchForm extends React.Component<ProductsSearchFormProps, any> {

    componentDidMount () {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIProductsFormStore) {
        return <ProductsSearchForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [];
        this.props.store.addCloseMenu(items);
        this.props.store.parentStore.setCommandBarItemsKeys(items);
        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    private _getColumns() : IColumn[] {
        const columns: IColumn[] = [
            {
                key: "1",
                name: "Product",
                fieldName: "name",
                minWidth: 250,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.ProductDTO) => {
                    return (
                        <div>
                            {item.name + ", v" + item.version} 
                        </div>  
                    )
                }
            },
            {
                key: "2",
                name: "Buy",
                fieldName: "name",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.ProductDTO) => {
                    return (
                        <PrimaryButton text="Buy Now"
                        />                    
                    )
                }
            },
            {
                key: "3",
                name: "Trial",
                fieldName: "name",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                onColumnClick: this.props.store.onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: Server.ProductDTO) => {
                    return (
                        <PrimaryButton text="Start Trial"
                            iconProps= {
                                {
                                    iconName: "TestStep"
                                }
                            }
                            onClick={() => {this.props.store.startTrial(item)} }
                        />                    
                    )
                }
            }                                          
          ];

        // sync these overriden properties from props
        columns.forEach((c: IColumn, i: number) => {
            const currColumn: IColumnProps = this.props.store.displayedColumns.filter((currCol: IColumnProps, idx: number) => {
                return c.key === currCol.key;
              })[0];
            
            c.isSorted = currColumn.isSorted;
            c.isSortedDescending = currColumn.isSortedDescending;
            // not setting onColumnClick as not all cols are clickable
        });

        return columns;
    }

    private _renderProduct(product: Server.ProductDTO) {
        return (
            <div style={{height: "100%", display: "flex", alignItems: "center"}}>
                <div>
                    {product.name + ", v" + product.version} 
                </div>
                <div style={{marginLeft:20 }}>
                    <PrimaryButton
                        text="Buy Now"
                    />
                </div>
                <div style={{marginLeft:20 }}>
                    <PrimaryButton
                        text="Start a Trial"
                    />
                </div>
            </div>
        )
    }

    detailList() {

        return (
            <div style={{ overflowX: "auto", marginTop: 10}}>
                <TextField
                    placeholder={"Filter by name"}
                    onChange={ this.props.store.filter }
                    value= {this.props.store.filterValue}
                />
                
                <div style={{height: 600, overflowY: "auto"}} data-is-scrollable={true}>
                    <DetailsList
                        items={ this.props.store.displayedRecords }
                        columns={ this._getColumns() }
                        compact={ false }
                        selectionMode={ SelectionMode.none }
                        layoutMode={ DetailsListLayoutMode.justified }
                        isHeaderVisible={ true }
                        constrainMode={ ConstrainMode.unconstrained }
                        // selectionPreservedOnEmptyClick={ true }
                        // enterModalSelectionOnTouch={ true }
                    />
                </div>
            </div>
        );          
    }

    render() {
        
        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content">
                    <FormHeader header={"Products"} uiStore={this.props.store.parentStore} />

                    { this.detailList() }
                </div>
            </div>
        );
    }
}   