import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { observer } from 'mobx-react';
import { FormHeader } from './FormHeader';
import { ICommandBarItemProps, CommandBar } from '@fluentui/react';
import { UIChangePasswordStore, ChangePasswordData } from '../stores/UIChangePasswordStore';
import { ValidationPasswordField } from './ValidationPasswordField';

class ChangePasswordFormProps {
    store: UIChangePasswordStore;
}

@observer
export class ChangePasswordForm extends React.Component<ChangePasswordFormProps, any> {

    componentDidMount() {
        this.props.store.parentStore.uiTransition.hide();
    }

    static create(store: UIChangePasswordStore) {
        return <ChangePasswordForm store={store} />;
    }

    _renderCommands() {
        let items: ICommandBarItemProps[] = [
            {
                key: "",
                text: "Change",
                iconProps: {
                    iconName: "Save",
                },
                onClick: () => { this.props.store.changePassword() }
            }                          
        ];
        this.props.store.addCloseMenu(items);

        this.props.store.parentStore.setCommandBarItemsKeys(items);

        return(
            <CommandBar
                items={items}
            />                   
        );
    }

    render() {
        const changePasswordData = this.props.store.baseObject as ChangePasswordData;
        
        return (
            <div className="page-form">
                { this._renderCommands() }

                <div className="page-content">
                    <FormHeader header="Change Password" uiStore={this.props.store.parentStore} />

                    <div className="ms-Grid content-form">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <TextField 
                                    readOnly={true}
                                    label="User Id"
                                    value={changePasswordData.userid}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationPasswordField 
                                    label="Old Password"
                                    resizable={false} 
                                    type="password"
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    fieldId="oldPassword"
                                    ignoreComplexityRequirement={true}
                                />      
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationPasswordField 
                                    label="New Password"
                                    resizable={false} 
                                    type="password"
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    fieldId="newPassword1"
                                />            
                            </div>                    
                        </div>    

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg10">
                                <ValidationPasswordField 
                                    label="Reenter Password"
                                    resizable={false} 
                                    type="password"
                                    deferredValidationTime={1000}
                                    validateOnLoad={ false }
                                    validateOnFocusOut = {true}
                                    store = {this.props.store}
                                    fieldId="newPassword2"
                                /> 
                            </div>                    
                        </div>    
                    </div>
                </div>
            </div>
        );
    }
}
