import * as Axios from 'axios';
import * as Server from './ServerClasses'
//import { ClientConfig } from '../LynXOfficeConfig';
import CryptoJS from 'crypto-js';
import { isNullOrUndefined } from './WebStoreUtil';
import { ClientConfig } from './ClientConfig';
import {IdResponse} from './ServerClasses';
import { arraysEqual } from '@fluentui/react';

export class ServerApi {
  private apiUrl: string;
  private usha: string;
  private token: string;
  private _eKey: any;
  private _eIv: any;
  private _anoneKey: any;
  private _anoneIv: any;
  private _encryptInsights: boolean;
  private _appToken: string;
  // tbd: add last time called, so app can timeout
  // by itself?
    
  constructor() {
      this._encryptInsights = true;
  }

  private get _isLoggedIn(): boolean {
    return (!isNullOrUndefined(this.usha) && !isNullOrUndefined(this.token))  
  }
   
  private _setDefaultHeaders(usha?: string, token?: string): any {
      var headers: any =  {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
          // note from old lynxofficecommon: pragma is required for divine IE (nothing else works!!)
        'Pragma': 'no-cache',
        'Expires': 0,
      };
  
      if (!isNullOrUndefined(usha)) headers.usha = usha;
      if (!isNullOrUndefined(token)) headers.token = token;
  
      if (!isNullOrUndefined(this.usha)) headers.usha = this.usha;
      if (!isNullOrUndefined(this.token)) headers.token = this.token;
  
      return headers;
  }
    
  getClientConfig = async(): Promise<ClientConfig> => {
        let config: ClientConfig = null;
    
        try {
    
          let settings: Axios.AxiosRequestConfig = {
            url: "data/config.json",
            method: "GET",
            headers: this._setDefaultHeaders(null, null)
          };
    
          let response: Axios.AxiosResponse = await Axios.default(settings);
  
          // not doing any checking here if certain members are present etc.
          // we control config.json. make sure it is right!
          config = ((response as Axios.AxiosResponse).data as ClientConfig);
          this.apiUrl = config.apiUrl;
    
          console.log(config.language);

        } catch (error) {
          console.error(error);
          config = null;
        }
        return config;
  }
  
  private errorToResponse<T extends Server.BaseResponse>(error: Axios.AxiosError) {
    try {
      console.log (error);

      if (!error) {
        return ({
          statusCode: 500,
          requestDate: new Date(),
          responseDate: new Date(),
          message: "The request resulted in an unknown error."
        } as T);
      }
  
      if (!error.response) {
        return ({
          statusCode: 500,
          requestDate: new Date(),
          responseDate: new Date(),
          message: "The request did not return any response."
        } as T);    
      }
  
      if (!error.response.status) {
        return ({
          statusCode: 500,
          requestDate: new Date(),
          responseDate: new Date(),
          message: "The request did not return any status."
        } as T);    
      }
      
      if (error.response.data) {
        return (error.response.data as T);
      }
      else {
        return ({
          statusCode: error.response.status,
          requestDate: new Date(),
          responseDate: new Date(),
          message: "The request did not return any data."
        } as T);    
      }
    } catch (e) {
      return ({
        statusCode: 500,
        requestDate: new Date(),
        responseDate: new Date(),
        message: "Error in errorToResponse."
      } as T);  
    }
  }
  
  getApiUrl = (lastPart: string) => {
    return this.apiUrl + "/api/v1/" + lastPart;
  }

  getAssetUrl = (assetId: string, inline: boolean) => {
    return this.apiUrl + "/api/v1/downloadassets?tk1=" + 
      encodeURIComponent(this.token) + 
      "&tk2=" + encodeURIComponent(this.usha) + 
      "&assetId=" + assetId +
      "&inline=" + (inline === true ? "true" : "false");
  }

  appInit = async(): Promise<Server.AppInitResponse> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl("appinit"),
      method: "GET",
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      if (ar.status !== 200) {
        console.error(ar.data);
        return null;
      }
      
      let response: Server.AppInitResponse = ar.data;
      this._appToken = response.appToken;
      // this initializes anon iv, key, encryptInsights
      // this._parseVak(response.vak, response.appToken, false);

      return response;
    } catch (error) {
        console.error(error);
        return null;
    }
  }

  login = async(loginRequest: Server.LoginRequest): Promise<Server.LoginResponse> => {
    let rawRequest: Axios.AxiosRequestConfig = {
      url: this.getApiUrl("login"),
      data: loginRequest,
      method: "POST",
    };
    
    try {
      let ar: Axios.AxiosResponse = await Axios.default(rawRequest);
          
      let response: Server.LoginResponse = ar.data;
      if (response.statusCode === 200) {
        this.usha = response.usha;
        this.token = response.token;
      }

      return response;

    } catch (error) {
      return this.errorToResponse<Server.LoginResponse>(error);
    }
  }  

  validateLogin = async(usha: string, token: string): Promise<Server.LoginResponse> => {
    let rawRequest: Axios.AxiosRequestConfig = {
      url: this.getApiUrl("login"),
      data: null,
      method: "GET",
      headers: this._setDefaultHeaders(usha, token)
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(rawRequest);
          
      let response: Server.LoginResponse = ar.data;
      if (response.statusCode === 200) {
        this.usha = usha;
        this.token = token;
      }

      return response;
    } catch (error) {
      return this.errorToResponse<Server.LoginResponse>(error);
    }
  }

  logoff = async(): Promise<boolean> => {
    try {
      if (isNullOrUndefined(this.usha) || isNullOrUndefined(this.token)) {
        this.usha = null;
        this.token = null;
        return false;
      }

      let rawRequest: Axios.AxiosRequestConfig = {
        url: this.getApiUrl("logoff"),
        data: null,
        method: "POST",
        headers: this._setDefaultHeaders()
      };
      
      let ar: Axios.AxiosResponse = await Axios.default(rawRequest);
          
      let response: Server.GenericResponse = ar.data;
      return (response.statusCode === 200);

    } catch (error) {
      console.error(error);
      return false;
    }
  }

  forgotPassword = async(request: Server.ForgotPasswordRequest): Promise<Server.GenericResponse> => {
    try {
      let rawRequest: Axios.AxiosRequestConfig = {
        url: this.getApiUrl("forgotpassword"),
        data: request,
        method: "POST",
        headers: this._setDefaultHeaders()
      };
      
      let ar: Axios.AxiosResponse = await Axios.default(rawRequest);
          
      let response: Server.GenericResponse = ar.data;
      return response;

    } catch (error) {
      console.error(error);
      return this.errorToResponse<Server.GenericResponse>(error);
    }
  }

  changePassword = async(request: Server.ChangePasswordRequest) : Promise<Server.GenericResponse> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl("changepassword"),
      method: "POST",
      data: request,
      headers: this._setDefaultHeaders(),
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.GenericResponse = ((ar as Axios.AxiosResponse).data as Server.GenericResponse);
      return response;
    } catch (error) {
      return this.errorToResponse(error);
    }
  }

  private _addQueryParams(params?: string[]): string {
    if (isNullOrUndefined(params)) return "";
    if (params.length <= 0) return "";
    return "?" + params.join("&");
  }

  getEntity = async<T>(entityName: string, params?: string[]) : Promise<Server.GetEnityResponse<T>> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl(entityName + this._addQueryParams(params)),
      method: "GET",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.GetEnityResponse<T> = ((ar as Axios.AxiosResponse).data as Server.GetEnityResponse<T>);
      return response;
    } catch (error) {
      return this.errorToResponse(error) as Server.GetEnityResponse<T>;
    }
  }  

  getEntities = async<T>(entityName: string, params?: string[]) : Promise<Server.GetEntitiesResponse<T>> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl(entityName + this._addQueryParams(params)),
      method: "GET",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.GetEntitiesResponse<T> = ((ar as Axios.AxiosResponse).data as Server.GetEntitiesResponse<T>);
      return response;
    } catch (error) {
      return this.errorToResponse(error) as Server.GetEntitiesResponse<T>;
    }
  }
  
  createEntity = async(entityName: string, entity: any) : Promise<Server.IdResponse> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl(entityName),
      method: "POST",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
      data: entity
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.IdResponse = ((ar as Axios.AxiosResponse).data as Server.IdResponse);
      return response;
    } catch (error) {
      return this.errorToResponse(error) as Server.IdResponse;
    }
  } 

  updateEntity = async(entityName: string, entity: any) : Promise<Server.IdResponse> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl(entityName),
      method: "PUT",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
      data: entity
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.IdResponse = ((ar as Axios.AxiosResponse).data as Server.IdResponse);
      return response;
    } catch (error) {
      return this.errorToResponse(error) as Server.IdResponse;
    }
  } 

  deleteEntity = async(entityName: string, id: Server.IdDTO ) : Promise<Server.IdResponse> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl(entityName),
      method: "DELETE",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
      data: id
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.IdResponse = ((ar as Axios.AxiosResponse).data as Server.IdResponse);
      return response;
    } catch (error) {
      return this.errorToResponse(error) as Server.IdResponse;
    }
  }

  postData = async<D, R extends Server.BaseResponse>(urlPart: string, data: D, method: ("POST" | "PUT" | "DELETE")): Promise<R> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl(urlPart),
      method: method,
      headers: this._setDefaultHeaders(),
      withCredentials: false,
      data: data
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: R = ((ar as Axios.AxiosResponse).data as R);
      return response;
    } catch (error) {
      return this.errorToResponse(error) as R;
    }
  }

  getVideo = async (asset: Server.Asset): Promise<Server.VideoReponse> => {
    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl("downloadassets?assetId=" + asset.id + "&inline=false"),
      method: "GET",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
      data: null,
      responseType: "arraybuffer"
    };

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      let response: Server.VideoReponse = {
        statusCode: ar.status,
        internalMessage: "",
        message: "",
        requestDate: new Date(),
        responseDate: new Date(),
        data: ar.data as ArrayBuffer
      }
      return response;
    } catch (error) {
      return this.errorToResponse(error) as Server.VideoReponse;
    }
  }
 
  private _encryptInsight(insight: Server.AppInsight): string {

    let data: string = JSON.stringify(insight);
    if (this._encryptInsights !== true) return data;

    try {
      
      let eKey, eIv: string;
      if (this._isLoggedIn) {
        eKey = this._eKey;
        eIv = this._eIv;
      } else {
        eKey = this._anoneKey;
        eIv = this._anoneIv;
      }
      
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf16LE.parse(data), eKey,
      {
          keySize: 128 / 8,
          iv: eIv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return encrypted.toString();
    } catch (error) {
      // do nohting
      return null;
    }
  }

  updateInsight = async(insight: Server.AppInsight) : Promise<void> => {

    return; // to be implemented

    let settings: Axios.AxiosRequestConfig = {
      url: this.getApiUrl("appinsights"),
      method: "POST",
      headers: this._setDefaultHeaders(),
      withCredentials: false,
      data: this._encryptInsight(insight),
    };

    // error in encryption
    if (isNullOrUndefined(settings.data)) return;

    if (!this._isLoggedIn) {
      settings.url += "?sid=" + encodeURIComponent(this._appToken);
    }

    try {
      let ar: Axios.AxiosResponse = await Axios.default(settings);
      // eslint-disable-next-line
      let response: IdResponse = ((ar as Axios.AxiosResponse).data as IdResponse);
    } catch (error) {
      // do nothing
    }
  }  
}