import moment from 'moment';
import { AppInsight } from './ServerClasses';
import { ServerApi } from './ServerApi';

export interface IInsightData {
    startDate: Date;
    endDate: Date;
    insightType: string;
    data: any;
    // start() : void;
    // end(): void;
    update(api: ServerApi) : void;
}

export class ServerInsight implements IInsightData {
    startDate: Date;
    endDate: Date;
    data: any;
    insightType: string;

    constructor(insightType: string) {
        this.startDate = moment.utc().toDate();
        this.endDate = moment.utc().toDate();
        this.insightType = insightType;
    }

    public update(api: ServerApi) {
        this.endDate = moment.utc().toDate();
        let appInsight: AppInsight = {
            id: 0,
            lastUpdated: new Date(),
            updatedBy: "",
            insightType: this.insightType,
            jData: this as IInsightData
        }

        api.updateInsight(appInsight);
    }
}
