import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { observer } from "mobx-react"
import { UIConfirmDialogStore, DialogMode, DialogResult } from '../stores/UIConfirmDialogStore';

export interface ConfirmDialogProps {
    store: UIConfirmDialogStore;
}

@observer
export class ConfirmDialog extends React.Component<ConfirmDialogProps, any> {

    public render() {

        const buttons = () => {
            switch(this.props.store.dialogMode){
                case DialogMode.OK:
                    return (
                        <DialogFooter>
                            <PrimaryButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Yes) } } text='OK' />
                        </DialogFooter>
                    );

                    case DialogMode.OKCancel:
                        return (
                            <DialogFooter>
                                <PrimaryButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Yes) } } text='OK' />
                                <DefaultButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Cancel) } } text='Cancel' />
                            </DialogFooter>
                        );

                    case DialogMode.YesNo:
                        return (
                            <DialogFooter>
                                <PrimaryButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Yes) } } text='Yes' />
                                <DefaultButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Cancel) } } text='No' />
                            </DialogFooter>
                        );

                    case DialogMode.YesNoCancel:
                        return (
                            <DialogFooter>
                                <PrimaryButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Yes) } } text='Yes' />
                                <DefaultButton onClick={ () => { this.props.store.dismissDialog(DialogResult.No) } } text='No' />
                                <DefaultButton onClick={ () => { this.props.store.dismissDialog(DialogResult.Cancel) } } text='Cancel' />
                            </DialogFooter>
                        );

                }
            return null;
        }

        return (
            <Dialog
                hidden={!this.props.store.show}
                // escape pressed 
                onDismiss={()  => { this.props.store.dismissDialog(DialogResult.Cancel)}}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.props.store.title,
                    subText: this.props.store.message
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}
            >

                <DialogFooter>
                    { buttons() }
                </DialogFooter>
            </Dialog>
        );
    }
}