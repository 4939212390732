import {action, observable} from 'mobx';
import { String } from 'typescript-string-operations';
import {UIStore, InitForm} from './UIStore'
import {UIEditFormStore} from './UIEditFormStore'
import * as Server from '../web-store/ServerClasses'
import { RequiredValidator, EmailValidator } from '../web-store/Validators';
import { ServerInsight } from '../web-store/ServerInsight';
import { isNullOrUndefined } from '../web-store/WebStoreUtil';

export interface WebStoreLogin {
    loginMessage: string;   
    isError: boolean;   
    userId: string;
    password: string;
    keepLoggedIn: boolean;
    isLoading: boolean;
    captcha: string;
}

export class UILoginFormStore extends UIEditFormStore<WebStoreLogin> {
    ref: any;
    recaptchaSiteKey: string;
    
    constructor(parent: UIStore, loginData: WebStoreLogin) {
        super(parent)
        this.setBaseObject(loginData);
        this.createValidationFields();
        // storing this locally because login calls preloginInit, which
        // resets the sitekey to null and re-retrieves.
        this.recaptchaSiteKey = parent.rootStore.ClientConfig.recaptchaSiteKey;
    }

    createValidationFields() {
        this.validationFields = [
            {
                fieldId: "userId",
                propName: "userId",
                errorMessage: "",
                validators: [new EmailValidator()]
            },
            {
                fieldId: "password",
                propName: "password",
                errorMessage: "",
                validators: [new RequiredValidator()]
            },
            {
                fieldId: "keepLoggedIn",
                propName: "keepLoggedIn",
                errorMessage: "",
            }
        ]
    }

    @action
    setMessage = (message: string) => {
        (this.baseObject as WebStoreLogin).loginMessage  = message;
    }

    
    @action
    resetCaptcha = (): void => {
        try {
            (this.baseObject as WebStoreLogin).captcha = "";
            if (this.ref != null) this.ref.execute();
        } catch (error) {
            console.error(error);
        }
    }

    @action
    verifyCaptcha = (response: string): void => {
        (this.baseObject as WebStoreLogin).captcha = response;
        // console.log(response);
    }
   
    @action
    login = async () => {
        const webStoreLogin: WebStoreLogin = this.baseObject;
        webStoreLogin.loginMessage = "";
        webStoreLogin.isError = false;

        try {
            if (String.IsNullOrWhiteSpace(webStoreLogin.userId) || String.IsNullOrWhiteSpace(webStoreLogin.password)) {
                webStoreLogin.isError = true;
                webStoreLogin.loginMessage = "User Id and/or password are invalid.";
                return;
            }

            webStoreLogin.isLoading = true;

            await this.parentStore.rootStore.preLoginInit();

            webStoreLogin.isError = false;            
            let bi = this.parentStore.rootStore.BrowserInfo;
            let serverApi = this.parentStore.serverApi;

            let request: Server.LoginRequest = {
                emailId: webStoreLogin.userId,
                password: webStoreLogin.password,
                asAdmin: this.parentStore.rootStore.AdminMode,
                deviceName: bi.name,
                deviceOS: bi.os,
                deviceVersion: bi.version,
                hostInfo: "Not Used",
                captcha: webStoreLogin.captcha,
                brc: this.parentStore.rootStore.ClientConfig.disableRecaptcha ? "tuktuk" : ""
            };
            
            let loginResponse: Server.LoginResponse = await serverApi.login(request);
            if (loginResponse.statusCode === 403) {
                webStoreLogin.isError = true;
                webStoreLogin.loginMessage = "User id and/or password are invalid.";
                return;
            } else if (loginResponse.statusCode == 206) {
                webStoreLogin.isError = true;
                webStoreLogin.loginMessage = "Your password has expired. Reset your password using the Forgot Password link to log back in.";
                return;
            } else if (loginResponse.statusCode !== 200) {
                webStoreLogin.isError = true;
                webStoreLogin.loginMessage = "An error occurred in logging into this app. The most likely cause is reCAPTCHA. Try refreshing the page. If you get this message after refreshing the page, please send us a message form our contact page at our web site (https://www.aellius.com/contact)";
                return;
            }

            webStoreLogin.isError = false;
            webStoreLogin.loginMessage = "";

            this.parentStore.rootStore.localStore.UserInfo = {
                asAdmin: (loginResponse.admin === null ? false : true),
                keepLoggedIn: true,
                token: loginResponse.token,
                usha: loginResponse.usha,
                userId: webStoreLogin.userId,
                userName: (loginResponse.admin === null ? loginResponse.account.firstName : loginResponse.admin.name)
            }

            this.parentStore.rootStore.domainStore.setLoginStatus(loginResponse);
            this.parentStore.rootStore.removegCaptchaBadge();
            
        } catch (err) {
            console.error(err);
            webStoreLogin.isError = true;
            webStoreLogin.loginMessage = "An error occurred in logging into this app.";
        }
        finally {
            webStoreLogin.isLoading = false;
            this.resetCaptcha();
        }
    }    

    @action
    navigateToForgotPassword = (): void => {
        let loginData: WebStoreLogin = this.baseObject;

        this.parentStore.initForm = InitForm.forgotPassword
        loginData.isError = false;
        loginData.loginMessage = "";
    }

    @action
    navigateToSignup = (): void => {
        let loginData: WebStoreLogin = this.baseObject;

        this.parentStore.initForm = InitForm.signUp;
        loginData.isError = false;
        loginData.loginMessage = "";
    }
}
