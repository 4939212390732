import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { observer } from 'mobx-react';
import { Link } from '@fluentui/react/lib/Link';
import { Text } from '@fluentui/react/lib/Text';
import { CenterContainerForm } from './CenterContainerForm';
import { ValidationTextField } from './ValidationTextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {ReCaptcha} from 'react-recaptcha-v3'
import { UIForgotPasswordFormStore, ForgotPassword } from '../stores/UIForgotPasswordFormStore';

interface ForgotPasswordFormProps {
    store: UIForgotPasswordFormStore;
}

@observer
export class ForgotPasswordForm extends React.Component<ForgotPasswordFormProps, any> {

    constructor(props: ForgotPasswordFormProps) {
        super(props);
        this.props.store.ref = React.createRef();
    }

    public render() {
        const signInBoxStyle = CenterContainerForm.boxStyle(this.props.store.parentStore);
        const signInCellStyle = CenterContainerForm.cellStyle();      

        const forgotPasswordData: ForgotPassword = this.props.store.baseObject;

        return (
            <form>
                <CenterContainerForm>
                    <div style={signInBoxStyle}>
                        <div style={{display: "flex", paddingTop: 10, flexDirection: "row", justifyContent: "center" }}>
                            <img src="images/logo.png" />
                        </div>

                        <div style={{display: "flex", paddingTop: 10, flexDirection: "column", alignItems: "center" }}>
                            <Text variant="mediumPlus">
                                Aellius Store - Forgot My Password 
                            </Text>
                        </div>
                
                        <div style={signInCellStyle}>
                            <ValidationTextField 
                                value={forgotPasswordData.userId} 
                                placeholder="User Id"
                                resizable={false}
                                autoFocus={true} 
                                deferredValidationTime={1000}
                                validateOnLoad={ false }
                                validateOnFocusOut = {true}
                                fieldId="userId"
                                store={this.props.store}
                            />
                            <div style={{ marginTop: 10 }} >
                                <Text>
                                    If you forgot your user id, please contact us by clicking the link below.
                                </Text>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }} >
                                <Link
                                    href="https://www.aellius.com/contact"
                                    target="_blank"
                                >
                                    Contact Us
                                </Link>                             
                            </div>
                        </div>

                        <div style={signInCellStyle}>
                            <ReCaptcha 
                                sitekey={this.props.store.parentStore.rootStore.ClientConfig.recaptchaSiteKey}
                                action="forgotPassword"
                                verifyCallback={this.props.store.verifyCaptcha}
                                ref={ref => this.props.store.ref = ref}
                            />                        
                        </div>

                        <div style={signInCellStyle}>
                            <PrimaryButton
                                style={{ height:40, width: '100%' }}
                                disabled={false}
                                checked={false}
                                text="Forgot My Password"
                                type="submit"
                                onClick={(e) => {e.preventDefault(); this.props.store.forgotPassword() }}
                            />      
                        </div>

                        <div style={{...signInCellStyle, display: "flex", justifyContent: "center"}}>
                            <Link onClick={this.props.store.navigateToLogin}>
                                Back to Sign In
                            </Link>                             
                        </div>   
                    </div>
                    

                    <div style={{...signInCellStyle, height: 52, maxWidth: 320}}>
                        <div style={{display: forgotPasswordData.isLoading ? "block" : "none" }} >  
                            <Spinner size={ SpinnerSize.large } ariaLive='assertive'
                                styles={ CenterContainerForm.getSpinnerStyles  }
                            />
                         </div>

                        <Text styles={
                            {
                                root: {
                                    color: forgotPasswordData.isError ? 
                                        this.props.store.parentStore.theme.semanticColors.bodyText :
                                        this.props.store.parentStore.theme.semanticColors.bodyText
                                }
                            }}>
                            { forgotPasswordData.message }
                        </Text>
                    </div>                     
                </CenterContainerForm>
            </form>

        );
    }
}
