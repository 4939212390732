import * as React from 'react';
import { observer } from "mobx-react";
import { Spinner, SpinnerSize, ISpinnerStyleProps, ISpinnerStyles } from '@fluentui/react/lib/Spinner';
import { FontSizes } from '@uifabric/fluent-theme';

@observer
export class Landing extends React.Component<any, any> {
    render() {
        return (
          <div style={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Spinner size={ SpinnerSize.large } label='Aellius Store' ariaLive='assertive'
                    styles={ this.getStyles }
                />
          </div>
       );
    }

    getStyles = (props: ISpinnerStyleProps): ISpinnerStyles => {
        const { theme } = props;
        const { palette } = theme;
      
        return {
          label: [
            {
              fontSize: FontSizes.size16
            },
            {
              color: palette.themePrimary,
              marginTop: 25,
              textAlign: 'center'
            }
          ],
        };
    };    
}